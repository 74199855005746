export const LOAD_USER = 'LOAD_USER'
export const RELOAD_USER = 'RELOAD_USER'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUBMIT = 'LOGIN_SUBMIT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const RESET_PW_STATE = 'RESET_PW_STATE'
export const RESET_USER_RELOAD = 'RESET_USER_RELOAD'

export const PW_RESETCODE_VALIDITY = 'PW_RESETCODE_VALIDITY'
export const CONSUME_PW_RESET = 'CONSUME_PW_RESET'

export const FAVORITES_GET = 'FAVORITES_GET'
export const FAVORITES_ADD = 'FAVORITES_ADD'
export const FAVORITES_REMOVE = 'FAVORITES_REMOVE'
export const FAVORITES_ERROR = 'FAVORITES_ERROR'

export const ADDRESSLIST_RESET = 'ADDRESSLIST_RESET'
export const ADDRESSLIST_ADD = 'ADDRESSLIST_ADD'
export const ADDRESSLIST_REMOVE = 'ADDRESSLIST_REMOVE'
export const ADDRESSLIST_ERROR = 'ADDRESSLIST_ERROR'

export const RECENTS_GET = 'RECENTS_GET'
export const RECENTS_ERROR = 'RECENTS_ERROR'

export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const CLEAR_ALERTS = 'CLEAR_ALERTS'

export const PRODUCT_SELECT = 'PRODUCT_SELECT'
export const PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND'
export const PRODUCT_RESET = 'PRODUCT_RESET'
export const PRODUCT_CATEGORY = 'PRODUCT_CATEGORY'
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH'
export const PRODUCT_SIMILAR = 'PRODUCT_SIMILAR'
export const PRODUCT_FAMILY = 'PRODUCT_FAMILY'
export const PRODUCT_GET_DISPLAY = 'PRODUCT_GET_DISPLAY'
export const PRODUCT_UUTUUDET = 'PRODUCT_UUTUUDET'
export const PRODUCT_OUTLET = 'PRODUCT_OUTLET'
export const PRODUCT_GET_MOST_VIEWED = 'PRODUCT_GET_MOST_VIEWED'
export const PRODUCT_AVAILABLE_AMOUNTS = 'PRODUCT_AVAILABLE_AMOUNTS'
export const PRODUCT_ERROR = 'PRODUCT_ERROR'

export const FETCH_ARTICLE = 'FETCH_ARTICLE'
export const RESET_ARTICLE = 'RESET_ARTICLE'

export const CART_GET = 'CART_GET'
export const CART_UPDATE = 'CART_UPDATE'
export const CART_DELAYED_JOB = 'CART_DELAYED_JOB'
export const CART_RESET = 'CART_RESET'

export const ORDER_SEND = 'ORDER_SEND'
export const ORDER_CONFIRM_AVAILABILITY = 'ORDER_CONFIRM_AVAILABILITY'
export const ORDER_RESET_CONFIRM_AVAILABILITY = 'ORDER_RESET_CONFIRM_AVAILABILITY'
export const ORDER_ADD = 'ORDER_ADD'
export const ORDER_GET_ONE = 'ORDER_GET_ONE'
export const ORDER_GET_ALL = 'ORDER_GET_ALL'
export const ORDER_SELECT = 'ORDER_SELECT'
export const ORDER_RESET = 'ORDER_RESET'
export const ORDER_ERROR = 'ORDER_ERROR'
export const ORDER_RESET_ERROR = 'ORDER_RESET_ERROR'
export const ORDER_COPY_DATA = 'ORDER_COPY_DATA'
export const ORDER_COPY_RESET = 'ORDER_COPY_RESET'

export const CAMPAIGN_GET_ALL = 'CAMPAIGN_GET_ALL'
export const CAMPAIGN_GET_PRODUCTS = 'CAMPAIGN_GET_PRODUCTS'
export const CAMPAIGN_CURRENT_RESET = 'CAMPAIGN_CURRENT_RESET'
export const CAMPAIGN_ERROR = 'CAMPAIGN_ERROR'

export const CONTACT_SUCCESS = 'CONTACT_SUCCESS'
export const CONTACT_RESET = 'CONTACT_RESET'

export const UTILS_GET = 'UTILS_GET'
export const UTILS_UPDATE = 'UTILS_UPDATE'
export const UTILS_RESET = 'UTILS_RESET'
export const UTILS_ERROR = 'UTILS_ERROR'
