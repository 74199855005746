import axios from 'axios'
import proxy from '../../utils/proxy'
import { CONTACT_SUCCESS, CONTACT_RESET } from './types'
import { actionAlert } from './action.alert'

// Lähetä yhteydenottolomake
export const actionSendContactForm = (obj) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify(obj)

  try {
    const res = await axios.post(`${proxy}/api/public/contact-form`, body, config)

    if (res.status === 200) {
      dispatch(
        actionAlert(`Kiitos yhteydenotostasi! Olemme yhteydessä mahdollisimman pian.`, 'desperado')
      )

      dispatch({
        type: CONTACT_SUCCESS,
      })
    }
    if (res.status === 400) {
      dispatch(actionAlert(`Tapahtui virhe. Yritä myöhemmin uudelleen.`, 'danger'))
    }
  } catch (err) {
    console.error(err)
    dispatch(actionAlert(`Tapahtui virhe. Yritä myöhemmin uudelleen.`, 'danger'))
    dispatch({
      type: CONTACT_RESET,
    })
  }
}

export const actionContactReset = () => (dispatch) => {
  dispatch({
    type: CONTACT_RESET,
  })
}
