// React & Routing
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Styling
import './Collections.scss'

// Utils
import { getBrandLogo } from '../../utils/getFunctions'

const Collections = ({collections}) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='Collections'>
      <Helmet>
        <title>Kuvastot | dc-collection</title>
      </Helmet>
      <h4>
        <strong>Kuvastot</strong>
      </h4>
      <div className='CollectionButtonContainer'>
        {collections?.length ? 
        collections
        .filter((c) => c.type === 'catalog')
        .map((el) => (
          <div key={el.title}>
            <a href={el.url} target='_blank' rel='noopener noreferrer'>
              <img src={el.cover} alt={el.title} />
              <h6>{el.title}</h6>
            </a>
          </div>
        ))
        :
        <p>Kuvastoja ei löytynyt.</p>
        }
      </div>
      <h4>
        <strong>Värikartat</strong>
      </h4>
      <div className='CollectionButtonContainer'>
        {collections?.length ? 
        collections
        .filter((c) => c.type !== 'catalog')
        .map((el) => (
          <div key={el.title}>
            <a href={el.url} target='_blank' rel='noopener noreferrer'>
              <img
                src={getBrandLogo(el.type, false)}
                alt={el.title}
                style={{
                  height: '60px',
                  maxHeight: '60px',
                  width: 'auto',
                  maxWidth: '180px',
                  objectFit: 'contain',
                }}
              />
              <h6>{el.title}</h6>
            </a>
          </div>
        ))
        :
        <p>Värikarttoja ei löytynyt.</p>}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  collections: state.utils.collections
})

const reduxActions = {}

export default connect(mapStateToProps, reduxActions)(Collections)
