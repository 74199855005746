// React & Routing
import React from 'react'

// Redux
import { connect } from 'react-redux'
import { actionRemoveAlert } from '../../redux/actions/action.alert'

// Bootstrap
import Alert from 'react-bootstrap/Alert'

// Styling
import './Alert.scss'

const AlertComponent = ({ alerts, actionRemoveAlert }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map((alert) => (
    <Alert
      key={alert.id}
      className='appAlert alertFadeIn'
      variant={`${alert.alertType}`}
      onClose={() => actionRemoveAlert(alert.id)}
      dismissible>
      {alert.msg}
    </Alert>
  ))

const mapStateToProps = (state) => ({
  alerts: state.alert,
})

export default connect(mapStateToProps, { actionRemoveAlert })(AlertComponent)
