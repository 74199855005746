import { CART_GET, CART_UPDATE, CART_DELAYED_JOB, CART_RESET } from '../actions/types'

const initialState = {
  products: undefined,
  totalPlusVAT: '0,00',
  total: '0,00',
  totalRetail: '0,00',
  VAT: '0,00',
  updated: undefined,
  inSync: false,
  delayedJobs: [],
}

export default function reducerCart(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case CART_GET:
      return {
        ...state,
        products: payload.products,
        totalPlusVAT: payload.totalPlusVAT,
        total: payload.total,
        totalRetail: payload.totalRetail,
        VAT: payload.VAT,
        updated: payload.updated,
        inSync: true,
      }

    case CART_UPDATE:
      return {
        ...state,
        inSync: false,
        delayedJobs: [],
      }

    case CART_DELAYED_JOB:
      return {
        ...state,
        delayedJobs: payload,
      }

    case CART_RESET:
      return initialState

    default:
      return state
  }
}
