// React
import React from 'react'

// Utils
import parse from 'html-react-parser'

// Styling
import './CardTemplate.scss'

const CardTemplate = (props) => {
    const {
      id,
      imageURL,
      extraImageLeftURL,
      extraImageRightURL,
      logoURL,
      measurementURL,
      colorThumbs,
      description,
      upperRef,
      lowerRef,
      leftSectionRef,
      rightSectionRef,
      colorsRef,
      usingEditorImages,
    } = props

    const handlePaste = (event, target) => {
      const clipboardData = event.clipboardData || window.Clipboard
      const pastedItemType = clipboardData.items[0].type
    
      if (pastedItemType.startsWith('text/plain') || pastedItemType.startsWith('text/html')) {
        // const pastedText = clipboardData.getData('Text')
        // const pastedData = clipboardData.getData('URL')
        console.log('Pasted from clipboard with default method.')
      } else if (pastedItemType.startsWith('image')) {
        event.preventDefault()
        const pastedFile = clipboardData.items[0]
        if (pastedFile
          && pastedFile.kind === 'file'
          && (pastedFile.type === 'image/png' || pastedFile.type === 'image/jpeg')) {
    
            const insertAtCursor = (html, target) => {
              const targetElement = document.querySelector(target)
              targetElement.focus()
              const sel = window.getSelection()
              if (sel.getRangeAt && sel.rangeCount) {
                let range = sel.getRangeAt(0)
                range.deleteContents()
            
                const el = document.createElement('div')
                el.innerHTML = html
                const frag = document.createDocumentFragment()
                let node, lastNode
                while ((node = el.firstChild)) {
                  lastNode = frag.appendChild(node)
                }
                range.insertNode(frag)
            
                if (lastNode) {
                  range = range.cloneRange()
                  range.setStartAfter(lastNode)
                  range.collapse(true)
                  sel.removeAllRanges()
                  sel.addRange(range)
                }
              }
            }
    
            const imageFile = pastedFile.getAsFile()
    
            const reader = new FileReader()
            reader.onload = (e) => {
              insertAtCursor(`<img src="${e.target.result}" alt="">`, target)
              console.log('Pasted from clipboard with image method.') //reader.result sisältää arvon
            }
            reader.readAsDataURL(imageFile)
          }
      } else {
        alert('Liitettyä sisältöä ei tueta. Käytä tekstiä, png- tai jpeg-tiedostoja.')
      }
    }

    return (
      <div className='card-template' id='card-template'>
        <hr className='page-divider' id='page-divider' />
        <div className='upper' id='upper-half' ref={upperRef}>
          <div className='section' ref={leftSectionRef}>
            <img
            id='pi0'
            className='product-image'
            src={imageURL} alt={id}
            />
            {extraImageLeftURL ?
            <img
            id='pi1'
            className='product-image'
            src={extraImageLeftURL} alt={id}
            />
            : null}
            <div contentEditable={true} id='editable-title-div' onPaste={(e) => handlePaste(e, '#editable-title-div')}>Muokkaa klikkaamalla...</div>
            <div contentEditable={true} id='editable-content-div' onPaste={(e) => handlePaste(e, '#editable-content-div')}>Muokkaa klikkaamalla...</div>
          </div>
          <div className='section' ref={rightSectionRef}>
            <img
            className='product-logo'
            src={logoURL} alt={id}
            />
            <h2>{id}</h2>
            <div className='description'>{parse(description)}</div>
            {extraImageRightURL ?
            <img
            id='pi2'
            className='product-image'
            src={extraImageRightURL} alt={id}
            />
            : null}
            {!usingEditorImages ? <div id='color-thumbs' className='color-thumbs' ref={colorsRef}>
              {colorThumbs}
            </div>
            : null}
          </div>
        </div>
        <div className='lower' ref={lowerRef}>
          <div className='section'>
            {measurementURL && <img
            className='measurement-table'
            src={measurementURL} alt={id}
            />}
          </div>
        </div>

      </div>
    )
}

export default CardTemplate
