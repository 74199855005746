// React & Routing
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'
import { actionFavoritesAdd } from '../../redux/actions/action.auth'

// Components
import ControlledCarousel from '../../components/Carousel/Carousel'
import { createItemButtons } from '../../components/ItemButton/ItemButton'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

// Styling
import './FrontPage.scss'
import styles from './animation.module.scss'

//Utils
import proxy from '../../utils/proxy'
import { getBrandLogo, formatStormtechCode } from '../../utils/getFunctions'

const FrontPage = ({
  product: { uutuudet, uutuudetLoadingDone, katsotuimmat, katsotuimmatLoadingDone },
  favorites,
  actionFavoritesAdd,
  isAuthenticated,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [uutuus, setUutuus] = useState(undefined)
  const [mostViewedButtons, setMostViewedButtons] = useState(undefined)
  const [animate, setAnimate] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (uutuudetLoadingDone && uutuudet && uutuudet.length) {
      setUutuus({
        kuva: `${proxy}/images/${uutuudet[activeIndex].tuoteKoodi}/${uutuudet[activeIndex].tuoteKoodi}-paakuva-thumbnail.jpg`,
        otsikko: `${uutuudet[activeIndex].tuoteNimi} (${
          uutuudet[activeIndex].brandi === 'Stormtech'
            ? formatStormtechCode(uutuudet[activeIndex].tuoteKoodi)
            : uutuudet[activeIndex].tuoteKoodi
        })`,
        logo: getBrandLogo(uutuudet[activeIndex].brandi, true),
        kuvaus: uutuudet[activeIndex].kuvaus,
        linkki: `/product/${uutuudet[activeIndex].tuoteKoodi}`,
      })
    }
  }, [uutuudet, uutuudetLoadingDone, activeIndex])

  useEffect(() => {
    if (katsotuimmatLoadingDone && katsotuimmat && katsotuimmat.length) {
      const renderMostViewedButtons = createItemButtons(
        katsotuimmat,
        isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
        false,
        actionFavoritesAdd,
        null,
        isAuthenticated
      )
      setMostViewedButtons(renderMostViewedButtons)
    }
    // eslint-disable-next-line
  }, [katsotuimmat, katsotuimmatLoadingDone, favorites, actionFavoritesAdd])

  return (
    <div className='FrontPage'>
      <Helmet>
        <title>dc-collection</title>
      </Helmet>
      <ControlledCarousel />
      {!uutuus || !uutuudet || !uutuudet.length ? null : (
        <div className='newProducts desktop-only'>
          <h5>Uutuudet</h5>
          <Container className='oneProduct'>
            <Col className='firstCol'>
              <div className='newProductsPagination'>
                <Button
                  variant='secondary'
                  className='chevron-uutuudet'
                  onClick={activeIndex > 0 ? () => setActiveIndex(activeIndex - 1) : null}>
                  <span aria-hidden='true' className='carousel-control-prev-icon'></span>
                </Button>
                <p style={{ margin: 'auto 0.5rem', fontSize: '16px' }}>
                  {uutuudet ? `${activeIndex + 1} / ${uutuudet.length}` : null}
                </p>
                <Button
                  variant='secondary'
                  className='chevron-uutuudet'
                  onClick={
                    activeIndex + 1 < uutuudet.length ? () => setActiveIndex(activeIndex + 1) : null
                  }>
                  <span aria-hidden='true' className='carousel-control-next-icon'></span>
                </Button>
              </div>
              <div className='newProductImgContainer'>
                <img
                  src={uutuus ? uutuus.kuva : ''}
                  className={styles.image}
                  onLoad={() => setAnimate(1)}
                  onAnimationEnd={() => setAnimate(0)}
                  animate={animate}
                  alt=''
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null //estä loop
                    currentTarget.src = `${proxy}/images/placeholder.jpg`
                  }}
                />
              </div>
            </Col>
            <Col>
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p
                  style={{
                    marginTop: 'unset',
                    marginBottom: 'unset',
                    fontSize: '16px',
                  }}>
                  <strong>{uutuus ? uutuus.otsikko : null}</strong>
                </p>
                <img
                  src={uutuus ? uutuus.logo : null}
                  alt=''
                  className='newProductLogo'
                  style={{ marginRight: 0 }}
                />
              </Row>
              <Row>
                <p style={{ margin: '1.5rem auto' }}>{uutuus ? uutuus.kuvaus : null}</p>
              </Row>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Link to={uutuus ? uutuus.linkki : '/'}>
                  <Button>Avaa tuote</Button>
                </Link>
              </Row>
            </Col>
          </Container>
        </div>
      )}

      {!uutuus || !uutuudet || !uutuudet.length ? null : (
        <div className='newProducts mobile-only'>
          <h5>
            <strong>Uutuudet</strong>
          </h5>
          <Container className='oneProduct'>
            <Col>
              <p
                style={{
                  marginTop: 'unset',
                  marginBottom: 'unset',
                  fontSize: '18px',
                  textTransform: 'uppercase',
                  fontWeight: '400',
                }}>
                {uutuus ? uutuus.otsikko : null}
              </p>
              <img
                src={uutuus ? uutuus.logo : null}
                alt=''
                style={{ margin: 'auto', height: '36px', width: 'max-content' }}
              />
            </Col>
            <Col id='imageCol'>
              <div>
                <Button
                  variant='secondary'
                  className='chevron-uutuudet'
                  onClick={activeIndex > 0 ? () => setActiveIndex(activeIndex - 1) : null}>
                  <span aria-hidden='true' className='carousel-control-prev-icon'></span>
                </Button>
              </div>
              <img
                src={uutuus ? uutuus.kuva : ''}
                className={styles.image}
                onLoad={() => setAnimate(1)}
                onAnimationEnd={() => setAnimate(0)}
                animate={animate}
                alt=''
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null //estä loop
                  currentTarget.src = `${proxy}/images/placeholder.jpg`
                }}
              />
              <div>
                <Button
                  variant='secondary'
                  className='chevron-uutuudet'
                  onClick={
                    activeIndex + 1 < uutuudet.length ? () => setActiveIndex(activeIndex + 1) : null
                  }>
                  <span aria-hidden='true' className='carousel-control-next-icon'></span>
                </Button>
              </div>
            </Col>
            <Col>
              <p style={{ margin: '8px', fontSize: '16px' }}>
                <strong>{uutuudet ? `${activeIndex + 1} / ${uutuudet.length}` : null}</strong>
              </p>
              <p style={{ margin: '16px auto' }}>{uutuus ? uutuus.kuvaus : null}</p>
              <Link to={uutuus ? uutuus.linkki : '/'}>
                <Button variant='link'>
                  <p>
                    <strong>SIIRRY TUOTTEESEEN</strong>
                  </p>
                </Button>
              </Link>
            </Col>
          </Container>
        </div>
      )}

      <div className='mostViewedProducts'>
        <h5>Katsotuimmat</h5>
        <Container className='topList'>{mostViewedButtons}</Container>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  product: state.product,
  favorites: state.auth.user.favorites,
  isAuthenticated: state.auth.isAuthenticated,
})

const reduxActions = {
  actionFavoritesAdd,
}

export default connect(mapStateToProps, reduxActions)(FrontPage)
