// React & Routing
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

import {
  actionProductSelect,
  actionProductReset,
  actionProductGetSimilar,
  actionProductGetFamily,
  actionProductViewCountPlusOne,
} from '../../redux/actions/action.product'
import { actionSendContactForm, actionContactReset } from '../../redux/actions/action.contact'
import { actionCartUpdate } from '../../redux/actions/action.cart'
import { actionFavoritesAdd, actionFavoritesRemove } from '../../redux/actions/action.auth'
import { actionAlert } from '../../redux/actions/action.alert'

// Components
import ImageModal from '../../components/ImageModal/ImageModal'
import ProductImageButtons from '../../components/ProductImageButtons/ProductImageButtons'
import FormAskAboutProduct from './FormAskAboutProduct'

// Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { LinkContainer } from 'react-router-bootstrap'

// Styling
import { CSSTransition } from 'react-transition-group'
import './Product.scss'

// Utils
import proxy from '../../utils/proxy'
import {
  formatStormtechCode,
  getBrandLogo,
  getCategoryCode,
  specifyColor,
  specifySize,
  specifyProduct,
  getMainCategoryCode,
} from '../../utils/getFunctions'
import { createItemButtons } from '../../components/ItemButton/ItemButton'
import parse from 'html-react-parser'

const Product = ({
  getSize,
  getColor,
  getColorGroup,
  actionProductSelect,
  actionProductReset,
  actionCartUpdate,
  actionAlert,
  actionProductGetSimilar,
  actionProductGetFamily,
  actionProductViewCountPlusOne,
  actionFavoritesAdd,
  actionFavoritesRemove,
  actionSendContactForm,
  actionContactReset,
  product: {
    selected,
    selectedLoadingDone,
    similarList,
    similarListLoadingDone,
    familyList,
    familyListLoadingDone,
  },
  cart,
  favorites,
  isAuthenticated,
  success,
  utils,
}) => {
  const { productID } = useParams()
  const navigate = useNavigate()
  let hash = window.location.hash

  // isMobile mini component starts
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  const updateWindowDimensions = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return function cleanUp() {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (screenWidth && screenWidth > 1024 && isMobile) {
      setIsMobile(false)
    } else if (screenWidth && screenWidth < 1025 && !isMobile) {
      setIsMobile(true)
    }
  }, [screenWidth, setScreenWidth, isMobile])
  // isMobile mini component ends

  const [productState, setProductState] = useState({
    loading: true,
    id: '',
    tuoteKoodi: '',
    tuoteNimi: '',
    brandi: '',
    pakkauskoko: '',
    tuoteRyhma: '',
    alaRyhma: '',
    malli: '',
    genre: '',
    kaulaAukko: '',
    materiaali: '',
    istutus: '',
    painoLuokka: '',
    samankaltaiset: '',
    tuoteperhe: '',
    yksikko: '',
    kuvaus: '',
    kevytKuvaus: '',
    kayttovinkki: undefined,
    aktiivinen: undefined,
    variRyhmat: [],
    variNimet: {},
    variaatiot: [],
    liitteet: [],
    brandiLiitteet: [],
    kuvat: [],
  })

  const [interfaceState, setInterfaceState] = useState({
    logoURL: undefined,
    startingPrice: undefined,
    startingPriceColor: undefined,
    salePrice: undefined,
    colorOptions: '',
    colorStrings: undefined,
    tabKey: 1,
    refreshTable: false,
  })
  const [colorSelected, selectColor] = useState(undefined)
  const [imageSelected, setImageSelected] = useState('')
  const [tableData, setTableData] = useState(undefined)
  const [colorButtons, setColorButtons] = useState(null)
  const [similarButtons, setSimilarButtons] = useState(undefined)
  const [familyButtons, setFamilyButtons] = useState(undefined)
  const [activeColorVariants, setActiveColorVariants] = useState(undefined)
  const [amounts, setAmounts] = useState({})
  const [modalShow, setModalShow] = useState(false)

  const {
    loading,
    tuoteKoodi,
    tuoteNimi,
    brandi,
    tuoteRyhma,
    alaRyhma,
    samankaltaiset,
    tuoteperhe,
    kuvaus,
    kevytKuvaus,
    variaatiot,
    liitteet,
    brandiLiitteet,
    kuvat,
  } = productState

  const {
    logoURL,
    startingPrice,
    startingPriceColor,
    salePrice,
    colorOptions,
    colorStrings,
    tabKey,
    refreshTable,
  } = interfaceState

  const getColorForDropMenu = (colorCode, brandi) => {
    const color = getColor(colorCode, brandi)
    if (Array.isArray(color) && selected.variNimet) {
      return selected.variNimet[colorCode]
    } else {
      return color
    }
  }

  useEffect(() => {
    actionProductReset()
    setProductState({ ...productState, loading: true })
    setImageSelected('')
    setAmounts({})
    setTableData(undefined)
    setInterfaceState({
      logoURL: undefined,
      startingPrice: undefined,
      startingPriceColor: undefined,
      colorOptions: '',
      colorStrings: undefined,
      tabKey: 1,
      refreshTable: false,
    })
    selectColor(undefined)
    window.scrollTo(0, 0)
    actionProductViewCountPlusOne(productID)
    // eslint-disable-next-line
  }, [productID])

  useEffect(() => {
    selectColor(hash.replace('#', ''))
  }, [hash])

  useEffect(() => {
    if (variaatiot && variaatiot.length) {
      const Ids = variaatiot.map((el) => el.id)
      let colorVariants = []
      Ids.forEach(function (x) {
        const color = specifyColor(x, brandi)
        if (color === colorSelected) {
          colorVariants.push(x)
        }
      })
      setActiveColorVariants(colorVariants)
    }
    // eslint-disable-next-line
  }, [colorSelected, variaatiot])

  useEffect(() => {
    if (!selectedLoadingDone) {
      actionProductSelect(productID)
    }

    if (selectedLoadingDone && selected) {
      const filteredVariaatiot = selected.variaatiot.filter((el) => !el.tagit.outlet)

      setProductState({
        ...productState,
        loading: false,
        id: selected._id,
        tuoteKoodi: selected.tuoteKoodi,
        tuoteNimi: selected.tuoteNimi,
        brandi: selected.brandi,
        pakkauskoko: selected.pakkauskoko,
        tuoteRyhma: selected.tuoteRyhma,
        alaRyhma: selected.alaRyhma,
        malli: selected.malli,
        genre: selected.genre,
        kaulaAukko: selected.kaulaAukko,
        materiaali: selected.materiaali,
        istutus: selected.istutus,
        painoLuokka: selected.painoLuokka,
        samankaltaiset: selected.samankaltaiset,
        tuoteperhe: selected.tuoteperhe,
        yksikko: selected.yksikko,
        kuvaus: selected.kuvaus,
        kevytKuvaus: selected.kevytKuvaus,
        kayttovinkki: selected.kayttovinkki,
        aktiivinen: selected.aktiivinen,
        variRyhmat: selected.variRyhmat,
        variNimet: selected.variNimet,
        variaatiot: filteredVariaatiot,
        liitteet: selected.liitteet,
        brandiLiitteet: selected.brandiLiitteet,
        kuvat: selected.kuvat,
      })

      const logoURL = getBrandLogo(selected.brandi)

      const prices = filteredVariaatiot.map((el) => el.ovh)
      let lowestPrice = Math.min(...prices)
      .toFixed(2)
      .replace('.', ',')

      if (!isFinite(parseFloat(lowestPrice))) {
        lowestPrice = '-'
      }

      let oldPrices = filteredVariaatiot.map((el) => el.vanhaHinta)
      let lowestOldPrice

      if (oldPrices && oldPrices.length) {
        let validOldPrices = oldPrices.map(op => parseFloat(op.replace(',', '.')))
        validOldPrices = validOldPrices.filter(price => !isNaN(price))
        validOldPrices.sort((a, b) => a - b)
        if (validOldPrices?.length) {
          lowestOldPrice = validOldPrices[0].toFixed(2).replace('.', ',')
        }
      }

      const activeVariants = filteredVariaatiot.filter((el) => el.aktiivinen)
      const colors = activeVariants.map((el) => el.vari)
      const colorsUnique = [...new Set(colors)]
      const colorsReadyForDropdown = colorsUnique.map((el) => ({
        code: el,
        tooltip: getColorForDropMenu(el, selected.brandi),
      }))
      colorsReadyForDropdown.sort((a, b) => (a.tooltip > b.tooltip ? 1 : -1))
      const colorOptions = colorsReadyForDropdown.map((el, index) => (
        <option key={index} value={el.code}>
          {el.tooltip}
        </option>
      ))

      setInterfaceState({
        ...interfaceState,
        logoURL: logoURL,
        startingPrice: `Hinta alkaen ${lowestOldPrice ? lowestOldPrice : lowestPrice} €`,
        salePrice: lowestOldPrice ? lowestPrice : 0,
        colorOptions: colorOptions,
        colorStrings: colorsUnique,
      })
    }

    if (selectedLoadingDone && !selected) {
      navigate('/404', { replace: true })
    }
    // eslint-disable-next-line
  }, [selectedLoadingDone, utils])

  useEffect(() => {
    // Uudelleenohjaus tuotteen product/outlet-product sivulle variaatio tietojen perusteella
    if (productID === tuoteKoodi && selected?.variaatiot) {
      const filteredVariaatiot = selected.variaatiot.filter((el) => !el.tagit.outlet)
      const outletVariaatiot = selected.variaatiot.filter((el) => el.tagit.outlet)
  
      // Uudelleenohjaus outlet-sivulle jos ei variaatioita normaalilla puolella, mutta outletissa on
      if (!filteredVariaatiot?.length && outletVariaatiot?.length) {
        navigate(`/outlet-product/${productID}`, { replace: true })
      }
    }
    // eslint-disable-next-line
  }, [productID, selected.variaatiot, tuoteKoodi])

  // Kuvat tuotekuvakaruselliin (vanha toteutus)
  // useEffect(() => {
  //   if (kuvat) {
  //     const kuvatForCurrentProductID = kuvat.filter(el => el.includes(productID))
  //     const thumbnails = kuvatForCurrentProductID
  //       .filter((el) => el.substring(el.length - 14) === '-thumbnail.jpg')
  //       .map((el) => `${proxy}/images/${productID}/${el}`)
  //     const main = thumbnails.find((el) => {
  //       if (el.includes('paakuva')) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     })
  //     const extras = getGeneralExtraThumbnails(thumbnails, productID)
  //     if (main) {
  //       extras.unshift(main)
  //     }
  //     setImageSources(extras)
  //   }
  //   // eslint-disable-next-line
  // }, [kuvat, productID])

  useEffect(() => {
    if (samankaltaiset.length > 0 && selectedLoadingDone) {
      actionProductGetSimilar(samankaltaiset)
    }
    // eslint-disable-next-line
  }, [samankaltaiset])

  useEffect(() => {
    if (tuoteperhe.length > 0 && selectedLoadingDone) {
      actionProductGetFamily(tuoteperhe)
    }
    // eslint-disable-next-line
  }, [tuoteperhe])

  useEffect(() => {
    if (similarListLoadingDone) {
      const renderButtons = createItemButtons(
        similarList,
        isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
        false,
        actionFavoritesAdd,
        null,
        isAuthenticated
      )
      setSimilarButtons(renderButtons)
    }
    // eslint-disable-next-line
  }, [similarListLoadingDone, favorites, actionFavoritesAdd, isAuthenticated])

  useEffect(() => {
    if (familyListLoadingDone) {
      const renderButtons = createItemButtons(
        familyList,
        isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
        false,
        actionFavoritesAdd,
        null,
        isAuthenticated
      )
      setFamilyButtons(renderButtons)
    }
    // eslint-disable-next-line
  }, [familyListLoadingDone, favorites, actionFavoritesAdd, isAuthenticated])

  useEffect(() => {
    return function cleanUp() {
      actionProductReset()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      colorSelected &&
      hash &&
      colorSelected === hash.replace('#', '') &&
      !tableData &&
      activeColorVariants &&
      activeColorVariants.length &&
      specifyProduct(activeColorVariants[0]) === productID &&
      specifyColor(activeColorVariants[0]) === colorSelected &&
      utils &&
      utils.sizes &&
      utils.sizes.length
    ) {
      const variantsFor = variaatiot.filter((el) => activeColorVariants.includes(el.id))
      const prices = variantsFor.map((el) => el.ovh)
      const lowestPrice = Math.min(...prices)
        .toFixed(2)
        .replace('.', ',')
      const highestPrice = Math.max(...prices)
        .toFixed(2)
        .replace('.', ',')
      const variantsForColor = variantsFor.map((obj) => ({
        ...obj,
        jarjestys: parseInt(specifySize(obj.id)),
      }))
      variantsForColor.sort((a, b) => (a.jarjestys > b.jarjestys ? 1 : -1))
      const variantIds = variantsForColor.map((el) => el.id)
      const variantObject = variantIds.reduce((o, key) => ({ ...o, [key]: 0 }), {})
      setAmounts(variantObject)
      const tableRows = variantsForColor.map((el, index) => (
        <Row key={index}>
          <Col className='desktop-only'>
            <p>{getColor(el.vari, brandi)}</p>
          </Col>
          <Col>
            <p>{getSize(el.koko)}</p>
          </Col>
          <Col>
            <p>
            {el.vanhaHinta ? 
              <span className='strikethrough'>
                {el.vanhaHinta}{' '}€
              </span>
              : null}
              <span className={el.vanhaHinta ? 'salePrice' : ''}>
                {parseFloat(el.ovh).toFixed(2).replace('.', ',')} €
              </span>
            </p>
          </Col>
          <Col>
            <p>
              {el.saldo1 && el.saldo1 > 0 ? el.saldo1 : '0'}{' '}
              {['bugOff', 'Cobalt Fusion', 'Cobalt Gear', 'Jack&Maker', 'Petrol'].includes(brandi)
                ? null
                : typeof el.saldo2 === 'number'
                ? `(${el.saldo2})`
                : '()'}
            </p>
          </Col>
          <Col style={{ flexDirection: 'row' }}>
            <Button
              variant='outline-primary'
              className='plusMinusBtn'
              onClick={() => modifyAmount('minus', el.id)}>
              -
            </Button>
            <div className='counter'>
              <input
                id={el.id}
                className='counter'
                type='tel'
                min='0'
                max='99999'
                maxLength='5'
                placeholder='0'
                defaultValue={amounts[el.id] ? amounts[el.id] : ''}
                onChange={(e) =>
                  parseInt(e.target.value) > 99999 || parseInt(e.target.value) < 0
                    ? customAmount([e.target.id], 0)
                    : customAmount([e.target.id], parseInt(e.target.value))
                }
              />
            </div>
            <Button
              variant='outline-primary'
              className='plusMinusBtn'
              onClick={() => modifyAmount('plus', el.id)}>
              +
            </Button>
          </Col>
        </Row>
      ))

      setInterfaceState({
        ...interfaceState,
        refreshTable: false,
        startingPriceColor:
          highestPrice && lowestPrice && highestPrice !== lowestPrice
            ? `Hinta ${lowestPrice} - ${highestPrice} €`
            : `Hinta ${lowestPrice} €`,
      })
      setTableData(tableRows)
    }
    // eslint-disable-next-line
  }, [refreshTable, colorSelected, activeColorVariants])

  const modifyAmount = (action, id) => {
    let element = document.getElementById(id)
    let value
    if (element.value) {
      value = parseInt(element.value)
    } else {
      value = 0
    }
    if (action === 'plus' && value < 99999) value = value + 1
    else if (action === 'minus' && value > 0) {
      value = value - 1 ? (value = value - 1) : ''
    } else return
    element.value = value
    return customAmount(id, value)
  }

  useEffect(() => {
    if (colorSelected) {
      setImageSelected(
        `${proxy}/images/${tuoteKoodi}/${tuoteKoodi}-${colorSelected.replace('/', '_')}.jpg`
      )
    } else if (!colorSelected || colorSelected === '') {
      setImageSelected(`${proxy}/images/${tuoteKoodi}/${tuoteKoodi}-paakuva.jpg`)
    }
  }, [colorSelected, tuoteKoodi])

  useEffect(() => {
    if (colorStrings) {
      const colorObjects = colorStrings.map((el) => ({
        code: el,
        tooltip: getColor(el, brandi),
        group: getColorGroup(el, brandi),
      }))

      const colorOrder = [
        'Musta',
        'Harmaa',
        'Valkoinen',
        'Ruskea',
        'Keltainen',
        'Oranssi',
        'Punainen',
        'Vaaleanpunainen',
        'Violetti',
        'Sininen',
        'Vihreä',
        'Moniväri',
      ]

      const sortedColors = colorObjects.sort((a, b) => {
        return colorOrder.indexOf(a.group) - colorOrder.indexOf(b.group)
      })

      const colorButtons = sortedColors.map((el) => (
        <OverlayTrigger
          key={el.code}
          placement='top'
          delay={{ show: 200, hide: 200 }}
          overlay={<Tooltip id='tooltip-top'>{el.tooltip}</Tooltip>}>
          <div
            id={el.code}
            className={colorSelected === el.code ? 'colorButton activeColor' : 'colorButton'}
            onClick={
              colorSelected === el.code
                ? null
                : () => {
                    setTableData(undefined)
                    setImageSelected(undefined)
                    navigate(`/product/${productID}#${el.code}`, { replace: true })
                  }
            }>
            <img
              src={`${proxy}/images/${tuoteKoodi}/${tuoteKoodi}-${el.code.replace(
                '/',
                '_'
              )}-thumbnail.jpg`}
              alt=''
              onError={({ currentTarget }) => {
                currentTarget.onerror = null //estä loop
                currentTarget.src = `${proxy}/images/placeholder.jpg`
              }}></img>
          </div>
        </OverlayTrigger>
      ))
      setColorButtons(colorButtons)
    }
    // eslint-disable-next-line
  }, [colorStrings, colorSelected])

  const [breadcrumb, setBreadcrumb] = useState(
    <Breadcrumb>
      <LinkContainer to='/'>
        <Breadcrumb.Item>Etusivu</Breadcrumb.Item>
      </LinkContainer>
    </Breadcrumb>
  )

  useEffect(() => {
    if (alaRyhma && alaRyhma !== null) {
      const mainCode = getMainCategoryCode(tuoteRyhma)
      const subCode = getCategoryCode(alaRyhma, tuoteRyhma)
      const editThese = ['Lippalakit', 'Hatut', 'Pipot']
      const filterQuery = '?page=1&sort=Nimi (A-Ö)&price=0,0&filter={}'
      setBreadcrumb(
        <Breadcrumb>
          <LinkContainer to='/'>
            <Breadcrumb.Item>Etusivu</Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to={{ pathname: `/browse/${mainCode}`, search: `${filterQuery}`}}>
            <Breadcrumb.Item>
              {tuoteRyhma === 'Päähineet ja asusteet' ? 'Oheistuotteet' : tuoteRyhma}
            </Breadcrumb.Item>
          </LinkContainer>
          {subCode ? 
          <LinkContainer to={{ pathname: `/browse/${subCode}`, search: `${filterQuery}`}}>
            <Breadcrumb.Item>
              {editThese.includes(alaRyhma) ? 'Hatut ja päähineet' : alaRyhma}
            </Breadcrumb.Item>
          </LinkContainer>
          : null}
          <Breadcrumb.Item active>
            {tuoteNimi} {tuoteKoodi}
          </Breadcrumb.Item>
        </Breadcrumb>
      )
    }
    // eslint-disable-next-line
  }, [alaRyhma, productID, tuoteKoodi])

  const [isFavorite, setIsFavorite] = useState(undefined)

  useEffect(() => {
    let storageFavorites = JSON.parse(localStorage.getItem('dc_shop_favorites'))
    if (
      (isAuthenticated && favorites && favorites.includes(productID)) ||
      (!isAuthenticated && storageFavorites && storageFavorites.includes(productID))
    ) {
      setIsFavorite(
        <>
          <div className='isFavoriteText desktop-only'>
            <p>Suosikkituote</p>
            <img src={`${proxy}/images/icons/heart-solid.svg`} alt='' />
          </div>
          <Button
            className='removeFavoriteButton desktop-only'
            variant='outline-danger'
            onClick={() => actionFavoritesRemove(productID, isAuthenticated)}>
            Poista suosikeista
          </Button>
        </>
      )
    } else {
      setIsFavorite(
        <Button
          className='addToFavoriteButton'
          variant='link'
          onClick={() => actionFavoritesAdd(productID, isAuthenticated)}>
          <p>Lisää suosikkeihin</p>
          <img src={`${proxy}/images/icons/heart-regular.svg`} alt='' />
        </Button>
      )
    }
    // eslint-disable-next-line
  }, [favorites, actionFavoritesAdd, productID, isAuthenticated])

  useEffect(() => {
    if (success) {
      actionContactReset()
    } // eslint-disable-next-line
  }, [success])

  const submitForm = (values) => {
    actionSendContactForm({
      id: '4',
      name: values.name,
      email: values.email,
      msg: values.message,
      code: tuoteKoodi,
    })
  }

  const customAmount = function (id, value) {
    let myAmounts = amounts
    let myItem = { ...myAmounts[id] }
    myItem = value
    myAmounts[id] = myItem
    setAmounts(myAmounts)
  }

  const addToCart = () => {
    const arrId = Object.keys(amounts)
    const arrAmount = Object.values(amounts)
    const allProducts = arrId.map((el, i) => {
      return {
        id: el,
        amount: arrAmount[i],
      }
    })
    const readyProducts = allProducts.filter((el) => el.amount > 0)
    const jobs = readyProducts.map((p) => ({ action: 'add', item: p }))
    if (jobs.length) {
      actionCartUpdate(jobs, cart.updated)
      setAmounts({})
      setInterfaceState({
        ...interfaceState,
        refreshTable: true,
      })
      setTableData(undefined)
    } else {
      actionAlert('Ei lisättäviä tuotteita.', 'desperado')
    }
  }

  const [liitteetLinks, setLiitteetLinks] = useState([])

  useEffect(() => {
    const productSpecificAttachmentLinks = liitteet?.map((el, index) => (
      <li key={index}>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={`${proxy}/attachments/${tuoteKoodi}/${el}`}>
          {el}
        </a>
      </li>
    ))
    const brandSpecificAttachmentLinks = brandiLiitteet?.map((el, index) => (
      <li key={index+liitteet.length}>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={`${proxy}/attachments/brands/${brandi}/${el.file}`}>
          {el.desc}
        </a>
      </li>
    ))
    const attachmentLinks = productSpecificAttachmentLinks.concat(brandSpecificAttachmentLinks);
    setLiitteetLinks(attachmentLinks)
    // eslint-disable-next-line
  }, [liitteet, brandiLiitteet])

  useEffect(() => {
    if (!colorSelected) {
      setInterfaceState({ ...interfaceState, startingPriceColor: undefined })
    }
    // eslint-disable-next-line
  }, [colorSelected])

  const tabsComponent = (
    <Tabs
      id='tabsComponent'
      activeKey={tabKey}
      onSelect={(k) => setInterfaceState({ ...interfaceState, tabKey: k })}>
      <Tab eventKey={1} title='Kuvaus' className='kuvausTab ql-editor'>
        {isMobile ? parse(kevytKuvaus) : null}
        {parse(kuvaus)}
      </Tab>
      <Tab eventKey={2} title='Mittataulukko'>
        <img
          src={`${proxy}/images/${tuoteKoodi}/${tuoteKoodi}-mittataulukko.jpg`}
          alt=''
          id='mittataulukko'
          onError={({ currentTarget }) => {
            currentTarget.onerror = null //estä loop
            currentTarget.src = `${proxy}/images/placeholder.jpg`
          }}
        />
      </Tab>
      <Tab eventKey={3} title='Liitteet'>
        {liitteet?.length || brandiLiitteet?.length ? (
          <ul className='attachmentsList'>{liitteetLinks}</ul>
        ) : (
          <p>Tällä tuotteella ei ole liitteitä.</p>
        )}
      </Tab>
      <Tab eventKey={4} title='Kysy tuotteesta'>
        <FormAskAboutProduct submitForm={submitForm} success={success} />
      </Tab>
    </Tabs>
  )

  const selectImage = (fileUrl) => {
    setImageSelected(fileUrl.replace('-thumbnail', ''))
  }


const downloadOneImage = (url) => {
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = url.split('/').pop()
  downloadLink.style.display = 'none'

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

const [preparingZipDownload, setPreparingZipDownload] = useState(false)

const downloadAllImages = (tuoteKoodi) => {
  // 1. Spinneri päälle
  setPreparingZipDownload(true)

  // 2. Lähetetään api-kutsu tarkistamaan, onko zip-tiedosto ladattavisssa
  const request = new XMLHttpRequest()

  request.addEventListener('readystatechange', () => {
    if (request.readyState === XMLHttpRequest.DONE) {
      if (request.status === 200 || request.status === 304) {
        startDownload()
      } else {
        setPreparingZipDownload(false)
        actionAlert('Kuvien lataus epäonnistui.', 'desperado')
      }
    }
  });

  request.open('GET', `${proxy}/api/public/download/${tuoteKoodi}`)
  request.send()

  const startDownload = () => {
    setPreparingZipDownload(false)
    actionAlert('Kuvien lataus aloitettu.', 'desperado')
    const downloadLink = document.createElement('a')
    downloadLink.href = `${proxy}/images/archives/${tuoteKoodi}.zip`
    downloadLink.download = `${tuoteKoodi}.zip`
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

}

  const imagePreview = (
    <div className='imagePreview'>
      <div className='imagePreviewSelected' onClick={() => setModalShow(true)}>
        <CSSTransition
          in={imageSelected ? true : false}
          timeout={500}
          classNames='fade-transition'
          unmountOnExit
          appear>
          <>
            <img
              src={imageSelected}
              alt=''
              onError={({ currentTarget }) => {
                currentTarget.onerror = null //estä loop
                currentTarget.src = `${proxy}/images/placeholder.jpg`
              }}></img>
            <figcaption>
              <img
                src={`${proxy}/images/icons/search-plus-solid.svg`}
                alt=''
                className='zoomIcon'
              />
              Näytä kuvat suurempana
            </figcaption>
          </>
        </CSSTransition>
      </div>
      <div className='imagePreviewButtons'>
        {kuvat && kuvat.length ? (
          <ProductImageButtons
            selectImage={selectImage}
            tuoteKoodi={tuoteKoodi}
            colorSelected={colorSelected}
            allImages={kuvat}
          />
        ) : null}
      </div>
      <div className='imageToolsContainer'>
        <Button
        className='desktop-only'
        variant='outline-primary'
        onClick={() => downloadOneImage(imageSelected)}>
          Lataa kuva
        </Button>
        <Button
        className='desktop-only'
        variant='outline-primary'
        disabled={preparingZipDownload}
        onClick={() => downloadAllImages(productID)}>
          {preparingZipDownload ? <><span>Valmistellaan...</span><Spinner animation='border' style={{ width: '1.2rem', height: '1.2rem', marginLeft: '4px' }} /></> : 'Lataa kaikki kuvat'}
        </Button>
        <Link
        className='product-card-link'
        to={`/card-editor/${productID}#main`}
        target='_blank'
        rel='noopener noreferrer'>
          <Button
          className='desktop-only'
          variant='outline-primary'>
            Lataa tuotekortti
          </Button>
        </Link>
        <Link
        className='product-card-link'
        to={`/image-editor/${productID}#${colorSelected}`}
        target='_blank'
        rel='noopener noreferrer'>
          <Button
          className='desktop-only'
          variant='outline-primary'>
            Lisää logo
          </Button>
        </Link>
      </div>
    </div>
  )

  const scrollToDescription = () => {
    const yCoord = document.getElementById('productComponent').offsetHeight
    if (yCoord) {
      window.scroll({ top: yCoord + 100, left: 0, behavior: 'smooth' })
      setInterfaceState({ ...interfaceState, tabKey: 1 })
    }
  }

  const getStartingPrice = () => {
    if (colorSelected && startingPriceColor) {
      return startingPriceColor
    } else {
      if (colorSelected) return 'Ladataan...'
      else return startingPrice
    }
  }

  const infoText = ['bugOff', 'Cobalt Fusion', 'Cobalt Gear', 'Jack&Maker', 'Petrol'].includes(brandi) ? 
  <span>Varastossa luku kertoo Vantaan varaston saldon.</span> :
  <span>Varastossa ensimmäinen luku kertoo Vantaan varaston saldon ja
  suluissa oleva luku noin 1-2 viikon toimitusajalla saatavilla olevan määrän.</span>

  const infoElement = 
    <OverlayTrigger
      placement='top'
      delay={{ show: 200, hide: 200 }}
      overlay={<Tooltip id='tooltip-top' className='tooltipInfoText'>{infoText}</Tooltip>}
      >
      <span>
        <img src={`${proxy}/images/icons/info-circle-solid.svg`} alt='' className='infoIcon' />
      </span>
    </OverlayTrigger>

  const productComponent = (
    <>
      <div className='productComponent' id='productComponent'>
        <Col id='mobile-rows-col' className='mobile-only'>
          <div id='mobile-row1'>
            <h5>{tuoteNimi}</h5>
            <h6>{brandi === 'Stormtech' ? formatStormtechCode(tuoteKoodi) : tuoteKoodi}</h6>
            <img src={logoURL} alt='logo' />
          </div>
          <div id='mobile-row2'>
            <p id='price'>
              <strong>{isAuthenticated ? getStartingPrice() : startingPrice}</strong>
              <strong>
                {salePrice && !colorSelected ? (
                  <span style={{ color: 'red', marginLeft: '5px' }}>({salePrice} €)</span>
                ) : null}
                {/* {salePrice &&
                colorSelected &&
                startingPriceColor &&
                currentSalePrices(activeColorVariants) &&
                currentSalePrices(activeColorVariants).length ? (
                  <span style={{ color: 'red', marginLeft: '5px' }}>
                    {currentSalePrices(activeColorVariants)}
                  </span>
                ) : null} */}
              </strong>
            </p>
            <Button variant='link' onClick={() => scrollToDescription()}>
              <p>
                <strong>TUOTEKUVAUS</strong>
              </p>
            </Button>
          </div>
        </Col>
        <Col>
          {imagePreview}
          {isFavorite}
        </Col>
        <Col>
          <Row id='row1' className='desktop-only'>
            <p className='productLogo'>
              <img src={logoURL} alt='logo' />
            </p>
          </Row>
          <Row id='rowProduct' className='desktop-only'>
            <h5>{tuoteNimi}</h5>
          </Row>
          <Row id='rowProduct' className='desktop-only'>
            <h6>{brandi === 'Stormtech' ? formatStormtechCode(tuoteKoodi) : tuoteKoodi}</h6>
          </Row>
          <Row id='rowKuvaus' className='desktop-only-block ql-editor'>
            {parse(kevytKuvaus)}
          </Row>
          <Row id='rowProduct' className='desktop-only'>
            <p id='price'>
              <strong>{isAuthenticated ? getStartingPrice() : startingPrice}</strong>
              <strong>
                {salePrice && !colorSelected ? (
                  <span style={{ color: 'red', marginLeft: '5px' }}>({salePrice} €)</span>
                ) : null}
{/*             {salePrice &&
                colorSelected &&
                startingPriceColor &&
                currentSalePrices(activeColorVariants) &&
                currentSalePrices(activeColorVariants).length ? (
                  <span style={{ color: 'red', marginLeft: '5px' }}>
                    {currentSalePrices(activeColorVariants)}
                  </span>
                ) : null} */}
              </strong>
            </p>
          </Row>
          <Row id='rowProduct' className='desktop-only'>
            <Button variant='danger' onClick={() => scrollToDescription()}>
              Lue lisää
            </Button>
          </Row>
          <Row id='row3'>
            <p>Väri:</p>
            <Form.Group>
              <Form.Control
                name='selectedColor'
                as='select'
                value={colorSelected}
                onChange={(e) => {
                  setTableData(undefined)
                  setImageSelected(undefined)
                  navigate(`/product/${productID}#${e.target.value}`, { replace: true })
                }}>
                <option value=''>Valitse väri...</option>
                {colorOptions}
              </Form.Control>
            </Form.Group>
          </Row>
          <div className='row colorButtons'>{colorButtons}</div>
          <p className='mobile-only' style={{ margin: '8px auto 0 auto' }}>
            <strong>{getColor(colorSelected, brandi)}</strong>
          </p>
          {isAuthenticated ? (
            <Row id='row4'>
              <CSSTransition
                in={tableData ? true : false}
                timeout={500}
                classNames='fade-transition'
                unmountOnExit
                appear>
                <div className='priceTable'>
                  <Row>
                    <Col className='desktop-only'>
                      <strong>
                        <p>Väri</p>
                      </strong>
                    </Col>
                    <Col>
                      <strong>
                        <p>Koko</p>
                      </strong>
                    </Col>
                    <Col>
                      <strong>
                        <p>Hinta</p>
                      </strong>
                    </Col>
                    <Col style={{flexDirection: 'row', justifyContent: 'center'}}>
                      <strong>
                        <p>Varastossa</p>
                      </strong>
                      {infoElement}
                    </Col>
                    <Col>
                      <strong>
                        <p className='desktop-only'>Valitse määrä</p>
                        <p
                          className='mobile-only'
                          style={{ margin: 'auto', justifyContent: 'center' }}>
                          Määrä
                        </p>
                      </strong>
                    </Col>
                  </Row>
                  {tableData}
                </div>
              </CSSTransition>
            </Row>
          ) : null}
          {isAuthenticated ? (
            <Row id='row5'>
              <CSSTransition
                in={tableData ? true : false}
                timeout={500}
                classNames='fade-transition'
                unmountOnExit
                appear>
                <Button onClick={addToCart} className='addToCartButton' variant='dark'>
                  Lisää ostoskoriin
                </Button>
              </CSSTransition>
            </Row>
          ) : null}
        </Col>
      </div>
      {tabsComponent}
      <div className='similarButtons' hidden={familyList.length === 0}>
        <h5>Tuoteperhe</h5>
        <Container className='similarList'>{familyButtons}</Container>
      </div>
      <div className='similarButtons' hidden={similarList.length === 0}>
        <h5>Samankaltaiset</h5>
        <Container className='similarList'>{similarButtons}</Container>
      </div>
    </>
  )

  return (
    <div className='Product'>
      <Helmet>
        <title>{`${productID}`} | dc-collection</title>
      </Helmet>
      {kuvat && kuvat.length ? (
        <ImageModal
          imageselected={imageSelected}
          colorselected={colorSelected}
          tuotekoodi={tuoteKoodi}
          images={kuvat}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      ) : null}

      {loading ? null : breadcrumb}

      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}>
          <Spinner animation='border' id='customSpinner' />
        </div>
      ) : null}

      {!loading && !selected ? <p>Hups, etsimääsi tuotetta ei löytynyt.</p> : null}
      {!loading && selected ? productComponent : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  product: state.product,
  cart: state.cart,
  favorites: state.auth.user.favorites,
  isAuthenticated: state.auth.isAuthenticated,
  success: state.contact.success,
  utils: state.utils,
})

const reduxActions = {
  actionProductSelect,
  actionProductReset,
  actionCartUpdate,
  actionAlert,
  actionProductGetSimilar,
  actionProductGetFamily,
  actionProductViewCountPlusOne,
  actionFavoritesAdd,
  actionFavoritesRemove,
  actionSendContactForm,
  actionContactReset,
}

export default connect(mapStateToProps, reduxActions)(Product)
