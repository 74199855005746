import React from 'react'
import Carousel from 'react-multi-carousel'
import { Image } from 'react-bootstrap'
import { getBrandLogo } from '../../utils/getFunctions'
import 'react-multi-carousel/lib/styles.css'
import './MultiCarouselBrands.scss'

const MultiCarouselBrands = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
      slidesToSlide: 4,
    },
    mobile: {
      breakpoint: { max: 1024, min: 0 },
      items: 3,
      slidesToSlide: 1,
    },
  }

  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={false}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={4000}
      keyBoardControl={true}
      transitionDuration={1000}
      containerClass='carousel-container'
      removeArrowOnDeviceType={['mobile']}
      dotListClass='custom-dot-list-style'
      itemClass='MultiCarouselBrands'>
      <div className='logoDiv'>
        <Image src={getBrandLogo('B&C', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Bella+Canvas', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Black&Match', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('bugOff', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Cobalt Fusion', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Cobalt Gear', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Cobalt Uusio', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('EXCD', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Gildan', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Jack&Maker', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('K-Up', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Kariban', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Kariban Premium', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Kimood', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Mukua', true)} />
      </div>
      <div className='logoDiv ns-extra-class'>
        <Image src={getBrandLogo('Native Spirit', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Penduick', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Petrol', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Proact', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Promodoro', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Stormtech', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('V-Pro', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('Velilla', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('WK', true)} />
      </div>
      <div className='logoDiv'>
        <Image src={getBrandLogo('X.O', true)} />
      </div>
    </Carousel>
  )
}

export default MultiCarouselBrands
