import axios from 'axios'
import proxy from '../../utils/proxy'
import { CART_GET, CART_UPDATE, CART_DELAYED_JOB, CART_RESET } from './types'
import { actionAlert } from './action.alert'
import { specifyProduct } from '../../utils/getFunctions'

export const actionCartGet = () => async (dispatch) => {
  try {
    const getCart = await axios.get(`${proxy}/api/private/cart`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (getCart.status === 200) {
      dispatch({
        type: CART_GET,
        payload: getCart.data,
      })
    }
  } catch (err) {
    dispatch({
      type: CART_RESET,
    })
    dispatch(actionAlert('Ostoskorin noudossa tapahtui virhe.', 'danger'))
  }
}

export const actionCartUpdate = (jobs, updated) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({
    jobs: jobs,
    updated: updated,
  })

  try {
    const res = await axios.put(`${proxy}/api/private/cart`, body, config)

    if (res.status === 200) {
      dispatch({
        type: CART_UPDATE,
      })
      if (res.data.cartWasNotLatest) alert('Huom! Ostoskorin sisältö on muuttunut.')
      dispatch(actionAlert('Ostoskori päivitetty.', 'desperado'))
    }
  } catch (err) {
    console.error(err)
  }
}

// Kuin actionCartUpdate, mutta varmistetaan jokaisen tuotteen ja variaation olemassaolo ja tila
export const actionCartUpdateFromFile = (jobs, updated) => async (dispatch) => {
  try {
    const variantArray = jobs.map(j => j?.item?.id)
    const codeArray = variantArray.map(code => specifyProduct(code))

    const preCheckConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const preCheckBody = JSON.stringify({
      codeArray: codeArray
    })

    const preCheckRes = await axios.post(`${proxy}/api/public/get-products`, preCheckBody, preCheckConfig)

    if (preCheckRes.status === 200) {
      const productData = preCheckRes.data
      const variantsNotFound = []
      variantArray.forEach(variant => {
        const product = productData.find(product => product.tuoteKoodi === specifyProduct(variant))
        if (!product || !product.variaatiot.some(variaatio => variaatio.id === variant)) {
          variantsNotFound.push(variant)
        }
      })

      if (variantsNotFound.length > 0 && variantsNotFound.length === variantArray.length) {
        alert('Tiedoston kaikki tuotekoodit (id) ovat virheellisiä tai poistuneita tuotteita.')
        return
      }

      if (variantsNotFound.length > 0 && variantsNotFound.length < variantArray.length) {
        const list = variantsNotFound.map((v) => `${v}`).join('\n')
        const confirmContinue = window.confirm(`Tiedostossa on virheellisiä tai poistuneita tuotteita:\n\n${list}\n\nJatketaanko muiden tuotteiden lisäystä?`)
        if (!confirmContinue) {
          return
        }
      }

      const confirmedJobs = jobs.filter(job => !variantsNotFound.includes(job.item.id))

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
  
      const body = JSON.stringify({
        jobs: confirmedJobs,
        updated: updated,
      })
  
      const res = await axios.put(`${proxy}/api/private/cart`, body, config)
  
      if (res.status === 200) {
        dispatch({
          type: CART_UPDATE,
        })
        if (res.data.cartWasNotLatest) alert('Huom! Ostoskorin sisältö on muuttunut.')
        dispatch(actionAlert('Ostoskori päivitetty.', 'desperado'))
      }
    }

  } catch (err) {
    console.error(err)
  }
}

export const actionCartDelayedJob = (job, delayedJobs) => (dispatch) => {
  delayedJobs.push(job)
  dispatch({
    type: CART_DELAYED_JOB,
    payload: delayedJobs,
  })
}

export const actionCartReset = () => (dispatch) => {
  dispatch({
    type: CART_RESET,
  })
}
