import {
  PRODUCT_SELECT,
  PRODUCT_NOT_FOUND,
  PRODUCT_CATEGORY,
  PRODUCT_RESET,
  PRODUCT_ERROR,
  PRODUCT_SEARCH,
  PRODUCT_SIMILAR,
  PRODUCT_FAMILY,
  PRODUCT_GET_DISPLAY,
  PRODUCT_UUTUUDET,
  PRODUCT_GET_MOST_VIEWED,
  PRODUCT_OUTLET,
} from '../actions/types'

const initialState = {
  list: [],
  listLoadingDone: false,
  searchList: [],
  searchListLoadingDone: false,
  outletList: [],
  outletListLoadingDone: false,
  similarList: [],
  similarListLoadingDone: false,
  familyList: [],
  familyListLoadingDone: false,
  prevKeyword: undefined,
  selected: {},
  selectedLoadingDone: false,
  display: undefined,
  displayLoadingDone: false,
  uutuudet: [],
  uutuudetLoadingDone: false,
  katsotuimmat: [],
  katsotuimmatLoadingDone: false,
}

export default function reducerProduct(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_GET_DISPLAY:
      return {
        ...state,
        display: payload,
        displayLoadingDone: true,
      }

    case PRODUCT_UUTUUDET:
      return {
        ...state,
        uutuudet: payload,
        uutuudetLoadingDone: true,
      }

    case PRODUCT_GET_MOST_VIEWED:
      return {
        ...state,
        katsotuimmat: payload,
        katsotuimmatLoadingDone: true,
      }

    case PRODUCT_SIMILAR:
      return {
        ...state,
        similarList: payload,
        similarListLoadingDone: true,
      }

    case PRODUCT_FAMILY:
      return {
        ...state,
        familyList: payload,
        familyListLoadingDone: true,
      }

    case PRODUCT_SELECT:
      return {
        ...state,
        selected: payload,
        selectedLoadingDone: true,
        list: [],
        listLoadingDone: false,
        searchList: [],
        searchListLoadingDone: false,
        outletList: [],
        outletListLoadingDone: false,
      }

    case PRODUCT_NOT_FOUND:
      return {
        ...state,
        selected: undefined,
        selectedLoadingDone: true,
      }

    case PRODUCT_CATEGORY:
      return {
        ...state,
        list: payload,
        listLoadingDone: true,
      }

    case PRODUCT_SEARCH:
      return {
        ...state,
        searchList: payload.data,
        searchListLoadingDone: true,
        prevKeyword: payload.keyword,
      }

    case PRODUCT_OUTLET:
      return {
        ...state,
        outletList: payload,
        outletListLoadingDone: true,
      }

    case PRODUCT_ERROR:
    case PRODUCT_RESET:
      return {
        ...state,
        list: [],
        listLoadingDone: false,
        searchList: [],
        searchListLoadingDone: false,
        outletList: [],
        outletListLoadingDone: false,
        similarList: [],
        similarListLoadingDone: false,
        familyList: [],
        familyListLoadingDone: false,
        prevKeyword: undefined,
        selected: {},
        selectedLoadingDone: false,
      }

    default:
      return state
  }
}
