// React & Routing
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Styling
import './Terms.scss'

const Terms = ({ isAuthenticated }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='Terms'>
      <Helmet>
        <title>Tilausehdot | dc-collection</title>
      </Helmet>
      <h4>
        <strong>Tilausehdot</strong>
      </h4>
      {isAuthenticated ? (
        <div className='TermsContainer'>
          <p className='p0'>
            <span className='s1'>
              <strong>1. Yleiset tilausehdot</strong>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Tilausehtoja sovelletaan j&auml;lleenmyyntiyrityksille myyt&auml;viin tilauksiin,
              joissa j&auml;lleenmyyj&auml; ostaa Desperado Clothing Oy:n
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>
          <p className='p1'>
            <span className='s1'>
              (Y-tunnus 0942112-7) maahantuomia, markkinoimia ja tukussa myyt&auml;vi&auml;
              tuotteita. Ostamalla tuotteita verkkokaupastamme ostaja hyv&auml;ksyy sill&auml;
              hetkell&auml; voimassa olevat tilausehdot ja vakuuttaa antamiensa tietojen
              oikeellisuuden ja voimassa olevan yrityskohtaisen osto-oikeutensa.
            </span>
          </p>
          <p className='p2'>
            <br />
          </p>
          <p className='p0'>
            <span className='s1'>
              <strong>2. Tilausmuodot</strong>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Heti toimitus
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>&ndash;
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>
              tilauksen tuotteet ovat osto hetkell&auml; toimitettavissa heti tai
              viimeist&auml;&auml;n 2 p&auml;iv&auml;n sis&auml;ll&auml; toimittajan (Desperado
              Clothing Oy) varastolta. <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Toimitustilaus
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>&ndash; tilauksen tuotteet
              siirtyv&auml;t tilausvahvistuksen j&auml;lkeen myyj&auml;n tilauskantaan, mist&auml;
              ne siirtyv&auml;t toimittajille (p&auml;&auml;mies) teht&auml;v&auml;&auml;n
              viikkotilaukseen. Viikkotilauksen toimitusaika on tavarantoimittajakohtainen ja se on
              tarkistettava erikseen myyj&auml;lt&auml;.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              N&auml;yte/mallitilaus &ndash; ostajalle voidaan toimittaa n&auml;ytteen&auml; yksi
              tai useampi n&auml;yte dc-mallistossa olevista tuotteista. Ostaja vastaa
              n&auml;ytetilauksen pakkaus- ja toimituskuluista. Tuotekohtainen mallialennus sovitaan
              erikseen myynnin kanssa. Alennuksella myyt&auml;vill&auml; n&auml;ytetilauksilla ei
              ole vaihto- tai palautusoikeutta. N&auml;ytetuotteiden tilaamisessa voi olla
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>
              tuotemerkki- ja ryhm&auml;kohtaisia rajoitteita.
            </span>
          </p>
          <p className='p2'>
            <br />
          </p>
          <p className='p0'>
            <span className='s1'>
              <strong>3. Ostohinta</strong>
            </span>
          </p>
          <p className='p1'>
            <span className='s1'>
              Desperado Clothing Oy:ll&auml; on oikeus muuttaa tilausehtoja ja -hintoja sek&auml;
              k&auml;ytt&ouml;ehtoja.
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Hinnaston ja verkkokaupan hinnat ovat SUOSITUS OVH hintoja, alv 0%, vapaasti
              varastostamme, eiv&auml;tk&auml; sis&auml;ll&auml; JM vuosialennusta.
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Kaikki dc-collection kuvastossa ja verkkokaupassa ilmoitetut hinnat ovat
              arvonlis&auml;verottomia. Kuvastohinnat ovat voimassa kyseisen kuvaston voimassaolon
              ajan, ellei toisin ilmoiteta.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Alennetut hinnat ja tarjoukset, joille ei ole erikseen merkitty viimeist&auml;
              voimassaolop&auml;iv&auml;&auml;, ovat voimassa niin kauan kuin tuotteita
              riitt&auml;&auml;.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>
          <p className='p2'>
            <br />
          </p>
          <p className='p0'>
            <span className='s1'>
              <strong>4. Toimitusehdot</strong>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Dc-collection malliston tuotteissa on vapaat lajitelmat, ellei muuta mainita.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>
          <p className='p1'>
            <span className='s1'>
              Kaikki tuotteet myyd&auml;&auml;n vapaasti varastostamme ja varataan
              tilausj&auml;rjestyksess&auml;.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Varastotuotteet laitetaan kuljetettavaksi tai noutovalmiiksi viimeist&auml;&auml;n 2
              p&auml;iv&auml;&auml; tilauksesta.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>
          <p className='p1'>
            <span className='s1'>
              Tilauksen loppusummaan lis&auml;t&auml;&auml;n kulloinkin voimassa olevat
              toimituskulut sek&auml; mahdollinen maksutavasta johtuva lis&auml;maksu
              (j&auml;lkitoimitus). Mik&auml;li tilaaja hyv&auml;ksyy osatoimitukset, tilaaja vastaa
              samalla kaikista j&auml;lkitoimituskuluista.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Mik&auml;li myyj&auml;n varastom&auml;&auml;r&auml;t eiv&auml;t riit&auml;, puuttuvat
              tuotteet lis&auml;t&auml;&auml;n viikoittain teht&auml;v&auml;&auml;n
              t&auml;ydennystilaukseen. Tilausp&auml;iv&auml;t ja toimitusajat on varmistettava
              myynnist&auml;mme.
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>
              Saatavuus perustuu kulloinkin vallitsevaan varastotilanteeseen, emmek&auml; vastaa
              tavarantoimittajan varastomuutoksista tai toimitusvaikeuksista
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Tilaaja on velvollinen vastaanottamaan h&auml;nelle erikseen tilatut tuotteet
              m&auml;&auml;r&auml;llisesti ja ajallisesti tilauksen mukaisesti (kts hyvitykset ja
              palautukset).
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Jos ostaja ei nouda tai vastaanota tavaraa oikeaan aikaan, myyj&auml;ll&auml; on
              oikeus purkaa kauppa 8 p&auml;iv&auml;n kuluttua siit&auml;, kun myyj&auml; on
              kehottanut ostajaa noutamaan tai vastaanottamaan tavaran, ellei ostaja ole myyj&auml;n
              mainittua kehotusta noudattanut. Myyj&auml;ll&auml; on oikeus peri&auml; asiakkaalle
              l&auml;hetetyist&auml; mutta vastaanottamatta j&auml;&auml;neist&auml; tilauksista
              l&auml;hetyskulujen lis&auml;ksi uudelleen hyllytysmaksun 15 % palautetun tavaran
              arvosta, ellei muuta sovita.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>
          <p className='p2'>
            <br />
          </p>
          <p className='p0'>
            <span className='s1'>
              <strong>
                5. Hyvitykset ja palautukset
                <span className='Apple-converted-space'>&nbsp;</span>
              </strong>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Vastaanottajan tulee tarkistaa vastaanottamansa tuotteet ennen niiden
              jatkok&auml;sittely&auml; tai toimittamista kolmannelle osapuolelle. Mik&auml;li
              tuotteet toimitetaan kolmannelle osapuolelle, ostajan vastuulla on tiedottaa
              l&auml;hetyksen vastaanottajaa voimassa olevista ostoehdoista.
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>
              Toimitusta koskevat huomautukset on teht&auml;v&auml; viimeist&auml;&auml;n 5
              p&auml;iv&auml;n kuluessa tavaran vastaanottamisesta.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Tavarapalautuksesta on aina sovittava etuk&auml;teen myyj&auml;n edustajan kanssa ja
              palautuksen on tapahduttava v&auml;litt&ouml;m&auml;sti sopimuksen j&auml;lkeen.
              Myyj&auml; ei suorita hyvityksi&auml; ilman sopimusta palautetuista tavaroista.
              Tavarapalautuksen hyv&auml;ksymisen ehtona on, ett&auml; tavara pakkauksineen on
              palautettaessa k&auml;ytt&auml;m&auml;t&ouml;n ja t&auml;ysin virheet&ouml;n,
              palautettu rahtivapaasti myyj&auml;n varastolle ja se palautuu pakattuna sellaisessa
              kunnossa, kun oli l&auml;hetett&auml;ess&auml;kin, mukaan lukien tuotekohtaiset
              pakkauspussit sek&auml; kaikki tuotekohtaiset liima- ja/tai roikkolaput.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Palautus- ja vaihto-oikeus ei koske jatkok&auml;siteltyj&auml; tuotteita (paino,
              brodeeraus tms.)
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>tai asiakkaalle erikseen
              valmistettuja tuotteita. Asiakkaalle erikseen tilatut tuotteet vaihdetaan ja
              hyvitet&auml;&auml;n tapauskohtaisesti ja vain kun siit&auml; on sovittu myyj&auml;n
              kanssa.<span className='Apple-converted-space'>&nbsp;&nbsp;</span>
              Mik&auml;li asiakas palauttaa h&auml;nelle erikseen tilatut tuotteet,
              myyj&auml;ll&auml; on oikeus veloittaa tavaran tarkastamisesta ja uudelleen
              hyllytyksest&auml; aiheutuneet kulut, 15 % palautetun tavaran arvosta.
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Vialliset tuotteet vaihdetaan ja/tai hyvitet&auml;&auml;n myyj&auml;n tapauskohtaista
              arviointia noudattaen. Myyj&auml; pid&auml;tt&auml;&auml; oikeuden ensisijaisesti
              korjata virhe ja jos se ei ole mahdollista tai korjaus muuttaa oleellisesti tuotteen
              k&auml;ytt&ouml;&auml;, viallisen tuotteen tilalle toimitetaan uusi tuote ja jos ei
              ole mahdollista, tuote hyvitet&auml;&auml;n.
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>
              Myyj&auml;ll&auml; ei ole velvollisuutta hyvitt&auml;&auml; virheellisi&auml;
              tuotteita, jos virheest&auml; tai puutteesta ei ole ilmoitettu huomausaikaa
              noudattaen, pois lukien k&auml;yt&ouml;naikana ilmennytt&auml; valmistusvirhett&auml;.
              Valmistusvirheet hyvitet&auml;&auml;n tuotteen elinkaari ja tuotteen oikea ja
              k&auml;ytt&ouml;tarkoituksen mukainen k&auml;ytt&ouml; huomioon ottaen. Virheellisen
              tuotteen palautuksesta on aina sovittava myyj&auml;n kanssa.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Kaikkien myyj&auml;lle palautuvien tuotteiden mukana on toimitettava kirjallinen
              saate, mist&auml; ilmenee palautuksesta sopineen myyj&auml;n edustajan tiedot
              sek&auml; palautuksen syy. Myyj&auml;ll&auml; ei ole velvollisuutta hyvitt&auml;&auml;
              virheellisesti, huolimattomasti tai v&auml;&auml;rin perustein palautettuja tuotteita.
            </span>
          </p>

          <p className='p2'>
            <br />
          </p>
          <p className='p0'>
            <span className='s1'>
              <strong>
                6. Laskutus ja maksuehdot
                <span className='Apple-converted-space'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
              </strong>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Yleinen maksuehto on k&auml;teinen, j&auml;lkivaatimus ja/tai ennakkomaksu.
            </span>
          </p>
          <p className='p1'>
            <span className='s1'>
              Sopimusasiakkailla maksuehto on 14 p&auml;iv&auml;&auml; netto, ellei muuta ole
              sovittu.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p4'>
            <span className='s1'>
              Desperado Clothing Oy:ll&auml; on oikeus tutkia asiakkaan luottokelpoisuus ja
              merkit&auml; asiakasrekisteriins&auml; ilman eri
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>
          <p className='p4'>
            <span className='s1'>
              ilmoitusta t&auml;st&auml; tilihakemuksesta ilmenev&auml;t asiakastiedot.
              <span className='Apple-converted-space'>&nbsp;&nbsp;</span>Lasku- tai tiliasiakkuutta
              haettaessa, luottotietojen
            </span>
          </p>
          <p className='p4'>
            <span className='s1'>
              tarkistuksen suorittaa luottotietopalveluita tarjoava yhteisty&ouml;kumppanimme
              (Intrum Oy). Mik&auml;li asiakkaan
            </span>
          </p>
          <p className='p4'>
            <span className='s1'>
              luottokelpoisuus edellytt&auml;&auml;, Desperado Clothing Oy:ll&auml; on oikeus
              rajoittaa tai peruuttaa tilin k&auml;ytt&ouml;oikeus.
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Mik&auml;li laskua ei makseta ajallaan, viiv&auml;styneet maksut siirtyv&auml;t
              automaattisesti Intrum Oy:n muistutuspalvelun tietokantaan. Viiv&auml;styneist&auml;
              maksuista Intrum Oy perii er&auml;&auml;ntyneen p&auml;&auml;oman lis&auml;ksi
              muistutusmaksun sek&auml; koron er&auml;p&auml;iv&auml;st&auml; lukien. Maksamattomana
              avoin saatava siirtyy maksumuistutuksesta automaattisesti perint&auml;&auml;n, jolloin
              asiakkaan maksettavaksi tulee my&ouml;s perint&auml;kulut.{' '}
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Myyj&auml;ll&auml; on oikeus keskeytt&auml;&auml; tilausten toimittaminen tai muuten
              rajoittaa asiakkaan osto-oikeutta, mik&auml;li ostajan ostoraja on ylittynyt tai
              ostaja ei ole maksanut er&auml;&auml;ntyneit&auml; laskuja sek&auml; maksunvalvonnasta
              syntyvi&auml; korkoja ja kululuja kokonaisuudessaan. Tilausten toimittaminen jatkuu,
              kun kaikki er&auml;&auml;ntyneet saatavat ja seuraamusmaksut on suoritettu tai
              luottolimiitti on vapautunut.
            </span>
          </p>

          <p className='p5'>
            <br />
          </p>
          <p className='p0'>
            <span className='s1'>
              <strong>
                Pienlaskutuslis&auml;
                <span className='Apple-converted-space'>&nbsp;</span>
              </strong>
            </span>
          </p>
          <p className='p1'>
            <span className='s1'>
              Veloitamme alle 50 &euro; tilauksista 17 &euro; pienlaskutuslis&auml;n, paitsi
              vuosiasiakkailtamme, joilla on voimassa oleva koontilaskutussopimus.
            </span>
          </p>

          <p className='p1'>
            <span className='s1'>
              Mik&auml;li asiakkaan edellisest&auml; ostosta on yli 14 vrk, eik&auml; asiakkaalle
              ole kertynyt yli 50 &euro; arvosta laskutettavaa, myyj&auml; on tapauskohtaista
              harkintaa noudattaen oikeutettu veloittamaan asiakkaalta koontilaskutussopimuksesta
              huolimatta pienlaskutuslis&auml;n.
              <span className='Apple-converted-space'>&nbsp;</span>
            </span>
          </p>
          <p className='p5'>
            <br />
          </p>
          <p className='p6'>
            <span className='s1'>N&auml;m&auml; ostoehdot astuvat voimaan 1.2.2021</span>
          </p>
        </div>
      ) : (
        <div className='TermsContainer'>
          <p style={{ margin: '30px 0', textAlign: 'center' }}>
            Verkkokaupan myynti vain jälleenmyyjille, kirjautumalla pääset tutustumaan
            tilausehtoihin. Voit kirjautua verkkokauppaan ruudun oikeasta yläreunasta.
          </p>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(Terms)
