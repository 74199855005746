import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

// React & Routing
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// Redux
import { Provider } from 'react-redux'
import store from './store'

// Components
import App from './App.jsx'

// Quill styles
import 'quill/dist/quill.snow.css'

// ServiceWorker for PWA
import * as serviceWorker from './serviceWorker'

// Container element & app root
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

serviceWorker.unregister()
