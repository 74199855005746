import React, { useEffect, useState } from 'react'
import proxy from '../../utils/proxy'
import { getExtraThumbsForColor, getExtraThumbsForProduct, getSecondPartOfImageFile } from '../../utils/getFunctions'

const ProductImageButtons = (props) => {
  const { selectImage, tuoteKoodi, colorSelected, allImages } = props
  const [imageArray, setImageArray] = useState(undefined)
  const mainImageIsFirst = imageArray?.length && imageArray[0]?.props?.src?.includes('paakuva') ? true : false
  const firstImageFileInArray = imageArray?.length ? imageArray[0]?.props?.src.split('/').pop() : undefined
  const selectedColorHasChanged = imageArray?.length && getSecondPartOfImageFile(firstImageFileInArray, tuoteKoodi) !== colorSelected ? true : false

  // Tuotteen pääkuva elementti
  const productMainImageURL = `${proxy}/images/${tuoteKoodi}/${tuoteKoodi}-paakuva-thumbnail.jpg`
  const productMainImage = (
    <img
      onClick={() => selectImage(productMainImageURL)}
      src={productMainImageURL}
      id={productMainImageURL}
      key={tuoteKoodi}
      alt={tuoteKoodi}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null //estä loop
        currentTarget.src = `${proxy}/images/placeholder.jpg`
      }}
    />
  )

  // Tuotteen yleisien lisäkuvien elementit
  const productExtraThumbStrings = getExtraThumbsForProduct(allImages, tuoteKoodi)
  const productExtraImages = productExtraThumbStrings.map((el) => {
    const imageURL = `${proxy}/images/${tuoteKoodi}/${el}`
    return (
      <img
        onClick={() => selectImage(imageURL)}
        src={imageURL}
        id={imageURL}
        key={el}
        alt={el}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null //estä loop
          currentTarget.src = `${proxy}/images/placeholder.jpg`
        }}
      />
    )
  })

  useEffect(() => {
    if (tuoteKoodi) {
      let imageElements = [productMainImage]
      if (productExtraImages?.length) {
        imageElements = imageElements.concat(productExtraImages)
      }
      setImageArray(imageElements)
    }
    // eslint-disable-next-line
  }, [tuoteKoodi])

  useEffect(() => {
    if (colorSelected) {
      // Tuotteen valitun värin pääkuvan elementti
      const colorMainImageURL = `${proxy}/images/${tuoteKoodi}/${tuoteKoodi}-${colorSelected.replace('/','_')}-thumbnail.jpg`
      const colorMainImage = (
        <img
          onClick={() => selectImage(colorMainImageURL)}
          src={colorMainImageURL}
          id={colorMainImageURL}
          key={colorSelected}
          alt={colorSelected}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null //estä loop
            currentTarget.src = `${proxy}/images/placeholder.jpg`
          }}
        />
      )

      // Tuotteen valitun värin lisäkuvien elementit
      const colorExtraThumbStrings = getExtraThumbsForColor(colorSelected, allImages, tuoteKoodi)
      const colorExtraImages = colorExtraThumbStrings.map((el) => {
        const imageURL = `${proxy}/images/${tuoteKoodi}/${el}`
        return (
          <img
            onClick={() => selectImage(imageURL)}
            src={imageURL}
            id={imageURL}
            key={el}
            alt={el}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null //estä loop
              currentTarget.src = `${proxy}/images/placeholder.jpg`
            }}
          />
        )
      })

      /* A:
      Jos mitään väriä ei ennestään oltu valittu, lisätään valitun värin
      värikohtaiset kuvat yleisten lisäkuvien ja pääkuvan eteen.
      Järjestys:
      -värin pääkuva
      -värin lisäkuvat
      -tuotteen yleiset lisäkuvat
      -tuotteen pääkuva
      */
      if (imageArray?.length && mainImageIsFirst) {
        const generalImageElements = productExtraImages?.length ? productExtraImages.concat(productMainImage) : [productMainImage]
        const colorSpecificImageElements = colorExtraImages?.length ? [colorMainImage].concat(colorExtraImages) : [colorMainImage]
        const combinedImageElements = colorSpecificImageElements.concat(generalImageElements)
        setImageArray(combinedImageElements)

      /* B:
      Jos toinen väri oli ennestään valittu, lisätään valitun värin
      värikohtaiset kuvat yleisten lisäkuvien ja pääkuvan eteen.
      Järjestys:
      -värin pääkuva
      -värin lisäkuvat
      -tuotteen yleiset lisäkuvat
      -tuotteen pääkuva
      */
      } else if (imageArray?.length && !mainImageIsFirst && selectedColorHasChanged) {
        const generalImageElements = productExtraImages?.length ? productExtraImages.concat(productMainImage) : [productMainImage]
        const colorSpecificImageElements = colorExtraImages?.length ? [colorMainImage].concat(colorExtraImages) : [colorMainImage]
        const combinedImageElements = colorSpecificImageElements.concat(generalImageElements)
        setImageArray(combinedImageElements)
      }

      /* C:
      Jos nollataan värivalinta esim. valitsemalla "Valitse väri..."
      niin täytyy poistaa kaikki värikohtaiset kuvat ja tuoda yleiset lisäkuvat.
      Järjestys:
      -tuotteen pääkuva
      -tuotteen yleiset lisäkuvat
      */
    } else if (!colorSelected && !mainImageIsFirst && imageArray?.length > 1) {
      const generalImageElements = productExtraImages?.length ? [productMainImage].concat(productExtraImages) : [productMainImage]
      setImageArray(generalImageElements)
    }
    // eslint-disable-next-line
  }, [colorSelected])

  return <>{imageArray ? imageArray : null}</>
}

export default ProductImageButtons
