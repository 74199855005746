import React, { useEffect, useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from 'react-bootstrap'

const Form1 = (props) => {
  const { submitForm, success } = props
  const formikRef = useRef()

  useEffect(() => {
    if (success) {
      formikRef.current.resetForm()
    }
  }, [success])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Nimesi on liian lyhyt, min 2 merkkiä.')
      .max(100, 'Nimesi on liian pitkä, max 100 merkkiä.')
      .required('Tämä kenttä on pakollinen.'),
    company: Yup.string()
      .min(2, 'Yrityksesi nimi on liian lyhyt, min 2 merkkiä.')
      .max(100, 'Yrityksesi nimi on liian pitkä, max 100 merkkiä.')
      .required('Tämä kenttä on pakollinen.'),
    email: Yup.string()
      .email('Tarkista sähköpostiosoitteen muoto.')
      .required('Tämä kenttä on pakollinen.'),
    message: Yup.string()
      .min(5, 'Viestisi on liian lyhyt, min 5 merkkiä.')
      .max(1000, 'Viestisi on liian pitkä, max 1000 merkkiä.')
      .required('Tämä kenttä on pakollinen.'),
  })

  return (
    <div className='contactForm'>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          company: '',
          email: '',
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(values)
        }}>
        {({ errors, touched }) => (
          <Form>
            <label htmlFor='name'>Nimi *</label>
            <Field id='name' name='name' placeholder='...' />
            {errors.name && touched.name ? <p className='validation-error'>{errors.name}</p> : null}

            <label htmlFor='company'>Yritys *</label>
            <Field id='company' name='company' placeholder='...' />
            {errors.company && touched.company ? (
              <p className='validation-error'>{errors.company}</p>
            ) : null}

            <label htmlFor='email'>Sähköpostiosoite *</label>
            <Field id='email' name='email' placeholder='esimerkki@esimerkki.fi' type='email' />
            {errors.email && touched.email ? (
              <p className='validation-error'>{errors.email}</p>
            ) : null}

            <label htmlFor='message'>Viesti *</label>
            <Field as='textarea' rows='5' id='message' name='message' placeholder='...' />
            {errors.message && touched.message ? (
              <p className='validation-error'>{errors.message}</p>
            ) : null}

            <p>Tähdellä merkityt kentät ovat pakollisia.</p>

            <Button type='submit' variant='dark'>
              Lähetä
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Form1
