// React
import React, { useEffect, useState, useRef } from 'react'

// Bootstrap
import { Modal, Button } from 'react-bootstrap'

// Utils
import Carousel from 'react-multi-carousel'
import proxy from '../../utils/proxy'

// Styling
import './ImageModal.scss'
import { getExtraThumbsForColor, getExtraThumbsForProduct, getSecondPartOfImageFile } from '../../utils/getFunctions'

const ImageModal = (props) => {
  const myRef = useRef()
  const { tuotekoodi, colorselected, imageselected, images, onHide, show } = props
  const [imageArray, setImageArray] = useState(undefined)
  const mainImageIsFirst = imageArray?.length && imageArray[0]?.props?.id?.includes('paakuva') ? true : false
  const firstImageFileInArray = imageArray?.length ? imageArray[0]?.props?.id.split('/').pop() : undefined
  const selectedColorHasChanged = imageArray?.length && getSecondPartOfImageFile(firstImageFileInArray, tuotekoodi) !== colorselected ? true : false

  // Tuotteen pääkuva elementti
  const productMainImageURL = `${proxy}/images/${tuotekoodi}/${tuotekoodi}-paakuva.jpg`
  const productMainImage = (
    <div
      key='mainImage'
      id={productMainImageURL}
      className='modalImageContainer'>
      <Button variant='outline-light' onClick={onHide}>
        <strong>X</strong>
      </Button>
      <img
        src={productMainImageURL}
        alt=''
        onError={({ currentTarget }) => {
          currentTarget.onerror = null //estä loop
          currentTarget.src = `${proxy}/images/placeholder.jpg`
        }}></img>
    </div>
  )
  
  // Tuotteen yleisien lisäkuvien elementit
  const productExtraThumbStrings = getExtraThumbsForProduct(images, tuotekoodi)
  const productExtraImages = productExtraThumbStrings.map((el) => {
    const imageURL = `${proxy}/images/${tuotekoodi}/${el}`
    return (
      <div
        key={imageURL.replace('-thumbnail', '')}
        id={imageURL.replace('-thumbnail', '')}
        className='modalImageContainer'>
        <Button variant='outline-light' onClick={onHide}>
          <strong>X</strong>
        </Button>
        <img
          src={imageURL.replace('-thumbnail', '')}
          alt=''
          onError={({ currentTarget }) => {
            currentTarget.onerror = null //estä loop
            currentTarget.src = `${proxy}/images/placeholder.jpg`
          }}></img>
      </div>
    )
  })

  useEffect(() => {
    if (show && myRef.current && imageArray) {
      const x = imageArray.findIndex((el) => el.props.id === imageselected)
      if (x) myRef.current.goToSlide(x)
    }
    // eslint-disable-next-line
  }, [show])

  useEffect(() => {
    if (tuotekoodi) {
      let imageElements = [productMainImage]
      if (productExtraImages?.length) {
        imageElements = imageElements.concat(productExtraImages)
      }
      setImageArray(imageElements)
    }
    // eslint-disable-next-line
  }, [tuotekoodi])

  useEffect(() => {
    if (colorselected) {
      // Tuotteen valitun värin pääkuvan elementti
      const colorMainImageURL = `${proxy}/images/${tuotekoodi}/${tuotekoodi}-${colorselected.replace('/','_')}.jpg`
      const colorMainImage = (
        <div
          key={colorMainImageURL.replace('-thumbnail', '')}
          id={colorMainImageURL.replace('-thumbnail', '')}
          className='modalImageContainer'>
          <Button variant='outline-light' onClick={onHide}>
            <strong>X</strong>
          </Button>
          <img
            src={colorMainImageURL.replace('-thumbnail', '')}
            alt=''
            onError={({ currentTarget }) => {
              currentTarget.onerror = null //estä loop
              currentTarget.src = `${proxy}/images/placeholder.jpg`
            }}></img>
        </div>
      )

      // Tuotteen valitun värin lisäkuvien elementit
      const colorExtraThumbStrings = getExtraThumbsForColor(colorselected, images, tuotekoodi)
      const colorExtraImages = colorExtraThumbStrings.map((el) => {
        const imageURL = `${proxy}/images/${tuotekoodi}/${el}`
        return (
          <div
            key={imageURL.replace('-thumbnail', '')}
            id={imageURL.replace('-thumbnail', '')}
            className='modalImageContainer'>
            <Button variant='outline-light' onClick={onHide}>
              <strong>X</strong>
            </Button>
            <img
              src={imageURL.replace('-thumbnail', '')}
              alt=''
              onError={({ currentTarget }) => {
                currentTarget.onerror = null //estä loop
                currentTarget.src = `${proxy}/images/placeholder.jpg`
              }}></img>
          </div>
        )
      })

      /* A:
      Jos mitään väriä ei ennestään oltu valittu, lisätään valitun värin
      värikohtaiset kuvat yleisten lisäkuvien ja pääkuvan eteen.
      Järjestys:
      -värin pääkuva
      -värin lisäkuvat
      -tuotteen yleiset lisäkuvat
      -tuotteen pääkuva
      */
      if (imageArray?.length && mainImageIsFirst) {
        const generalImageElements = productExtraImages?.length ? productExtraImages.concat(productMainImage) : [productMainImage]
        const colorSpecificImageElements = colorExtraImages?.length ? [colorMainImage].concat(colorExtraImages) : [colorMainImage]
        const combinedImageElements = colorSpecificImageElements.concat(generalImageElements)
        setImageArray(combinedImageElements)

      /* B:
      Jos toinen väri oli ennestään valittu, lisätään valitun värin
      värikohtaiset kuvat yleisten lisäkuvien ja pääkuvan eteen.
      Järjestys:
      -värin pääkuva
      -värin lisäkuvat
      -tuotteen yleiset lisäkuvat
      -tuotteen pääkuva
      */
      } else if (imageArray?.length && !mainImageIsFirst && selectedColorHasChanged) {
        const generalImageElements = productExtraImages?.length ? productExtraImages.concat(productMainImage) : [productMainImage]
        const colorSpecificImageElements = colorExtraImages?.length ? [colorMainImage].concat(colorExtraImages) : [colorMainImage]
        const combinedImageElements = colorSpecificImageElements.concat(generalImageElements)
        setImageArray(combinedImageElements)
      }

      /* C:
      Jos nollataan värivalinta esim. valitsemalla "Valitse väri..."
      niin täytyy poistaa kaikki värikohtaiset kuvat ja tuoda yleiset lisäkuvat.
      Järjestys:
      -tuotteen pääkuva
      -tuotteen yleiset lisäkuvat
      */
    } else if (!colorselected && !mainImageIsFirst && imageArray?.length > 1) {
      const generalImageElements = productExtraImages?.length ? [productMainImage].concat(productExtraImages) : [productMainImage]
      setImageArray(generalImageElements)
    }
    // eslint-disable-next-line
  }, [colorselected, selectedColorHasChanged])

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 1024, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  return (
    <Modal {...props} size='lg' className='fullscreenModal' centered>
      <Modal.Body>
        <Carousel
          ref={myRef}
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass='carousel-container'
          removeArrowOnDeviceType={['mobile']}>
          {imageArray ? imageArray : <></>}
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}

export default ImageModal
