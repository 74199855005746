import axios from 'axios'
import moment from 'moment'
import {
  ORDER_SEND,
  ORDER_CONFIRM_AVAILABILITY,
  ORDER_RESET_CONFIRM_AVAILABILITY,
  ORDER_ADD,
  ORDER_GET_ONE,
  ORDER_GET_ALL,
  ORDER_SELECT,
  ORDER_RESET,
  ORDER_COPY_DATA,
  ORDER_COPY_RESET,
  ORDER_ERROR,
  ORDER_RESET_ERROR,
} from './types'
import { specifyProduct } from '../../utils/getFunctions'
import { actionAlert } from './action.alert'
import proxy from '../../utils/proxy'

export const actionConfirmAvailability = (order) => async (dispatch) => {
  try {
    let prodsDone = 0
    await order.products.forEach(async (prod) => {
      const amountRes = await axios.post(`${proxy}/api/private/single-amount`, '', {
        headers: {
          'x-product-id': prod.id,
        },
      })
      if (amountRes.status === 200) {
        prod.inStock = amountRes.data
        prod.available = prod.inStock >= prod.amount ? true : false
      } else {
        prod.inStock = amountRes.data ? amountRes.data : 0
        prod.available = false
      }
      prodsDone = prodsDone + 1
      if (prodsDone === order.products.length) {
        dispatch({
          type: ORDER_CONFIRM_AVAILABILITY,
          payload: order,
        })
      }
    })
  } catch (err) {
    console.error(err)

    dispatch({
      type: ORDER_ERROR,
    })
  }
}

export const actionResetAvailability = () => (dispatch) => {
  dispatch({
    type: ORDER_RESET_CONFIRM_AVAILABILITY,
  })
}

// Lähetä uusi tilaus
export const actionOrderSend = (order, id) => async (dispatch) => {
  try {
    order.date = moment(new Date()).format('DD.MM.YYYY')
    order.deliveryWeek = moment(new Date()).format('YYYYWW')
    if (order.deliveryMethod === '1') {
      order.msg = `${order.msg} Nouto ${moment(order.pickupDate).format('DD.MM.YYYY')}`
    }

    const body = {
      order: JSON.stringify(order),
    }

    const res1 = await axios.post(`${proxy}/api/private/send-order`, body)

    if (res1.status === 200 && res1.data.orderNumber !== 0 && res1.data.orderNumber !== '0') {
      dispatch({
        type: ORDER_SEND,
      })

      const config2 = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const orderNumber = res1.data.orderNumber
      const body2 = JSON.stringify({ id: id, orderNumber: orderNumber })
      const res2 = await axios.post(`${proxy}/api/private/add-order`, body2, config2)

      if (res2.status === 200) {
        dispatch({
          type: ORDER_ADD,
        })
      } else {
        dispatch({
          type: ORDER_RESET,
        })
      }
    } else if (res1.data.orderNumber === 0 || res1.data.orderNumber === '0') {
      dispatch(actionAlert('Tilauksen lähetyksessä tapahtui virhe. Yritä uudelleen.', 'danger'))
      dispatch({
        type: ORDER_ERROR,
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: ORDER_RESET,
    })
  }
}

// Hae Novasta yhden tilauksen tiedot
export const actionOrderGetOne = (number) => async (dispatch) => {
  const headers = {
    'x-order-number': number,
  }
  const body = ''

  try {
    const res = await axios.post(`${proxy}/api/private/fetch-order`, body, {
      headers: headers,
    })

    if (res.status === 200) {
      dispatch({
        type: ORDER_GET_ONE,
        payload: res.data,
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: ORDER_RESET,
    })
  }
}

// Hae Novasta kaikkien tilauksen tiedot
export const actionOrderGetAll = (orders) => async (dispatch) => {
  const allTheOrders = []
  let i

  for (i = 0; i < orders.length; i++) {
    const headers = {
      'x-order-number': orders[i],
    }
    const body = ''

    try {
      const res = await axios.post(`${proxy}/api/private/fetch-order`, body, {
        headers: headers,
      })

      if (res.status === 200) {
        allTheOrders.push(res.data)
      }
    } catch (err) {
      console.error(err)

      dispatch({
        type: ORDER_RESET,
      })
    }
  }

  dispatch({
    type: ORDER_GET_ALL,
    payload: allTheOrders,
  })
}

export const actionOrderSelect = (number) => (dispatch) => {
  dispatch({
    type: ORDER_SELECT,
    payload: number,
  })
}

export const actionOrderReset = () => (dispatch) => {
  dispatch({
    type: ORDER_RESET,
  })
}

export const actionOrderErrorReset = () => (dispatch) => {
  dispatch({
    type: ORDER_RESET_ERROR,
  })
}

export const actionOrderCopyReset = () => (dispatch) => {
  dispatch({
    type: ORDER_COPY_RESET,
  })
}

export const actionOrderCopyData = (products) => async (dispatch) => {
  try {
    const getData = async (product) => {
      const code = specifyProduct(product.id)
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const body = JSON.stringify({ code })
      const res = await axios.post(`${proxy}/api/public/get-product`, body, config)
      if (res.status === 200) {
        const data = res.data
        const variant = data.variaatiot.find((el) => el.id === product.id)
        if (data && data.aktiivinen === 1 && variant && variant.aktiivinen) {
          const obj = {
            id: product.id,
            amount: product.amount,
            name: data.tuoteNimi,
            brand: data.brandi,
            unit: data.yksikko,
            price: parseFloat(data.variaatiot.find((el) => el.id === product.id).ovh),
            oldPrice: data.variaatiot.find((el) => el.id === product.id).vanhaHinta,
          }
          completedProducts.push(obj)
        } else {
          completedProducts.push(null)
        }
        if (completedProducts.length === products.length) {
          dispatch({
            type: ORDER_COPY_DATA,
            payload: completedProducts.filter((el) => el),
          })
        }
      }
    }
    const completedProducts = []
    products.forEach(getData)
  } catch (err) {
    console.error(err)

    dispatch({
      type: ORDER_ERROR,
    })
  }
}
