// React & Routing
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Styling
import './Info.scss'

const Info = () => {
  const versionNumber = process.env.REACT_APP_VERSION

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='Info'>
      <Helmet>
        <title>Keitä olemme | dc-collection</title>
      </Helmet>
      <h4>
        <strong>Keitä olemme</strong>
      </h4>
      <div className='InfoContainer'>
        <p>
          Desperado Clothing Oy on Vantaalla toimiva mainostekstiilien maahantuoja ja tekstiilien
          valmistaja, olemme toimineet alalla jo yli 30 vuotta.
        </p>
        <p>
          Yrityksemme harjoittaa tukkukauppaa sekä työvaatteiden ja asiakastuotannon valmistusta.
          Kaikki tuotteemme myydään ainoastaan jälleenmyyjien kautta. Jälleenmyyjiämme ovat mm.
          painotalot, työvaatemyyjät, mainostoimistot ja muut tekstiilien vähittäismyyjät, mukaan
          mahtuu myös alan verkkokauppoja sekä vähittäisliikkeitä.
        </p>
        <p>
          Valikoimamme kattaa tekstiilit sukista päähineisiin, t-paidoista teknisiin
          ulkoilutakkeihin ja sertifioituihin työvaatteisiin. Edustamme yli 15 tuotemerkkiä, näiden
          joukossa myös omassa tehtaassamme valmistettu Cobalt tuotemerkki: Cobalt Gear työvaatteet
          ja Cobalt UUSIO 100 % kierrätysmateriaalista valmistetut kierrätystuotteet.
        </p>
        <p>
          Teemme yhteistyötä maailman suurimpien tekstiilituottajien kanssa ja edustamamme
          tuotemerkit täyttävät tarvittavat eettiset vaatimukset tarjoten laajan malliston
          pukeutumiseen niin työ- kuin vapaa-ajallakin.
        </p>
        <p>
          Oma vaatetahtaamme Aasiassa varmistaa omien tuotteiden oikea-aikaisen toimituksen sekä
          malliston dynaamisuuden mahdollistaen myös asiakaskohtaisen tuotannon.
        </p>
        <p>
          Toimimme meille varta vasten rakennetuissa energiatehokkaissa tiloissa, joiden
          suunnittelussa on annettu suuri painoarvo logistiikalle. Meidät tunnetaan luotettavana
          kumppanina ja pystymme reagoimaan tilauksiin ja niiden tuomiin haasteisiin nopeasti,
          kiitos siitä toimivien tilojen sekä hyvin organisoitujen päämiehiemme.
        </p>
        <p>
          Riippumatta siitä, oletko jälleenmyyjä tai tuotteiden käyttäjä neuvomme ja autamme
          mielellämme tuotteiden valinnassa sekä käyttötarkoituksessa.
        </p>
        <p className='versionNumber'>
          Verkkokaupan versio: <span>{versionNumber}</span>
        </p>
      </div>
    </div>
  )
}

export default connect()(Info)
