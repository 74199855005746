// React & Routing
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'
import { actionProductSelect } from '../../redux/actions/action.product'
import axios from 'axios'

// Fabric (Canvas kirjasto)
import { fabric } from 'fabric'

// Dropzone
import { useDropzone } from 'react-dropzone'

// Color picker
import { ChromePicker } from 'react-color'

// PDF to Image
import { pdfjs } from 'react-pdf'

// Bootstrap
import { Button, Spinner, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'

// Utils
import proxy from '../../utils/proxy'
import { getBrandLogo } from '../../utils/getFunctions'
import { TextCurved } from './curvedText.js'

// Styling
import './ImageEditor.scss'
import ProductImageButtons from '../../components/ProductImageButtons/ProductImageButtons'
import { FiUpload, FiDownload, FiExternalLink, FiPlus, FiTrash2 } from 'react-icons/fi'
import { BiBold, BiItalic, BiUnderline, BiAlignLeft, BiAlignMiddle, BiAlignRight, BiPlus, BiMinus } from 'react-icons/bi'
import { PiBezierCurve } from 'react-icons/pi'
import { LuFlipHorizontal2 } from 'react-icons/lu'

import strokeIcon from '../../images/stroke.png'

const ImageEditor = ({ getColor, getColorGroup, actionProductSelect, product: { selected, selectedLoadingDone }, utils}) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    fabric.TextCurved = TextCurved
    fabric.TextCurved.fromObject = function(object, callback) {
      var instance = new fabric.TextCurved(object.text, object)
      callback && callback(instance)
      return instance
  }

    const { productID } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    let hash = location.hash

    // Canvas alustus
    const [canvas, setCanvas] = useState(null)
    const [canvases, setCanvases] = useState([null, null, null])
    const [currentCanvasIndex, setCurrentCanvasIndex] = useState(0)
    const [history, setHistory] = useState([])
    const [historyIndex, setHistoryIndex] = useState(0)
    let canvasElement = document.getElementById(`editor-canvas-${currentCanvasIndex}`)
    const devicePixelRatio = 2 // Oltava 1 tai 2

    // Luo canvaksen, jos sitä ei ole vielä luotu
    useEffect(() => {
      if (!canvases[currentCanvasIndex]) {
        // console.log('Current canvas not found, creating new one.')
        if (canvasElement) {
          // console.log('Canvas element found in DOM. Creating new Fabric Canvas.')
          fabric.devicePixelRatio = devicePixelRatio
          const newCanvas = new fabric.Canvas(canvasElement)
          setCanvases(prevCanvases => {
            const newCanvases = [...prevCanvases]
            newCanvases[currentCanvasIndex] = newCanvas
            return newCanvases
          })
          // console.log('Selecting newly created canvas to canvas state.')
          setCanvas(newCanvas)
        }
      } else {
        // console.log('Existing canvas found, selecting it to canvas state.')
        setCanvas(canvases[currentCanvasIndex])
      }
    }, [canvases, currentCanvasIndex, canvasElement])

    useEffect(() => {
      const canvasElements = document.querySelectorAll('.canvas-container')
      canvasElements.forEach((canvasElement, index) => {
        if (canvasElements.length < 3 && currentCanvasIndex === 2) {
          // Tarvitaan poikkeuskäsittely koska kaikkia canvaseja ei ole alustettu,
          // minkä vuoksi indeksit ei mene yks yhteen.
          if (index === 1) {
            canvasElement.style.display = 'block'
          } else {
            canvasElement.style.display = 'none'
          }
        } else {
          if (index === currentCanvasIndex) {
            canvasElement.style.display = 'block'
          } else {
            canvasElement.style.display = 'none'
          }
        }
      })
    }, [currentCanvasIndex])

    // Vaihda canvasta ja nollaa historia
    const switchCanvas = (index) => {
      // console.log('Reset history and switch canvas to id:', index)
      handleZoom(1)
      setHistory([])
      setHistoryIndex(0)
      setCurrentCanvasIndex(index)
      setCanvas(canvases[index])
    }

    // Muut state
    const [useOutletVariants, setUseOutletVariants] = useState(false)
    const [activeLayerType, setActiveLayerType] = useState(undefined)
    const [colorSelected, selectColor] = useState(undefined)
    const [imageSelected, selectImage] = useState('')
    const [selectedBackgrounds, setSelectedBackgrounds] = useState([null, null, null])
    const [colorButtons, setColorButtons] = useState(null)
    const [imageButtons, setImageButtons] = useState(null)
    const [colorStrings, setColorStrings] = useState(undefined)
    const [downloading, setDownloading] = useState(false)
    const [clipboard, setClipboard] = useState(null)
    const [selectedForTransfer1, setSelectedForTransfer1] = useState(true)
    const [selectedForTransfer2, setSelectedForTransfer2] = useState(false)
    const [selectedForTransfer3, setSelectedForTransfer3] = useState(false)

    // Kuvatasojen työkalut
    const brightnessDefault = 0, hueDefault = 0, saturateDefault = 0, flippedImageDefault = false
    const [brightness, setBrightness] = useState(brightnessDefault)
    const [hue, setHue] = useState(hueDefault)
    const [saturate, setSaturate] = useState(saturateDefault)
    const [flippedImage, setFlippedImage] = useState(flippedImageDefault)
    const [whiteIsRemoved, setWhiteIsRemoved] = useState(false)

    // Tekstitasojen työkalut
    const boldDefault = false,
    italicDefault = false,
    underlinedDefault = false,
    outlineDefault = false,
    outlineColorDefault = {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    },
    textColorDefault = {
      r: 0,
      g: 0,
      b: 0,
      a: 1
    },
    textColorOpacityDefault = '1',
    textAlignDefault = 'center',
    fontFamilyDefault = 'Arial',
    diameterDefault = 250,
    kerningDefault = 0,
    fontSizeDefault = 96
    // flippedDefault = false

    const [isBold, setIsBold] = useState(boldDefault)
    const [isItalic, setIsItalic] = useState(italicDefault)
    const [isUnderlined, setIsUnderlined] = useState(underlinedDefault)
    const [isOutlined, setIsOutlined] = useState(outlineDefault)
    const [outlineColor, setOutlineColor] = useState(outlineColorDefault)
    const [textColor, setTextColor] = useState(textColorDefault)
    const [textColorOpacity, setTextColorOpacity] = useState(textColorOpacityDefault)
    const [textAlign, setTextAlign] = useState(textAlignDefault)
    const [fontFamily, setFontFamily] = useState(fontFamilyDefault)
    const [diameter, setDiameter] = useState(diameterDefault)
    const [kerning, setKerning] = useState(kerningDefault)
    const [fontSize, setFontSize] = useState(fontSizeDefault)
    // const [flipped, setFlipped] = useState(flippedDefault)
    const fontOptions = [
      'Arial',
      'Comic Sans MS',
      'Courier New',
      'Georgia',
      'Impact',
      'Tahoma',
      'Times New Roman',
      'Trebuchet MS',
      'Verdana'
      ]

    const originalCanvasWidth = 668
    const originalCanvasHeight = 668
    const [angleText, setAngleText] = useState('')

    useEffect(() => {
      if (location.search && location.search === '?outlet=true') setUseOutletVariants(true)
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
      // if (!canvas && canvasElement) {
      //   // fabric.devicePixelRatio = 2
      //   setCanvas(new fabric.Canvas('editor-canvas'))
      // }
      if (canvas && (canvas.width !== originalCanvasWidth || canvas.height !== originalCanvasHeight || canvas.backgroundColor !== 'transparent')) {
        canvas.setWidth(originalCanvasWidth)
        canvas.setHeight(originalCanvasHeight)
        canvas.backgroundColor = 'transparent'
        canvas.renderAll()
        updateBackgroundBasedOnButtonClick()
      }
    // eslint-disable-next-line
    }, [canvas])

    useEffect(() => {
      if (canvas) {
        const initialCanvasState = canvas.toJSON()
        setHistory([initialCanvasState])
        setHistoryIndex(0)
      }
    }, [canvas, currentCanvasIndex])

    const [productState, setProductState] = useState({
      loading: true,
      id: '',
      tuoteKoodi: '',
      tuoteNimi: '',
      brandi: '',
      pakkauskoko: '',
      tuoteRyhma: '',
      alaRyhma: '',
      malli: '',
      genre: '',
      kaulaAukko: '',
      materiaali: '',
      istutus: '',
      painoLuokka: '',
      samankaltaiset: '',
      tuoteperhe: '',
      yksikko: '',
      kuvaus: '',
      kevytKuvaus: '',
      kayttovinkki: undefined,
      aktiivinen: undefined,
      variRyhmat: [],
      variNimet: {},
      variaatiot: [],
      liitteet: [],
      kuvat: [],
    })

    const { loading, tuoteKoodi, brandi, kuvat } = productState

    const setToolsToDefaultValues = () => {
      setBrightness(brightnessDefault)
      setHue(hueDefault)
      setSaturate(saturateDefault)
      setActiveLayerType(undefined)

      setIsBold(boldDefault)
      setIsItalic(italicDefault)
      setIsUnderlined(underlinedDefault)
      setIsOutlined(outlineDefault)
      setOutlineColor(outlineColorDefault)
      setTextColor(textColorDefault)
      setTextColorOpacity(textColorOpacityDefault)
      setTextAlign(textAlignDefault)
      setFontFamily(fontFamilyDefault)
      setDiameter(diameterDefault)
      setKerning(kerningDefault)
      setFontSize(fontSizeDefault)
      // setFlipped(flippedDefault)
      setFlippedImage(flippedImageDefault)
    }

    const adjustCanvasDuringZoom = (usingPlus) => {
      const zoomLevel = canvas.getZoom()
      const deltaX = originalCanvasWidth * 0.0539 / zoomLevel
      const deltaY = originalCanvasHeight * 0.0539 / zoomLevel
      if (usingPlus) {
        canvas.viewportTransform[4] -= deltaX
        canvas.viewportTransform[5] -= deltaY
      } else {
        canvas.viewportTransform[4] += deltaX
        canvas.viewportTransform[5] += deltaY
      }
    }

    const centerCanvas = () => {
      const viewportCenter = new fabric.Point(originalCanvasWidth / 2, originalCanvasHeight / 2)
      canvas.viewportTransform[4] = viewportCenter.x - (canvas.width / 2)
      canvas.viewportTransform[5] = viewportCenter.y - (canvas.height / 2)
    }

    const rgbaOpacity = (rgba) => {
      const rgbaArray = rgba.match(/\d+(\.\d+)?/g)
      if (!rgbaArray || rgbaArray.length !== 4) {
        return null // Virheellinen RGBA-arvo
      }

      parseFloat(rgbaArray[3]).toFixed(2)

      return rgbaArray[3]
    }

    const rgbStringToObject = (rgbString) => {
      if (!rgbString) return null
      if (typeof rgbString !== 'string') return rgbString // Se on jo olio
      // Poista "rgb(" ja ")" -merkit
      const rgbValues = rgbString.replace(/^rgb\(|\)$/g, '').split(',')

      if (rgbValues.length !== 3) {
        return null // Virheellinen RGBA-arvo
      }

      // Muunna arvot olioksi
      const rgbaObject = {
        r: parseInt(rgbValues[0], 10),
        g: parseInt(rgbValues[1], 10),
        b: parseInt(rgbValues[2], 10)
      }

      return rgbaObject
    }

    const rgbaStringToObject = (rgbaString) => {
      // Poista "rgba(" ja ")" -merkit
      const rgbaValues = rgbaString.replace(/^rgba\(|\)$/g, '').split(',')

      if (rgbaValues.length !== 4) {
        return null // Virheellinen RGBA-arvo
      }

      // Muunna arvot olioksi
      const rgbaObject = {
        r: parseInt(rgbaValues[0], 10),
        g: parseInt(rgbaValues[1], 10),
        b: parseInt(rgbaValues[2], 10),
        a: parseFloat(rgbaValues[3]),
      }

      return rgbaObject
    }

    const updateToolsFromObjectState = () => {
      const obj = canvas.getActiveObject()
      const multipleObjects = canvas.getActiveObjects()?.length > 1 ? true : false
      if (obj && obj.type === 'image' && !multipleObjects) {
        setActiveLayerType('image')
        const brightnessFilter = obj.filters.find(filter => filter.type === 'Brightness')
        const inputBrightness = parseInt(brightnessFilter?.brightness * 100)
        setBrightness(inputBrightness)
        const hueFilter = obj.filters.find(filter => filter.type === 'HueRotation')
        const inputHue = parseInt(hueFilter?.rotation * 100)
        setHue(inputHue)
        const saturateFilter = obj.filters.find(filter => filter.type === 'Saturation')
        const inputSaturate = parseInt(saturateFilter?.saturation * 100)
        setSaturate(inputSaturate)
        const newIsFlippedImage = obj.flipX === -1 ? true : false
        setFlippedImage(newIsFlippedImage)
        const whiteIsRemoved = obj.whiteRemoved === true ? true : false
        setWhiteIsRemoved(whiteIsRemoved)
      } else if (obj && obj.type === 'textbox' && !multipleObjects) {
        setActiveLayerType('textbox')
        const newIsBold = obj.fontWeight === 'bold' ? true : false
        setIsBold(newIsBold)
        const newIsItalic = obj.fontStyle === 'italic' ? true : false
        setIsItalic(newIsItalic)
        const newIsUnderlined = obj.underline ? true : false
        setIsUnderlined(newIsUnderlined)
        const newIsOutlined = obj.strokeWidth > 0 ? true : false
        setIsOutlined(newIsOutlined)
        const newOutlineColor = rgbStringToObject(obj.stroke) || outlineColorDefault
        setOutlineColor(newOutlineColor)
        const newTextColor = rgbaStringToObject(obj.fill) || textColorDefault
        setTextColor(newTextColor)
        const newTextColorOpacity = rgbaOpacity(obj.fill) || textColorOpacityDefault
        setTextColorOpacity(newTextColorOpacity)
        const newTextAlign = obj.textAlign || textAlignDefault
        setTextAlign(newTextAlign)
        const newFontFamily = obj.fontFamily || fontFamilyDefault
        setFontFamily(newFontFamily)
      } else if (obj && obj.type === 'text-curved' && !multipleObjects) {
        setActiveLayerType('text-curved')
        const newDiameter = obj.diameter || diameterDefault
        setDiameter(newDiameter)
        const newKerning = obj.kerning || kerningDefault
        setKerning(newKerning)
        const newFontSize = obj.fontSize || fontSizeDefault
        setFontSize(newFontSize)
        // const newFlipped = obj.flipped || flippedDefault
        // setFlipped(newFlipped)
        const newIsBold = obj.fontWeight === 'bold' ? true : false
        setIsBold(newIsBold)
        const newIsItalic = obj.fontStyle === 'italic' ? true : false
        setIsItalic(newIsItalic)
        const newIsUnderlined = obj.underline ? true : false
        setIsUnderlined(newIsUnderlined)
        const newIsOutlined = obj.strokeWidth > 0 ? true : false
        setIsOutlined(newIsOutlined)
        const newOutlineColor = rgbStringToObject(obj.stroke) || outlineColorDefault
        setOutlineColor(newOutlineColor)
        const newTextColor = rgbaStringToObject(obj.fill) || textColorDefault
        setTextColor(newTextColor)
        const newTextColorOpacity = rgbaOpacity(obj.fill) || textColorOpacityDefault
        setTextColorOpacity(newTextColorOpacity)
        const newTextAlign = obj.textAlign || textAlignDefault
        setTextAlign(newTextAlign)
        const newFontFamily = obj.fontFamily || fontFamilyDefault
        setFontFamily(newFontFamily)
      } else {
        setToolsToDefaultValues()
      }
    }

    const toggleBold = () => {
      const activeObject = canvas.getActiveObject()
      if (activeObject && (activeObject.type === 'textbox' || activeObject.type === 'text-curved')) {
        const newIsBold = !isBold
        setIsBold(newIsBold)
        activeObject.set({ fontWeight: newIsBold ? 'bold' : 'normal' })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const toggleItalic = () => {
      const activeObject = canvas.getActiveObject()
      if (activeObject && (activeObject.type === 'textbox' || activeObject.type === 'text-curved')) {
        const newIsItalic = !isItalic
        setIsItalic(newIsItalic)
        activeObject.set({ fontStyle: newIsItalic ? 'italic' : 'normal' })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const toggleUnderlined = () => {
      const activeObject = canvas.getActiveObject()
      if (activeObject && activeObject.type === 'textbox') {
        const newIsUnderlined = !isUnderlined
        setIsUnderlined(newIsUnderlined)
        activeObject.set({ underline: newIsUnderlined })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const toggleOutline = () => {
      const activeObject = canvas.getActiveObject()
      if (activeObject && (activeObject.type === 'textbox' || activeObject.type === 'text-curved')) {
        const newIsOutlined = !isOutlined
        setIsOutlined(newIsOutlined)
        const strokeStyle = `rgb(${outlineColor.r},${outlineColor.g},${outlineColor.b})`
        activeObject.set({ 
          stroke: newIsOutlined ? strokeStyle : '', 
          strokeWidth: newIsOutlined ? 0.5 : 0 
        })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const handleOutlineColorChange = (color) => {
      const newOutlineColor = color.rgb
      setOutlineColor(newOutlineColor)
      const activeObject = canvas.getActiveObject()
      if (activeObject && (activeObject.type === 'textbox' || activeObject.type === 'text-curved') && isOutlined) {
        const strokeStyle = `rgb(${newOutlineColor.r},${newOutlineColor.g},${newOutlineColor.b})`
        activeObject.set({ stroke: strokeStyle })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const handleTextColorChange = (color) => {
      const newTextColor = color.rgb
      const newTextColorOpacity = color.rgb.a
      setTextColor(newTextColor)
      setTextColorOpacity(newTextColorOpacity)
      const activeObject = canvas.getActiveObject()
      if (activeObject && (activeObject.type === 'textbox' || activeObject.type === 'text-curved')) {
        const fillStyle = `rgba(${newTextColor.r},${newTextColor.g},${newTextColor.b},${newTextColor.a})`
        activeObject.set({ fill: fillStyle })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const previewColor = (rgbObj) => {
      return `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
    }

    const colorPickerPopover = (
      <Popover id='editor-text-color-picker'>
        <Popover.Content>
          <ChromePicker color={textColor} onChange={handleTextColorChange} disableAlpha={false} />
        </Popover.Content>
      </Popover>
    )

    const outlineColorPickerPopover = (
      <Popover id='editor-text-color-picker'>
        <Popover.Content>
          <ChromePicker color={outlineColor} onChange={handleOutlineColorChange} disableAlpha={true} />
        </Popover.Content>
      </Popover>
    )

    const setTextAlignLeft = () => {
      setTextAlign('left')
      const activeObject = canvas.getActiveObject()
      if (activeObject && activeObject.type === 'textbox') {
        activeObject.set({ textAlign: 'left' })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const setTextAlignCenter = () => {
      setTextAlign('center')
      const activeObject = canvas.getActiveObject()
      if (activeObject && activeObject.type === 'textbox') {
        activeObject.set({ textAlign: 'center' })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const setTextAlignRight = () => {
      setTextAlign('right')
      const activeObject = canvas.getActiveObject()
      if (activeObject && activeObject.type === 'textbox') {
        activeObject.set({ textAlign: 'right' })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const handleFontFamilyChange = (e) => {
      const newFontFamily = e.target.value
      setFontFamily(newFontFamily)
      const activeObject = canvas.getActiveObject()
      if (activeObject && (activeObject.type === 'textbox' || activeObject.type === 'text-curved')) {
        activeObject.set({ fontFamily: newFontFamily })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const applyFilter = (index, filter) => {
      const obj = canvas.getActiveObject()
      obj.filters[index] = filter
      obj.applyFilters()
      canvas.renderAll()
    }

    const handleDiameterChange = (e) => {
      const newDiameter = parseInt(e.target.value)
      setDiameter(newDiameter)
      const activeObject = canvas.getActiveObject()

      if (activeObject) {
        activeObject.set({
          diameter: newDiameter,
        })
        canvas.discardActiveObject()
        canvas.renderAll()
        canvas.setActiveObject(activeObject)
      }
    }

    const handleKerningChange = (e) => {
      const newKerning = Math.round(e.target.value * 2) / 2
      setKerning(newKerning)
      const activeObject = canvas.getActiveObject()

      if (activeObject) {
        activeObject.set({
          kerning: newKerning,
        })
        canvas.discardActiveObject()
        canvas.renderAll()
        canvas.setActiveObject(activeObject)
      }
    }

    const handleFontSizeChange = (e) => {
      const newFontSize = Math.round(e.target.value * 2) / 2
      setFontSize(newFontSize)
      const activeObject = canvas.getActiveObject()

      if (activeObject) {
        activeObject.set({
          fontSize: newFontSize,
        })
        canvas.discardActiveObject()
        canvas.renderAll()
        canvas.setActiveObject(activeObject)
      }
    }

    // const handleFlippedChange = (e) => {
    //   const newFlipped = e.target.checked
    //   setFlipped(newFlipped)
    //   const activeObject = canvas.getActiveObject()

    //   if (activeObject) {
    //     activeObject.set({
    //       flipped: newFlipped,
    //     })
    //     canvas.renderAll()
    //     forceHistoryUpdate()
    //   }
    // }

    const handleFlippedImageChange = (value) => {
      const newFlippedImage = value
      setFlippedImage(newFlippedImage)
      const activeObject = canvas.getActiveObject()

      if (activeObject) {
        activeObject.set({
          flipX: newFlippedImage,
        })
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const handleBrightnessChange = (e) => {
      const newBrightness = parseInt(e.target.value)
      setBrightness(newBrightness)
      const activeObject = canvas.getActiveObject()

      if (activeObject) {
        const brightnessFilter = new fabric.Image.filters.Brightness({
          brightness: parseFloat(newBrightness / 100),
        })
        applyFilter(0, brightnessFilter)
      }
    }

    const handleHueChange = (e) => {
      const newHue = parseInt(e.target.value)
      setHue(newHue)
      const activeObject = canvas.getActiveObject()

      if (activeObject) {
        const hueFilter = new fabric.Image.filters.HueRotation({
          rotation: parseFloat(newHue / 100),
        })
        applyFilter(1, hueFilter)
      }
    }

    const handleSaturateChange = (e) => {
      const newSaturate = parseInt(e.target.value)
      setSaturate(newSaturate)
      const activeObject = canvas.getActiveObject()

      if (activeObject) {
        const saturateFilter = new fabric.Image.filters.Saturation({
          saturation: parseFloat(newSaturate / 100),
        })
        applyFilter(2, saturateFilter)
      }
    }

    // const calculateOptimalDiameter = (textWidth) => {

    //   const calculateDiameter = (x1, y1, x2) => {
    //     const m = y1 / x1
    //     const y2 = m * x2
    //     return y2
    //   }
      
    //   const x1 = 169.84
    //   const y1 = 98.18
      
    //   const optimalDiameter = calculateDiameter(x1, y1, textWidth)
    //   return optimalDiameter
    // }

    const [zoomLevel, setZoomLevel] = useState(1)

    const handleZoom = (newZoomLevel) => {
      if (newZoomLevel !== zoomLevel) {
        let zoomingIn = newZoomLevel > zoomLevel
        setZoomLevel(newZoomLevel)
        canvas.setZoom(newZoomLevel)
        if (newZoomLevel > 1) {
          canvas.defaultCursor = 'move'
        } else {
          canvas.defaultCursor = 'default'
        }
        if (newZoomLevel === 1) {
          centerCanvas()
        } else {
          adjustCanvasDuringZoom(zoomingIn)
        }
        canvas.renderAll()
      }
    }

    const zoomIn = () => {
      const newZoomLevel = zoomLevel + 0.1
      if (newZoomLevel < 4.01) {
        handleZoom(newZoomLevel)
      }
    }

    const zoomOut = () => {
      const newZoomLevel = zoomLevel - 0.1
      if (newZoomLevel >= 1) {
        handleZoom(newZoomLevel)
      }
    }

    const minusDiameter = () => {
      if (diameter > 100) {
        const newDiameter = diameter - 1
        setDiameter(newDiameter)
        const activeObject = canvas.getActiveObject()
  
        if (activeObject) {
          activeObject.set({
            diameter: newDiameter,
          })
          canvas.renderAll()
        }
      }
    }

    const plusDiameter = () => {
      if (diameter < 3100) {
        const newDiameter = diameter + 1
        setDiameter(newDiameter)
        const activeObject = canvas.getActiveObject()
  
        if (activeObject) {
          activeObject.set({
            diameter: newDiameter,
          })
          canvas.renderAll()
        }
      }
    }

    const handleRemoveColorChange = (e) => {

      if (zoomLevel !== 1) {
        handleZoom(1)
      }

      const activeObject = canvas.getActiveObject()

      if (activeObject) {
        const removeColorFilter = new fabric.Image.filters.RemoveColor({
          color: '#FFFFFF',
          distance: 0.10,
        })
        applyFilter(3, removeColorFilter)

        // Skaalaa alkuperäinen kuva uuden kuvan laadun parantamiseksi
        activeObject.scaleToHeight(activeObject.height)

        // Luo uusi kuva
        const dataUrl = activeObject.toDataURL({
          format: 'png',
          quality: 1,
          multiplier: 1,
        })

        fabric.Image.fromURL(dataUrl, (newImage) => {
          newImage.set({
            left: 0,
            top: 0,
            angle: 0,
            opacity: 1
          })

          const scaleFactor = 250 / newImage.width
          newImage.scale(scaleFactor)

          const canvasCenterX = canvas.width / 2
          const canvasCenterY = canvas.height / 2
          const imgCenterX = newImage.width * scaleFactor / 2
          const imgCenterY = newImage.height * scaleFactor / 2
          newImage.set({
            left: canvasCenterX - imgCenterX,
            top: canvasCenterY - imgCenterY
          })

          newImage.filters = [
            new fabric.Image.filters.Brightness({brightness: 0}),
            new fabric.Image.filters.HueRotation({rotation: 0}),
            new fabric.Image.filters.Saturation({saturation: 0}),
          ]
          newImage.applyFilters()
          newImage.whiteRemoved = true

          canvas.remove(activeObject)
          canvas.add(newImage)
          canvas.setActiveObject(newImage)
          canvas.renderAll()
          forceHistoryUpdate()
        })
      }
    }

    const renderCanvasBackground = (imgSrc) => {
      if (canvas) {
        fabric.Image.fromURL(imgSrc, (image) => {
          image.scaleToHeight(canvas.height)
          canvas.setBackgroundImage(image, () => {
            canvas.centerObject(image)
            canvas.renderAll()
          })
        }, { crossOrigin: "anonymous" })
      }
    }

    const selectBackgroundAndUpdateStates = (imageUrl) => {
      renderCanvasBackground(imageUrl)
      setSelectedBackgrounds(prevImages => {
        const newImages = [...prevImages]
        newImages[currentCanvasIndex] = imageUrl
        return newImages
      })
    }

    // const setBackgroundCrossOrigin = () => {
    //   if (canvas && canvas.backgroundImage && canvas.backgroundImage._element) {
    //     canvas.backgroundImage._element.crossOrigin = 'anonymous'
    //   }
    // }

    // const getImageDimensions = (file) => {
    //   return new Promise((resolve) => {
    //     const image = new Image()
    //     image.src = URL.createObjectURL(file)
    //     image.onload = () => {
    //       resolve({
    //         width: image.width,
    //         height: image.height,
    //       })
    //     }
    //   })
    // }

    const pdfToImage = async (url) => {
      const loadingTask = pdfjs.getDocument(url)
      const pdf = await loadingTask.promise
      const page = await pdf.getPage(1) // get first page
    
      const scale = 1
      const viewport = page.getViewport({ scale })
    
      // Prepare canvas using PDF page dimensions
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.height = viewport.height
      canvas.width = viewport.width
    
      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }
      await page.render(renderContext).promise
    
      // Create image from canvas
      const imgSrc = canvas.toDataURL()
    
      return imgSrc
    }

    const addNewImageLayer = async (acceptedFiles) => {
      const uploadedFile = acceptedFiles[acceptedFiles.length - 1]

      let imgSrc = ''
      const isPDF = uploadedFile.type === 'application/pdf'

      if (isPDF) {
        const fileURL = URL.createObjectURL(uploadedFile)
        const pdfAsImage = await pdfToImage(fileURL)
        imgSrc = pdfAsImage
      } else {
        imgSrc = URL.createObjectURL(uploadedFile)
      }

      const createCanvasElement = (imageEl) => {
        const imgObject = new fabric.Image(imageEl, {
          left: 0,
          top: 0,
          angle: 0,
          opacity: 1,
          whiteRemoved: false
        })

        const scaleFactor = 250 / imgObject.width
        imgObject.scale(scaleFactor)

        const canvasCenterX = canvas.width / 2
        const canvasCenterY = canvas.height / 2
        const imgCenterX = imgObject.width * scaleFactor / 2
        const imgCenterY = imgObject.height * scaleFactor / 2
        imgObject.set({
          left: canvasCenterX - imgCenterX,
          top: canvasCenterY - imgCenterY
        })

        imgObject.filters = [
          new fabric.Image.filters.Brightness({brightness: 0}),
          new fabric.Image.filters.HueRotation({rotation: 0}),
          new fabric.Image.filters.Saturation({saturation: 0}),
        ]
        imgObject.applyFilters()

        canvas.add(imgObject)
        canvas.setActiveObject(imgObject)
        canvas.renderAll()
        forceHistoryUpdate()
      }

      const imgElementOriginal = document.createElement('img')
      imgElementOriginal.src = imgSrc
      imgElementOriginal.onload = () => {

        // Skaalaa kuva, jos se on liian suuri
        const maxWidth = 2000, maxHeight = 2000

        if (imgElementOriginal.width > maxWidth || imgElementOriginal.height > maxHeight) {

          const scaleFactor = Math.min(
            maxWidth / imgElementOriginal.width,
            maxHeight / imgElementOriginal.height
          )

          // Luo uusi canvas elementti
          const canvas = document.createElement('canvas')
          canvas.width = imgElementOriginal.width * scaleFactor
          canvas.height = imgElementOriginal.height * scaleFactor

          // Piirrä ja skaalaa kuva canvasille
          const ctx = canvas.getContext('2d')
          ctx.drawImage(imgElementOriginal, 0, 0, canvas.width, canvas.height)

          // Luo uusi kuvan URL skaalatusta canvasista
          const imgSrcScaled = canvas.toDataURL()

          // Luo uusi Image-objekti skaalatusta kuvasta
          const imgElementScaled = new Image()
          imgElementScaled.src = imgSrcScaled
          imgElementScaled.onload = () => {
            createCanvasElement(imgElementScaled)
          }
        } else {
          createCanvasElement(imgElementOriginal)
        }
      }
    }

    const addNewTextLayer = () => {
      const text = 'Muokkaa klikkaamalla'
      const textColor = 'black'
      const fontSizeForTextLayer = 24
      const fontFamily = 'Arial'

      const textObject = new fabric.Textbox(text, {
        left: 0,
        top: 0,
        angle: 0,
        fill: textColor,
        fontSize: fontSizeForTextLayer,
        fontFamily: fontFamily,
        opacity: 1,
        strokeWidth: 0,
        textAlign: 'center',
        selectable: true,
      })

      const canvasCenterX = canvas.width / 2
      const canvasCenterY = canvas.height / 2
      const textWidth = textObject.width * textObject.scaleX
      const textHeight = textObject.height * textObject.scaleY

      textObject.set({
        left: canvasCenterX - textWidth / 2,
        top: canvasCenterY - textHeight / 2,
      })

      canvas.add(textObject)
      canvas.setActiveObject(textObject)
      canvas.renderAll()
      forceHistoryUpdate()
    }

    const changeToCurvedText = () => {

      const activeObject = canvas.getActiveObject()
    
      if (activeObject && activeObject.type === 'textbox') {

        // Pakota teksti yhdelle riville
        const singleLineText = activeObject.text.replace(/\n/g, ' ')

        const curvedText = new TextCurved(singleLineText, {
          left: activeObject.left,
          top: activeObject.top,
          fill: activeObject.fill,
          fontFamily: activeObject.fontFamily,
          fontSize: 96,
          fontStyle: activeObject.fontStyle,
          fontWeight: activeObject.fontWeight,
          stroke: activeObject.stroke,
          strokeWidth: activeObject.strokeWidth,
          textAlign: activeObject.textAlign,
          textBackgroundColor: activeObject.textBackgroundColor,
          angle: activeObject.angle,
          opacity: 1,
          selectable: true,
          objectCaching: true
        })
    
        canvas.remove(activeObject)
        canvas.add(curvedText)
        canvas.setActiveObject(curvedText)
        canvas.renderAll()
        forceHistoryUpdate()
      }
    }

    const copyLayer = () => {
      if (canvas?.getActiveObject()) {
        canvas.getActiveObject().clone(function(cloned) {
          if (cloned.type === 'image') {
            const obj = canvas.getActiveObject()
            const whiteRemoved = obj.whiteRemoved
            cloned.whiteRemoved = whiteRemoved
          } else if (cloned.type === 'text-curved') {
            const obj = canvas.getActiveObject()
            const diameter = obj.diameter
            cloned.diameter = diameter
          }
          setClipboard(cloned)
        })
      }
    }

    const pasteLayer = () => {
      if (clipboard && clipboard.clone) {
        clipboard.clone(function(clonedObj) {
          canvas.discardActiveObject()
          if (clonedObj.type === 'text-curved') {
            const newCurvedText = new TextCurved(clipboard.text, {
              left: clipboard.left + 10,
              top: clipboard.top + 10,
              fill: clipboard.fill,
              fontFamily: clipboard.fontFamily,
              fontSize: clipboard.fontSize,
              fontStyle: clipboard.fontStyle,
              fontWeight: clipboard.fontWeight,
              stroke: clipboard.stroke,
              strokeWidth: clipboard.strokeWidth,
              textAlign: clipboard.textAlign,
              angle: clipboard.angle,
              opacity: 1,
              selectable: true,
              diameter: clipboard.diameter,
              kerning: clipboard.kerning,
              flipped: clipboard.flipped
            })
            canvas.add(newCurvedText)
            canvas.setActiveObject(newCurvedText)
          } else {
            clonedObj.set({
              left: clonedObj.left + 10,
              top: clonedObj.top + 10,
              evented: true,
            })
            if (clipboard.type === 'image') {
              clonedObj.set({
                whiteRemoved: clipboard.whiteRemoved
              })
            }
            if (clonedObj.type === 'activeSelection') {
              clonedObj.canvas = canvas
              clonedObj.forEachObject(function(obj) {
                canvas.add(obj)
              })
              clonedObj.setCoords()
            } else {
              canvas.add(clonedObj)
            }
            clipboard.top += 15
            clipboard.left += 15
            canvas.setActiveObject(clonedObj)
          }
          canvas.requestRenderAll()
          forceHistoryUpdate()
        })
      }
    }

    const deleteLayer = () => {
      const activeObject = canvas.getActiveObject()
        if (activeObject) {
          const confirmDelete = window.confirm('Haluatko varmasti poistaa aktiivisen tason?')
          if (confirmDelete) {
            canvas.remove(activeObject)
          }
        }
    }

    const getImageAspectRatio = async (imageUrl) => {
      return new Promise((resolve) => {
        const img = new Image()
        img.onload = () => resolve(img.width / img.height)
        img.src = imageUrl
      })
    }

    const forceHistoryUpdate = () => {
      const json = canvas.toJSON()
      setHistory(prevHistory => [...prevHistory, json])
      setHistoryIndex(prevIndex => prevIndex + 1)
    }

    const handleDownloadImage = async (zoomLev) => {
      setDownloading(true)
    
      if (!canvas) {
        setDownloading(false)
        return
      }

      if (zoomLev !== 1) {
        handleZoom(1)
      }
    
      try {

        const canvasDiv = canvasElement
        const multiplier = 4 // Kuvatiedoston skaalaus kerroin

        const hqImage = canvas.toDataURL({
          format: 'png',
          multiplier: multiplier
        })

        const fullVersionOfImageSelected = imageSelected ? imageSelected.replace('-thumbnail', '') : imageSelected
        const aspectRatio = await getImageAspectRatio(fullVersionOfImageSelected)

        const imgWidth = canvasDiv.height * aspectRatio * (multiplier / devicePixelRatio)
        const imgHeight = canvasDiv.height * (multiplier / devicePixelRatio)
        const x = ((canvasDiv.width * multiplier / devicePixelRatio) - imgWidth) / 2
        const y = 0
        const crop = { left: x, top: y, width: imgWidth, height: imgHeight }

        // Luo uusi Image-olio data URL:stä
        const imgElement = new Image()
        imgElement.src = hqImage
        await new Promise((resolve) => {
          imgElement.onload = resolve
        })
    
        // Luo uusi fabric.Image-olio korkealaatuisesta kuvasta
        const croppedImage = new fabric.Image(imgElement, {
          left: -crop.left,
          top: -crop.top,
        })
    
        // Luo uusi canvas rajatulle alueelle
        const croppedCanvas = new fabric.StaticCanvas(null, {
          width: crop.width,
          height: crop.height
        })
    
        // Lisää rajattu kuva uuteen canvasiin
        croppedCanvas.add(croppedImage)
        croppedCanvas.renderAll()
    
        const dataUrl = croppedCanvas.toDataURL({
          format: 'png',
        })
    
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = `dc-collection kuvaeditori ${productID}.png`
    
        link.click()
        setDownloading(false)
      } catch (error) {
        setDownloading(false)
        console.error(error)
        alert('Tapahtui virhe kuvan lataamisessa. Yritä uudelleen.')
      }
    }

    const handleImageToCardEditor = async (zoomLev) => {
      if (!selectedForTransfer1 && !selectedForTransfer2 && !selectedForTransfer3) {
        window.alert('Valitse vähintään yksi kuva siirrettäväksi tuotekorttiin. Valinnat tehdään kuvavälilehtien alapuolelta rasti ruutuun painikkeista.')
        return
      }

      setDownloading(true)
    
      if (!canvas) {
        setDownloading(false)
        return
      }

      if (zoomLev !== 1) {
        handleZoom(1)
      }

      try {
        const canvasDiv = canvasElement
        const multiplier = 3 // Kuvatiedoston skaalaus kerroin

        const selectedCanvases = [selectedForTransfer1, selectedForTransfer2, selectedForTransfer3]
        .map((selected, index) => selected ? canvases[index] : null)
        .filter(canvas => canvas !== null)
  
      const images = await Promise.all(selectedCanvases.map(async (selectedCanvas) => {
        const hqImage = selectedCanvas.toDataURL({
          format: 'png',
          multiplier: multiplier
        })
  
        const fullVersionOfImageSelected = imageSelected ? imageSelected.replace('-thumbnail', '') : imageSelected
        const aspectRatio = await getImageAspectRatio(fullVersionOfImageSelected)
  
        const imgWidth = canvasDiv.height * aspectRatio * (multiplier / devicePixelRatio)
        const imgHeight = canvasDiv.height * (multiplier / devicePixelRatio)
        const x = ((canvasDiv.width * multiplier / devicePixelRatio) - imgWidth) / 2
        const y = 0
        const crop = { left: x, top: y, width: imgWidth, height: imgHeight }
  
        // Luo uusi Image-olio data URL:stä
        const imgElement = new Image()
        imgElement.src = hqImage
        await new Promise((resolve) => {
          imgElement.onload = resolve
        })
  
        // Luo uusi fabric.Image-olio korkealaatuisesta kuvasta
        const croppedImage = new fabric.Image(imgElement, {
          left: -crop.left,
          top: -crop.top,
        })
  
        // Luo uusi canvas rajatulle alueelle
        const croppedCanvas = new fabric.StaticCanvas(null, {
          width: crop.width,
          height: crop.height
        })
  
        // Lisää rajattu kuva uuteen canvasiin
        croppedCanvas.add(croppedImage)
        croppedCanvas.renderAll()
  
        return croppedCanvas.toDataURL({
          format: 'png',
        })
      }))

      const formData = new FormData()
      
      for (const [index, imageDataUrl] of images.entries()) {
        const response = await axios.get(imageDataUrl, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'image/png' })
        formData.append('array', blob, `${productID}_${index + 1}.png`)
      }
      
      axios.post(`${proxy}/api/public/upload-editor-image`, formData)
      .then(response => {
        if (response.status === 200) {
          const imagesFromServer = response.data.urls
          const proxyToUse = proxy.includes('localhost') ? 'http://localhost:3000' : proxy
          const goToUrl = `${proxyToUse}/card-editor/${productID}${useOutletVariants ? '?outlet=true' : ''}#${imagesFromServer.join(',')}`
          const newTab = window.open(goToUrl)
          if (!newTab) {
            alert(`Kuvia ei voitu siirtää tuotekorttiin, koska selaimesi on estänyt ponnahdusikkunat. Tarkista selaimesi ponnahdusikkuna-asetukset sivustolle ${proxy}.`)
          }
        }
      })
      .catch(error => alert('Tapahtui virhe kuvan lataamisessa. Yritä uudelleen.'))
  
        setDownloading(false)
      } catch (error) {
        setDownloading(false)
        console.error(error)
        alert('Tapahtui virhe kuvan lataamisessa. Yritä uudelleen.')
      }
    }

    const updateBackgroundBasedOnReactState = () => {
      const backgroundToUse = selectedBackgrounds[currentCanvasIndex]
      if (canvas && backgroundToUse) {
        selectBackgroundAndUpdateStates(backgroundToUse)
      }
    }

    const updateBackgroundBasedOnButtonClick = () => {
      if (canvas && imageSelected) {
        const fullVersionOfImageSelected = imageSelected ? imageSelected.replace('-thumbnail', '') : imageSelected
        selectBackgroundAndUpdateStates(fullVersionOfImageSelected)
      }
    }

    useEffect(() => {
      updateBackgroundBasedOnReactState()
      // eslint-disable-next-line
    }, [canvas, currentCanvasIndex])

    useEffect(() => {
      updateBackgroundBasedOnButtonClick()
      // eslint-disable-next-line
    }, [imageSelected])

    useEffect(() => {
    if (canvas) {
      const handleSelectionUpdated = () => {
        updateToolsFromObjectState()
        const activeObject = canvas.getActiveObject()
        const multipleObjectsSelected = canvas.getActiveObjects()?.length > 1 ? true : false
        if (multipleObjectsSelected) {
          const activeObjects = canvas.getActiveObjects()
          if (activeObjects.some(obj => obj.type === 'text-curved')) {
            activeObject.setControlsVisibility({
              tl: false,
              tr: false,
              bl: false,
              br: false,
              ml: false,
              mt: false,
              mr: false,
              mb: false,
              mtr: true
            })
          }
        }
        if (activeObject) {
          activeObject.bringToFront()
        }
      }

      const handleShowRotationAngle = () => {
        const activeObject = canvas.getActiveObject()
        if (activeObject) {
          activeObject.snapAngle = 1
          const angle = activeObject.angle
          const angleAsText = 'Tason kulma: ' + angle.toFixed(0) + '°'
          setAngleText(angleAsText)
        }
      }

      const handleHideRotationAngle = () => {
        setAngleText('')
      }

      const handleHistoryUpdate = () => {
        const json = canvas.toJSON()
        setHistory(prevHistory => [...prevHistory, json])
        setHistoryIndex(prevIndex => prevIndex + 1)
      }

      const handleCheckBoundaries = (e) => {
        let obj = e.target
        
        // if object is too big ignore
        if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
          return
        }
        obj.setCoords()

        function calculateRotatedCenter(a, b, c) {
          const angleInRadians = (c * Math.PI) / 180
        
          if ((c > 45 && c < 135) || (c > 225 && c < 315)) {
            [a, b] = [b, a]
          }
        
          const x = Math.abs((a / 2) * Math.cos(angleInRadians) - (b / 2) * Math.sin(angleInRadians))
          const y = Math.abs((a / 2) * Math.sin(angleInRadians) + (b / 2) * Math.cos(angleInRadians))
        
          return { x, y }
        }
 
          let centerCoordinates = calculateRotatedCenter(obj.width * obj.scaleX, obj.height * obj.scaleY, obj.angle)
          let objHalfWidth = centerCoordinates.x
          let objHalfHeight = centerCoordinates.y

          let objCenterOverTop = obj.getBoundingRect().top + objHalfHeight < 1
          let objCenterOverBottom = obj.getBoundingRect().top > originalCanvasHeight - objHalfHeight
          let objCenterOverLeft = obj.getBoundingRect().left + objHalfWidth < 1
          let objCenterOverRight = obj.getBoundingRect().left > originalCanvasWidth - objHalfWidth

          if (objCenterOverTop) {
            const deltaY = 1 - (obj.getBoundingRect().top + objHalfHeight)
            obj.top += deltaY
          }
          
          if (objCenterOverBottom) {
            const deltaY = (obj.getBoundingRect().top + objHalfHeight) - originalCanvasHeight
            obj.top -= deltaY
          }
          
          if (objCenterOverLeft) {
            let deltaX
            if (obj.angle === 90 || obj.angle === 270) {
              deltaX = 1 - (obj.getBoundingRect().left + objHalfHeight)
            } else {
              deltaX = 1 - (obj.getBoundingRect().left + objHalfWidth)
            }
            obj.left += deltaX
          }
          
          if (objCenterOverRight) {
            let deltaX
            if (obj.angle === 90 || obj.angle === 270) {
              deltaX = (obj.getBoundingRect().left + objHalfHeight) - originalCanvasWidth
            } else {
              deltaX = (obj.getBoundingRect().left + objHalfWidth) - originalCanvasWidth
            }
            obj.left -= deltaX
          }
        }

      canvas.on('selection:created', handleSelectionUpdated)
      canvas.on('selection:updated', handleSelectionUpdated)
      canvas.on('selection:cleared', handleSelectionUpdated)

      canvas.on('object:moving', handleCheckBoundaries)
      canvas.on('object:scaling', handleCheckBoundaries)
      canvas.on('object:rotating', handleShowRotationAngle)
      canvas.on('object:modified', () => {
        handleHideRotationAngle()
        handleHistoryUpdate()
      })

      let movingWholeCanvas = false
      canvas.on('mouse:up', function(e) {
        movingWholeCanvas = false
      })

      canvas.on('mouse:down', function(e) {
        if (canvas.getActiveObject() == null && canvas?.getZoom() > 1) {
          movingWholeCanvas = true
        }
      })

      canvas.on('mouse:move', function(e) {
        if (movingWholeCanvas && e && e.e && canvas?.getZoom() > 1) {
          const delta = new fabric.Point(e.e.movementX, e.e.movementY)
          canvas.relativePan(delta)
        }
      })

      return () => {
        canvas.off('selection:created', handleSelectionUpdated)
        canvas.off('selection:updated', handleSelectionUpdated)
        canvas.off('selection:cleared', handleSelectionUpdated)
        canvas.off('object:rotating', handleShowRotationAngle)
        canvas.off('object:moving')
        canvas.off('object:scaling')
        canvas.off('object:modified')
        canvas.off('mouse:up')
        canvas.off('mouse:down')
        canvas.off('mouse:move')
      }
    }
    // eslint-disable-next-line
  }, [canvas, currentCanvasIndex])

  useEffect(() => {
    if (!selectedLoadingDone) {
        actionProductSelect(productID)
    }

    if (selectedLoadingDone && selected) {
        let filteredVariaatiot = []
        if (location.search === '?outlet=true') {
          filteredVariaatiot = selected.variaatiot.filter((el) => el.tagit.outlet)
        } else {
          filteredVariaatiot = selected.variaatiot.filter((el) => !el.tagit.outlet)
        }

        setProductState({
            ...productState,
            loading: false,
            id: selected._id,
            tuoteKoodi: selected.tuoteKoodi,
            tuoteNimi: selected.tuoteNimi,
            brandi: selected.brandi,
            pakkauskoko: selected.pakkauskoko,
            tuoteRyhma: selected.tuoteRyhma,
            alaRyhma: selected.alaRyhma,
            malli: selected.malli,
            genre: selected.genre,
            kaulaAukko: selected.kaulaAukko,
            materiaali: selected.materiaali,
            istutus: selected.istutus,
            painoLuokka: selected.painoLuokka,
            samankaltaiset: selected.samankaltaiset,
            tuoteperhe: selected.tuoteperhe,
            yksikko: selected.yksikko,
            kuvaus: selected.kuvaus,
            kevytKuvaus: selected.kevytKuvaus,
            kayttovinkki: selected.kayttovinkki,
            aktiivinen: selected.aktiivinen,
            variRyhmat: selected.variRyhmat,
            variNimet: selected.variNimet,
            variaatiot: filteredVariaatiot,
            liitteet: selected.liitteet,
            kuvat: selected.kuvat,
        })

        const activeVariants = filteredVariaatiot.filter((el) => el.aktiivinen)
        const colors = activeVariants.map((el) => el.vari)
        const colorsUnique = [...new Set(colors)]

        setColorStrings(colorsUnique)
    }

    if (selectedLoadingDone && !selected) {
      navigate('/404', { replace: true })
    }
    // eslint-disable-next-line
  }, [selectedLoadingDone])

    useEffect(() => {
    if (colorStrings) {
      const colorObjects = colorStrings.map((el) => ({
        code: el,
        tooltip: getColor(el, brandi),
        group: getColorGroup(el, brandi),
      }))

      const colorOrder = [
        'Musta',
        'Harmaa',
        'Valkoinen',
        'Ruskea',
        'Keltainen',
        'Oranssi',
        'Punainen',
        'Vaaleanpunainen',
        'Violetti',
        'Sininen',
        'Vihreä',
        'Moniväri',
      ]

      const sortedColors = colorObjects.sort((a, b) => {
        return colorOrder.indexOf(a.group) - colorOrder.indexOf(b.group)
      })

      const colorButtons = sortedColors.map((el) => (
        <OverlayTrigger
          key={el.code}
          placement='top'
          delay={{ show: 200, hide: 200 }}
          overlay={<Tooltip id='tooltip-top'>{el.tooltip}</Tooltip>}>
          <div
            id={el.code}
            className={colorSelected === el.code ? 'colorButton activeColor' : 'colorButton'}
            onClick={
              colorSelected === el.code
                ? null
                : () => {
                    selectImage(undefined)
                    navigate(`/image-editor/${productID}${useOutletVariants ? '?outlet=true' : ''}#${el.code}`, { replace: true })
                  }
            }>
            <img
              src={`${proxy}/images/${tuoteKoodi}/${tuoteKoodi}-${el.code.replace(
                '/',
                '_'
              )}-thumbnail.jpg`}
              alt=''
              onError={({ currentTarget }) => {
                currentTarget.onerror = null //estä loop
                currentTarget.src = `${proxy}/images/placeholder.jpg`
              }}></img>
          </div>
        </OverlayTrigger>
      ))
      setColorButtons(colorButtons)
    }

    if (colorSelected) {
      const imageButtons =
        <ProductImageButtons
          selectImage={selectImage}
          tuoteKoodi={productID}
          colorSelected={colorSelected}
          allImages={kuvat}
        />
      setImageButtons(imageButtons)
      selectImage(`${proxy}/images/${productID}/${productID}-${colorSelected.replace('/','_')}-thumbnail.jpg`)
    }
    // eslint-disable-next-line
  }, [colorStrings, colorSelected, utils])

  useEffect(() => {
    if (canvas) {
      handleZoom(1)
    }
    // eslint-disable-next-line
  }, [imageSelected])

    useEffect(() => {
      if (!hash && colorStrings?.length) {
        const firstColorCode = colorStrings[0]
        navigate(`/image-editor/${productID}#${firstColorCode}`, { replace: true })
      } else {
        selectColor(hash.replace('#', ''))
      }
      // eslint-disable-next-line
    }, [hash, colorStrings, location])

    useEffect(() => {
      const handleKeyDown = (event) => {
        // Delete-näppäimellä tason poisto
        if (event.key === 'Delete') {
          deleteLayer()
        }
        // Backspace-näppäimellä tason poisto (kun ei olla edit-tilassa)
        if (event.key === 'Backspace') {
          const activeObject = canvas.getActiveObject()
          if (activeObject && activeObject.type === 'textbox' && activeObject.isEditing) {
            return
          }
          if (activeObject) {
            const confirmDelete = window.confirm('Haluatko varmasti poistaa aktiivisen tason?')
            if (confirmDelete) {
              canvas.remove(activeObject)
            }
          }
        }

        const backgroundImage = canvas.backgroundImage

        // CTRL + Z näppäimellä undo-toiminto
        if (event.ctrlKey && event.key === 'z') {
          event.preventDefault()
          if (historyIndex > 0) {
            canvas.loadFromJSON(history[historyIndex - 1], () => {
              canvas.setBackgroundImage(backgroundImage)
              canvas.renderAll()
            })
            setHistoryIndex(historyIndex - 1)
            setHistory(prevHistory => prevHistory.slice(0, -1))
          }
        }

        // CTRL + Y näppäimellä redo-toiminto
        // Ei käytössä koska tekee historian hallinnasta monimutkaista (kaksisuuntaisen)
        // if (event.ctrlKey && event.key === 'y') {
        //   event.preventDefault()
        //   if (historyIndex < history.length - 1) {
        //     setHistoryIndex(prevIndex => prevIndex + 1)
        //     canvas.loadFromJSON(history[historyIndex + 1])
        //   }
        // }

        // Tasojen kopiointi ja liittäminen näppäimistöllä (ei toimi)
        // if (event.metaKey || event.ctrlKey) {
        //   if (event.key === 'c') {
        //     event.preventDefault()
        //     copyLayer()
        //   } else if (event.key === 'v') {
        //     event.preventDefault()
        //     pasteLayer()
        //   }
        // }
      }

      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    // eslint-disable-next-line
    }, [canvas, history, historyIndex])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/webp': [],
      'application/pdf': []
    },
    onDrop: addNewImageLayer,
    maxFiles: 1
  })

  const dropZone = <div {...getRootProps({className: 'drop-zone'})}>
    <input {...getInputProps()} />
    <p>Raahaa kuvatiedosto tähän tai klikkaa valitaksesi tiedostoista</p>
    <span><FiUpload /></span>
  </div>

  // const diameterMax = 150
  // const diameterMin = 50
  // const diameterPercentage = 100 - (((diameter - diameterMin) / (diameterMax - diameterMin)) * 100)

  return (
    <div className='image-editor'>
        <Helmet>
            <title>Kuvaeditori {productID} | dc-collection</title>
        </Helmet>
        {loading ?
        <Spinner animation='border' id='customSpinner' /> :
        <div className='image-template' id='image-template'>
          <div className='image-editor-container-cols'>
            <div className='image-editor-col'>
            <div className='image-editor-product-title'>
            <div className='image-editor-title-col'>
              <img
              className='product-logo'
              src={getBrandLogo(selected?.brandi)} alt={brandi}
              />
              <div>
                <h1>{selected?.tuoteNimi} ({productID})</h1>
                {/* <span className='product-id'>{productID}</span> */}
              </div>
            </div>
            <div className='image-editor-title-col'>
              <h2 id="dc-collection"><strong>dc-collection</strong></h2>
            </div>
          </div>
              <div className='image-editor-select-color'>
                <h2>Valitse väri: {getColor(colorSelected, brandi)}</h2>
                <div className='image-editor-colorbuttons'>
                  {colorButtons}
                </div>
              </div>
              <div className='image-editor-select-image'>
                <h2>Valitse pohjakuva</h2>
                <div className='image-editor-imagebuttons'>
                  {imageButtons}
                </div>
              </div>
              <div className='image-editor-text-editor'>
                <h2>Lisää tekstitaso</h2>
                <p>Lisää kuvaeditoriin tekstitaso, johon voit kirjoittaa tekstiä.</p>
                <p>Tekstille voit määrittää mm. fontin, värin, paksuuden ja tasauksen.</p>
                <Button variant='outline-primary' onClick={() => addNewTextLayer()}><span>Uusi tekstitaso</span><FiPlus /></Button>
              </div>
              <div className='image-editor-upload-image'>
                <h2>Lisää kuvataso</h2>
                <p>Lisää kuvaeditoriin oma kuva laitteeltasi, esimerkiksi logo.</p>
                <p>Tuetut tiedostomuodot: jpg, jpeg, png, webp, pdf.</p>
                {dropZone}
              </div>
            </div>
            <div className='image-editor-col'>
              <div className='image-editor-layer-tools'>
                {/* <h2>Tasotyökalut</h2> */}
                <div className='layer-tools-container'>
                {activeLayerType === 'image' || activeLayerType === 'textbox' ? 
                <div className='tool-row'>
                  <span className='text'></span>
                </div>
                : null}
                  {activeLayerType === 'image' ?
                    <div className='tool-row'>
                      <div>
                        <label htmlFor="brightness">Kirkkaus {brightness + 100}%</label>
                        <input
                        type="range"
                        name="brightness"
                        min="-100"
                        max="100"
                        step="2"
                        value={brightness}
                        onChange={handleBrightnessChange}
                        onMouseUp={forceHistoryUpdate}
                        />
                      </div>
                      <div>
                        <label htmlFor="saturate">Kylläisyys {saturate + 100}%</label>
                        <input
                        type="range"
                        name="saturate"
                        min="-100"
                        max="100"
                        step="2"
                        value={saturate}
                        onChange={handleSaturateChange}
                        onMouseUp={forceHistoryUpdate}
                        />
                      </div>
                      <div>
                        <label htmlFor="hue">Sävy {hue}°</label>
                        <input
                        type="range"
                        name="hue"
                        min="-100"
                        max="100"
                        step="2"
                        value={hue}
                        onChange={handleHueChange}
                        onMouseUp={forceHistoryUpdate}
                        />
                      </div>
                      <button className='text-tool-btn' style={{ marginTop: '1px' }} hidden={whiteIsRemoved} onClick={() => handleRemoveColorChange()}>Valkoisen poisto</button>
                      <button title='Peilikuva' className='text-tool-btn' style={{ marginTop: '1px' }} onClick={() => handleFlippedImageChange(!flippedImage)}><LuFlipHorizontal2/></button>
                    </div> : null
                  }
                  {activeLayerType === 'textbox' || activeLayerType === 'text-curved' ?
                    <div className='tool-row'>
                      <select value={fontFamily} onChange={handleFontFamilyChange} style={{ marginLeft: '10px', padding: '3px 0', borderRadius: '5px' }}>
                      {fontOptions.map((font, index) => (
                      <option key={index} value={font}>{font}</option>
                      ))}
                      </select>
                      <OverlayTrigger rootClose trigger='click' placement='bottom' overlay={colorPickerPopover}>
                        <button title='Tekstin väri' className='text-tool-btn text-tool-color-picker'>
                          <div id='text-color-frame' className='color-frame' style={{ backgroundColor: previewColor(textColor), opacity: textColorOpacity }} />
                        </button>
                      </OverlayTrigger>
                      <button title='Lihavoitu teksti' className={isBold ? 'text-tool-btn active margin-left' : 'text-tool-btn margin-left'} onClick={toggleBold}><BiBold/></button>
                      <button title='Kursivoitu teksti' className={isItalic ? 'text-tool-btn active' : 'text-tool-btn'} onClick={toggleItalic}><BiItalic/></button>
                      <button title='Alleviivattu teksti' className={isUnderlined ? 'text-tool-btn active' : 'text-tool-btn'} onClick={toggleUnderlined} hidden={activeLayerType === 'text-curved'}><BiUnderline/></button>
                      <button title='Tekstin ääriviivat' className={isOutlined ? 'text-tool-btn margin-left active' : 'text-tool-btn margin-left'} onClick={toggleOutline}><div className='custom-icon'><img id='stroke-icon' src={strokeIcon} alt=''/></div></button>
                      <OverlayTrigger rootClose trigger='click' placement='bottom' overlay={outlineColorPickerPopover}>
                        <button title='Ääriviivojen väri' className='text-tool-btn text-tool-color-picker'>
                          <div id='outline-color-frame' className='color-frame' style={{ backgroundColor: previewColor(outlineColor) }} />
                        </button>
                      </OverlayTrigger>
                      <button title='Tasaa vasemmalle' className={textAlign === 'left' ? 'text-tool-btn margin-left active' : 'text-tool-btn margin-left'} onClick={setTextAlignLeft} hidden={activeLayerType === 'text-curved'}><BiAlignLeft/></button>
                      <button title='Tasaa keskelle' className={textAlign === 'center' ? 'text-tool-btn active' : 'text-tool-btn'} onClick={setTextAlignCenter} hidden={activeLayerType === 'text-curved'}><BiAlignMiddle/></button>
                      <button title='Tasaa oikealle' className={textAlign === 'right' ? 'text-tool-btn active' : 'text-tool-btn'} onClick={setTextAlignRight} hidden={activeLayerType === 'text-curved'}><BiAlignRight/></button>
                      <button title='Kaareva teksti' className='text-tool-btn margin-left' onClick={changeToCurvedText} hidden={activeLayerType === 'text-curved'}><PiBezierCurve/></button>
                    </div> : null
                  }
                  {activeLayerType !== 'image' && activeLayerType !== 'textbox' && activeLayerType !== 'text-curved' ?
                    <>
                      <div className='tool-row'>
                        <span className='text'></span>
                      </div>
                      <div className='tool-row'>
                        <span className='text'></span>
                      </div>
                      <div className='tool-row'>
                        <span className='text'>Valitse taso.</span>
                      </div>
                    </> :
                    <div className='tool-row'>
                      <button className='text-tool-btn' style={{ marginLeft: '10px' }} onClick={() => copyLayer()}>Kopioi taso</button>
                      <button className='text-tool-btn' onClick={() => pasteLayer()}>Liitä taso</button>
                      <button className='text-tool-btn red' title='Poista taso' onClick={() => deleteLayer()}><span><FiTrash2 /></span></button>
                      {activeLayerType === 'text-curved' ?
                      <>
                        <div>
                        <label htmlFor='fontSize'>Merkkikoko {fontSize / 2 - 23}</label>
                        <input
                        type='range'
                        className='width-200'
                        name='fontSize'
                        min='48'
                        max='146'
                        step={2}
                        value={fontSize}
                        onChange={handleFontSizeChange}
                        onMouseUp={forceHistoryUpdate}
                        />
                      </div>
                        <div>
                          <label htmlFor='kerning'>Merkkiväli {kerning.toFixed(1) / 0.5}</label>
                          <input
                          type='range'
                          className='width-200'
                          name='kerning'
                          min='0'
                          max='25'
                          step={0.5}
                          value={kerning}
                          onChange={handleKerningChange}
                          onMouseUp={forceHistoryUpdate}
                          />
                        </div>
                        {/* <div>
                          <label htmlFor='flipped'>Käänteinen</label>
                          <input type='checkbox' checked={flipped} onChange={handleFlippedChange} />
                        </div> */}
                      </> : null}
                    </div>
                  }
                  {activeLayerType === 'text-curved' ?
                  <div className='tool-row diameter-row'>
                      <div className='diameter-container'>
                        <div>
                          {/* <span>-1500</span> */}
                          <label htmlFor='diameter' id='diameter-label'>Kaarevuus {parseInt(diameter - 1600)}</label>
                          {/* <span>1500</span> */}
                        </div>
                        <div>
                          <button className='zoom-button minus' onClick={minusDiameter}><BiMinus /></button>
                          <input
                          id='diameter'
                          type='range'
                          name='diameter'
                          min='100'
                          max='3100'
                          step='5'
                          value={diameter}
                          onChange={handleDiameterChange}
                          onMouseUp={forceHistoryUpdate}
                          />
                          <button className='zoom-button plus' onClick={plusDiameter}><BiPlus /></button>
                        </div>
                      </div>
                  </div>
                  : null}
                </div>
              </div>
              <div id='image-editor-editable-image' className='image-editor-editable-image'>
                <canvas id='editor-canvas-0' hidden={currentCanvasIndex !== 0} style={{ width: '100% !important', height: '100% !important' }}></canvas>
                <canvas id='editor-canvas-1' hidden={currentCanvasIndex !== 1} style={{ width: '100% !important', height: '100% !important' }}></canvas>
                <canvas id='editor-canvas-2' hidden={currentCanvasIndex !== 2} style={{ width: '100% !important', height: '100% !important' }}></canvas>
                <span className='angle-info'>{angleText}</span>
                <div className='zoom-container'>
                  <button className='zoom-button minus' onClick={zoomOut}><BiMinus /></button>
                  <span id="zoom-value">{parseInt(zoomLevel * 100)}%</span>
                  <button className='zoom-button plus' onClick={zoomIn}><BiPlus /></button>
                </div>
              </div>
              <div className='image-editor-download-buttons'>
              <div className='canvas-switches'>
                <div>
                  <button className={currentCanvasIndex === 0 ? 'canvas-switch-button first active' : 'canvas-switch-button first'} onClick={() => switchCanvas(0)}>Kuva 1</button>
                  <input type="checkbox" checked={selectedForTransfer1} onChange={() => setSelectedForTransfer1(!selectedForTransfer1)} />
                </div>
                <div>
                  <button className={currentCanvasIndex === 1 ? 'canvas-switch-button active' : 'canvas-switch-button'} onClick={() => switchCanvas(1)}>Kuva 2</button>
                  <input type="checkbox" checked={selectedForTransfer2} disabled={!canvases[1]} onChange={() => setSelectedForTransfer2(!selectedForTransfer2)} />
                </div>
                <div>
                  <button className={currentCanvasIndex === 2 ? 'canvas-switch-button last active' : 'canvas-switch-button last'} onClick={() => switchCanvas(2)}>Kuva 3</button>
                  <input type="checkbox" checked={selectedForTransfer3} disabled={!canvases[2]} onChange={() => setSelectedForTransfer3(!selectedForTransfer3)} />
                </div>
              </div>
                <div className='big-blue-buttons'>
                  <Button variant='primary' onClick={() => handleImageToCardEditor(zoomLevel)} disabled={downloading} hidden={downloading}><span>Siirrä valitut kuvat tuotekorttiin</span><FiExternalLink/></Button>
                  <Button variant='primary' onClick={() => handleDownloadImage(zoomLevel)} disabled={downloading}>{downloading ? <Spinner animation='border' variant='light' /> : <><span>Lataa kuva</span><FiDownload /></>}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  product: state.product,
  utils: state.utils,
})

const reduxActions = {
  actionProductSelect,
}

export default connect(mapStateToProps, reduxActions)(ImageEditor)
