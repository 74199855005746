// React & Routing
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Styling
import './Brands.scss'

const Brands = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='Brands'>
      <Helmet>
        <title>Tuotemerkit | dc-collection</title>
      </Helmet>
      <h4>
        <strong>Tuotemerkit</strong>
      </h4>

      <div className='BrandsContainer'>
        <p className='p0'>BELLA+CANVAS</p>
        <p className='p1'>
          Korkealaatuiset Bella+Canvas tuotteet naisille ja miehille on valinta niille, jotka
          arvostavat mukavuutta, istuvuutta, laajaa v&auml;rivalikoimaa ja ennen kaikkea laatua,
          joka kest&auml;&auml; pesusta toiseen.
        </p>
        <p className='p0'>B&amp;C</p>
        <p className='p1'>
          B&amp;C tarjoaa nykyaikaisen ja t&auml;ysin viimeistelt&auml;viss&auml; olevan malliston:
          t-paidat, pikeet, colleget, kauluspaidat, fleecet, takit ja oheistuotteet, kaikki
          suunniteltu t&auml;ydent&auml;m&auml;&auml;n yrityksen markkinointiviestint&auml;&auml;.
          &nbsp;&nbsp;Useimmissa malleissa valittavana omat mallit naisille ja miehille, jopa
          lapsille, useissa eri v&auml;reiss&auml;.
        </p>
        <p className='p0'>BUGOFF</p>
        <p className='p1'>
          Hy&ouml;nteisilt&auml; suojaavat vaatteet ja asusteet naisille ja miehille. BugOff
          -tuotesarjan vaatteet tarjoavat tehokkaan, hajuttoman ja myrkytt&ouml;m&auml;n
          lis&auml;suojan hyttysi&auml;, s&auml;&auml;ski&auml; ja punkkeja vastaan. BugOff tuotteet
          ovat turvallisia sek&auml; k&auml;ytt&auml;j&auml;lleen ett&auml; ymp&auml;rist&ouml;lle.
        </p>
        <p className='p0'>COBALT FUSION</p>
        <p className='p1'>
          Cobalt Fusion takkimallisto ei ainoastaan n&auml;yt&auml; hyv&auml;lt&auml;, se antaa
          my&ouml;s suojan vaihtuvissa s&auml;&auml;olosuhteissa. Cobalt Fusion mallistossa yhdistyy
          kest&auml;vyys, vedenpit&auml;vyys, suoja tuulelta ja viimalta sek&auml;
          hengitt&auml;vyys, suomalainen suunnittelu, toiminnallinen muotoilu, oikeat materiaalit
          sek&auml; kulutuskest&auml;vyys.
        </p>
        <p className='p0'>COBALT GEAR&nbsp;</p>
        <p className='p1'>Cobalt Gear- ty&ouml;vaatteet, mukavuutta ja muotia</p>
        <p className='p1'>
          Oikeanlaiset ty&ouml;v&auml;lineet eiv&auml;t pelk&auml;st&auml;&auml;n helpota
          ty&ouml;ntekoa, ne tekev&auml;t siit&auml; my&ouml;s turvallisemman ja mukavamman. Cobalt
          Gear tekee siit&auml; nyt my&ouml;s muodikkaamman. Cobalt Gear - ty&ouml;vaatteissa
          mukavuus tarkoittaa todellista liikkumavapautta, joka mahdollistaa ammattimaisen
          ty&ouml;skentelyn hankalissakin ty&ouml;olosuhteissa. Materiaalivalintojen ansiosta asut
          sopivat niin sis&auml;- kuin ulkok&auml;ytt&ouml;&ouml;nkin.
        </p>
        <p className='p0'>COBALT UUSIO</p>
        <p className='p1'>
          Cobalt Uusio tuotteet ovat 100% kierr&auml;tysmateriaalista valmistettuja. Valmistuksessa
          k&auml;ytetty puuvilla on tuotannon leikkuuj&auml;tett&auml;, polyesteri on valmistettu
          muovij&auml;tteest&auml;, kuten muovipulloista. Kangas on valmistettu
          ymp&auml;rist&ouml;yst&auml;v&auml;llisesti, niin ett&auml; materiaalit on lajiteltu
          v&auml;reitt&auml;in, eik&auml; niit&auml; ole en&auml;&auml; erikseen v&auml;rj&auml;tty.
          Tuotteilla on GRS sertifikaatti.
        </p>
        <p className='p0'>GILDAN</p>
        <p className='p1'>
          Maailman johtavimpiin vaatevalmistajiin lukeutuva Gildan tarjoaa edullisen perusmalliston
          mainos- ja liikelahja-alalle. Kattavasta perusmallistosta l&ouml;yd&auml;t t-paidat,
          colleget ja pikeet, niin naisille, miehille kuin lapsille.
        </p>
        <p className='p1'>
          Kun etsit sopivaa v&auml;ri&auml;, se todenn&auml;k&ouml;isesti l&ouml;ytyy Gildan
          mallistosta.
        </p>
        <p className='p0'>KARIBAN</p>
        <p className='p1'>
          T&auml;m&auml;n laajempaa mallistoa saa hakea - mallisto sis&auml;lt&auml;&auml; laajan
          valikoiman erilaisia paitoja, collegetuotteita, housuja, takkeja, pyyhkeit&auml; ja
          asusteita. Mallisto on tarjottavissa miehille, naisille ja lapsille, kattaen kaikista
          pienimm&auml;t vauvakoot aina aikuisten isoimpiin kokoihin. &nbsp;
        </p>
        <p className='p0'>KIMOOD</p>
        <p className='p1'>
          Mallistosta l&ouml;ytyy laukkuja pienist&auml; pusseista aina korkealaatuisiin
          matkalaukkuihin ja sateenvarjoihin. Hintataso on saatu edulliseksi keskittym&auml;ll&auml;
          tuotekohtaisesti oleellisimpiin ratkaisuihin, kriittisist&auml; pisteist&auml; kuten
          renkaista, vahvikekankaista ja -ompeleista tinkim&auml;tt&auml;.
        </p>
        <p className='p0'>KUP</p>
        <p className='p1'>
          K-up monipuolisesta p&auml;&auml;hine- ja oheistuotevalikoimasta l&ouml;ytyy runsaasti
          erilaisia ja erilaatuisia lippalakkeja, trendikk&auml;it&auml; pipoja ja aina klassisia
          lieri- ja olkihattuja. T&auml;ydent&auml;vin&auml; tuotteina mallistosta l&ouml;ytyy
          muodikkaat huivit ja neulepipomallistoa t&auml;ydent&auml;v&auml;t kaulaliinat. Mit&auml;
          olisi oheistuotevalikoima ilman k&auml;ytt&ouml;kelpoista vy&ouml;mallistoa - punottujen
          ja silikonipintaisten lis&auml;ksi aidosta nahasta valmistettuja.
        </p>
        <p className='p0'>BLACK&amp;MATCH</p>
        <p className='p1'>
          Nykyaikaisesta Black&amp;Match mallistosta l&ouml;ytyy takit, fleece- ja softshell takit,
          laukut ja oheistuotteet. Mallisto tarjoaa eritt&auml;in edullisen ja kustannustehokkaan
          tavan tuotteiden r&auml;&auml;t&auml;l&ouml;innille - perustuotteet ovat mustia,
          mist&auml; l&ouml;ytyy kustakin v&auml;hint&auml;&auml;n 8 tehostev&auml;ri&auml;.
        </p>
        <p className='p0'>PROACT</p>
        <p className='p1'>
          Edulliset Proact urheiluvaatteet aktiiviselle liikkujalle ty&ouml;h&ouml;n ja
          vapaa-aikaan. Mallistosta l&ouml;ytyy paidat, takit, housut ja puvut sek&auml; runsas
          valikoima erilaisia urheiluun sopivia oheistuotteita kuten urheilukassit, sukat ja
          erilaiset urheiluv&auml;lineet - kaikki k&auml;ytt&ouml;kelpoisia tuotteita juoksusta ja
          kuntoilusta perinteisiin pallopeleihin
        </p>
        <p className='p0'>PROMODORO</p>
        <p className='p1'>
          Promodoro tarjoaa eritt&auml;in laajan perinteisin yritys- ja mainosvaatemalliston. Kaikki
          puuvillaiset tuotteet on pest&auml;viss&auml; 60 asteessa ja siksi mallisto toimii niin
          vapaa- kuin ty&ouml;ajalla. Korkealuokkaisen laadun lis&auml;ksi Promodoro tarjoaa parhaan
          valikoiman moniv&auml;risi&auml; tuotteita, joissa saatavana useita eri
          v&auml;riyhdistelmi&auml;. Promodorolle kukaan ei ole liian pieni tai liian iso.
          Perusmalliston pienimm&auml;t koot alkavat 56 cm:st&auml; ja p&auml;&auml;ttyv&auml;t
          parhaimmillaan 8XL:&auml;&auml;n.
        </p>
        <p className='p0'>STORMTECH</p>
        <p className='p1'>
          Vuodesta 1977 Stormtech on vastannut menestyksellisesti vaativan ja ennalta arvaamattoman
          ilmaston vaatimuksiin. Tuotteissa huolella valitut erikoismateriaalit ja tekniset
          ominaisuudet on yhdistetty ammattitaidolla ja korkealuokkaisella suunnittelulla jokaiseen
          tuotteeseen. Tuloksena korkealaatuinen, trendik&auml;s ja toiminnallinen mallisto.
        </p>
        <p className='p0'>VELILLA</p>
        <p className='p1'>
          Yli 70 vuoden laaja-alainen kokemus tekstiileistä näkyy malliston laajuudessa kattaen
          työvaatteet niin teollisuuden, terveydenhuollon sekä palvelusektorin kokeista
          tarjoilijoihin. Vahva työvaateosaaminen näkyy myös värikombinaatioista, joista on helppo
          löytää yrityksesi imagoon sopivat tuotteet.
        </p>
        <p className='p0'>WK Designed to work</p>
        <p className='p1'>
          Vuonna 2020 lanseerattu DayToDay mallisto on nyt WK Designed to work. Uusi WK
          p&auml;ivitt&auml;iseen k&auml;ytt&ouml;&ouml;n suunniteltu mallisto - sopii
          ty&ouml;h&ouml;n ja vapaa-aikaan. &nbsp;&nbsp;Malliston tunnusmerkkej&auml; ovat moderni
          leikkaus, laadukkaat materiaalit ja pesu 60&deg;C asteessa. Tuotteet sopivat
          k&auml;ytett&auml;v&auml;ksi p&auml;iv&auml;st&auml; toiseen. &nbsp;&nbsp;Saatavilla
          olevat v&auml;rivaihtoehdot tekev&auml;t WK mallistosta ty&ouml;paikan todellisen
          tiimivaatteen. Tuloksena korkealaatuinen, trendik&auml;s ja toiminnallinen mallisto.
        </p>
        <p className='p0'>X.0</p>
        <p className='p1'>
          X.O malliston ainutlaatuinen Millennial materiaali aiheuttaa ansaittua ihastusta ja
          ihmetyst&auml;. Ainutlaatuinen sileys, keveys ja pehmeys yhdistettyn&auml;
          miellytt&auml;v&auml;&auml;n joustavuuteen ilman, ett&auml; materiaaliin on lis&auml;tty
          viimeistelyaineita, elastaania tai muuta joustavaa materiaali,
          h&auml;mm&auml;stytt&auml;&auml; k&auml;ytt&auml;j&auml;ns&auml;.
        </p>
      </div>
    </div>
  )
}

export default connect()(Brands)
