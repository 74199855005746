import { CONTACT_SUCCESS, CONTACT_RESET } from '../actions/types'

const initialState = {
  success: false,
}

export default function reducerContact(state = initialState, action) {
  const { type } = action

  switch (type) {
    case CONTACT_SUCCESS:
      return {
        success: true,
      }

    case CONTACT_RESET:
      return initialState

    default:
      return state
  }
}
