import { UTILS_GET, UTILS_UPDATE, UTILS_RESET, UTILS_ERROR } from '../actions/types'

const initialState = {
  colors_bc: [],
  colors_gildan: [],
  colors_master: [],
  colors_mukua: [],
  colors_velilla: [],
  sizes: [],
  loadingDone: false,
}

export default function reducerUtils(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case UTILS_GET:
    case UTILS_UPDATE:
      return {
        ...state,
        colors_bc: payload.colors_bc,
        colors_gildan: payload.colors_gildan,
        colors_master: payload.colors_master,
        colors_mukua: payload.colors_mukua,
        colors_velilla: payload.colors_velilla,
        sizes: payload.sizes,
        collections: payload.collections,
        loadingDone: true,
      }

    case UTILS_RESET:
      return initialState

    case UTILS_ERROR:
    default:
      return state
  }
}
