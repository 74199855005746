// React & Routing
import React, { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'
import {
  actionCampaignGetProducts,
  actionResetCurrentCampaign,
} from '../../redux/actions/action.campaign'
import { actionFavoritesAdd, actionRecentsGet } from '../../redux/actions/action.auth'

// Components
import Filter from '../../components/Filter/Filter'
import PaginationComponent from '../../components/Pagination/Pagination'

// Bootstrap
import { Breadcrumb, Container, Form, Button, Spinner } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

// Utils
import { createItemButtons } from '../../components/ItemButton/ItemButton'
import { formatStormtechCode, parseQuery } from '../../utils/getFunctions'
import proxy from '../../utils/proxy'

// Styling
import bars from '../../images/bars-solid.svg'
import './ItemBrowse.scss'

const Campaign = ({
  getSize,
  campaign: { all, products, productsLoadingDone },
  isAuthenticated,
  favorites,
  recents,
  utils,
  prevRecentsQuery,
  actionCampaignGetProducts,
  actionResetCurrentCampaign,
  actionRecentsGet,
  actionFavoritesAdd,
}) => {
  let wls = window.location.search
  const { cid } = useParams()
  const source = 'campaign'

  // isMobile mini component starts
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate()

  const updateWindowDimensions = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return function cleanUp() {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (screenWidth && screenWidth > 1024 && isMobile) {
      setIsMobile(false)
    } else if (screenWidth && screenWidth < 1025 && !isMobile) {
      setIsMobile(true)
    }
  }, [screenWidth, setScreenWidth, isMobile])
  // isMobile mini component ends

  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(undefined)
  const [sort, setSort] = useState(undefined)
  const [currentCampaign, setCurrentCampaign] = useState(undefined)
  const [defaultList, setDefaultList] = useState([])
  const [itemList, setItemList] = useState([])
  const [itemButtons, setItemButtons] = useState(undefined)
  const [pages, setPages] = useState(1)
  const [recentsButtons, setRecentsButtons] = useState(undefined)
  const [banner, setBanner] = useState(null)
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    setLoading(true)
    setItemButtons(undefined)
    setDefaultList([])
    setItemList([])
    window.scrollTo(0, 0)

    if (all && all.length > 0) {
      const theCampaign = all.filter((el) => el.cid === cid)
      setCurrentCampaign(theCampaign[0])
    }
    // eslint-disable-next-line
  }, [cid, all])

  useEffect(() => {
    if (currentCampaign) {
      const codes = currentCampaign.products
      actionCampaignGetProducts(codes)
      if (currentCampaign.image_secondary) {
        setBanner(<img src={`${proxy}/images/campaigns/campaign_${cid}_secondary.jpg`} alt='' />)
      } else {
        setBanner(<h1>{currentCampaign.name}</h1>)
      }
    }
    // eslint-disable-next-line
  }, [currentCampaign])

  useEffect(() => {
    return function cleanUp() {
      actionResetCurrentCampaign()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isMobile) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
    // eslint-disable-next-line
  }, [currentPage])

  useEffect(() => {
    let lsRecents = localStorage.getItem('recently-viewed')
    let reduxAndLsMatch = false
    let prevQueryAndLsMatch = false

    if (lsRecents) {
      reduxAndLsMatch =
        recents
          .map((el) => el.tuoteKoodi)
          .sort()
          .join(',') === JSON.parse(lsRecents).sort().join(',')

      prevQueryAndLsMatch =
        prevRecentsQuery.sort().join(',') === JSON.parse(lsRecents).sort().join(',')
    }

    if (!lsRecents) {
      setRecentsButtons(null)
    } else if ((!recents || !recents.length) && lsRecents) {
      actionRecentsGet(JSON.parse(lsRecents))
    } else if ((recents && recents.length && lsRecents && reduxAndLsMatch) || prevQueryAndLsMatch) {
      const elements = recents.map((el) => (
        <Link
          className='recentBtnLink animated fadeInUp'
          to={`/product/${el.tuoteKoodi}`}
          key={el.tuoteKoodi}>
          <div className='recentContainer' key={el.tuoteKoodi}>
            <div className='recentColOne'>
              <img
                src={`${proxy}/images/${el.tuoteKoodi}/${el.tuoteKoodi}-paakuva-thumbnail.jpg`}
                alt=''
                height='80'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null //estä loop
                  currentTarget.src = `${proxy}/images/placeholder.jpg`
                }}
              />
            </div>
            <div className='recentColTwo'>
              <p>
                {el.brandi === 'Stormtech' ? formatStormtechCode(el.tuoteKoodi) : el.tuoteKoodi}
              </p>
              <p style={{ fontWeight: '500' }}>{el.tuoteNimi}</p>
              <p>
                Alkaen{' '}
                <span style={{ color: '#0067E0', fontWeight: '600' }}>
                  {Math.min(...el.variaatiot.map((el2) => el2.ovh))
                    .toFixed(2)
                    .replace('.', ',')}{' '}
                  €
                </span>
              </p>
            </div>
          </div>
        </Link>
      ))
      setRecentsButtons(elements)
    } else if (!prevQueryAndLsMatch) {
      actionRecentsGet(JSON.parse(lsRecents))
    } else {
      setRecentsButtons(null)
    }
    // eslint-disable-next-line
  }, [recents])

  useEffect(() => {
    if (productsLoadingDone && products.length > 0) {
      setDefaultList(products)
      setItemList(products)
    } else if (productsLoadingDone && products.length === 0) {
      setItemButtons(undefined)
    }
    // eslint-disable-next-line
  }, [products])

  const compareName = (a, b) => {
    if (a.tuoteNimi < b.tuoteNimi) {
      return -1
    }
    if (a.tuoteNimi > b.tuoteNimi) {
      return 1
    }
    return 0
  }

  const comparePrice = (a, b) => {
    if (a.startingPrice < b.startingPrice) {
      return -1
    }
    if (a.startingPrice > b.startingPrice) {
      return 1
    }
    return 0
  }

  useEffect(() => {
    if (itemList && sort) {
      switch (sort) {
        case 'Nimi (A-Ö)':
          itemList.sort(compareName)
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated
            )
          )
          break
        case 'Nimi (Ö-A)':
          itemList.sort(compareName).reverse()
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated
            )
          )
          break
        case 'Hinta (halvin ensin)':
          itemList.sort(comparePrice)
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated
            )
          )
          break
        case 'Hinta (kallein ensin)':
          itemList.sort(comparePrice).reverse()
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated
            )
          )
          break
        default:
          itemList.sort(compareName)
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated
            )
          )
          break
      }
    }
  }, [sort, itemList, favorites, actionFavoritesAdd, isAuthenticated])

  useEffect(() => {
    if (itemButtons && itemButtons.length > 0) {
      setLoading(false)
    } else if (defaultList.length > 0) {
      setLoading(false)
    } else if (itemButtons === undefined) {
      setLoading(false)
    } else {
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [itemButtons])

  useEffect(() => {
    const howManyPages = Math.ceil(itemList.length / 20)
    setPages(howManyPages)
    if (itemList.length > 0 && howManyPages < currentPage) {
      setCurrentPage(1)
    }
    // eslint-disable-next-line
  }, [itemList])

  const sortProducts = (e) => {
    const q = parseQuery(window.location.search)
    navigate(
      `/${source}/${cid}?page=${q.page}&sort=${e.target.value}&price=${q.price}&filter=${q.filter}`
    )
  }

  const [breadcrumb, setBreadcrumb] = useState(null)
  const [title, setTitle] = useState(null)

  useEffect(() => {
    if (cid && currentCampaign) {
      const bcElement = (
        <Breadcrumb>
          <LinkContainer to='/'>
            <Breadcrumb.Item>Etusivu</Breadcrumb.Item>
          </LinkContainer>
          {currentCampaign.type === source ? (
            <LinkContainer to='/campaigns'>
              <Breadcrumb.Item>Kampanjat</Breadcrumb.Item>
            </LinkContainer>
          ) : null}
          {currentCampaign ? (
            <LinkContainer to={`/${source}/${cid}`}>
              <Breadcrumb.Item active>{currentCampaign.name}</Breadcrumb.Item>
            </LinkContainer>
          ) : null}
        </Breadcrumb>
      )
      setBreadcrumb(bcElement)
      setTitle(currentCampaign.name)
    }
  }, [cid, currentCampaign])

  // Takaisin painikkeella skrollaus samaan y-koordinaattiin
  let scrollMemory = JSON.parse(localStorage.getItem('scroll-memory'))
  const [scrollDone, setScrollDone] = useState(false)

  useEffect(() => {
    if (
      scrollMemory &&
      !scrollDone &&
      scrollMemory.y <= document.body.scrollHeight &&
      scrollMemory.body === document.body.scrollHeight &&
      scrollMemory.prevPage === window.location.href
    ) {
      window.scrollTo(0, scrollMemory.y)
      setScrollDone(true)
      localStorage.removeItem('scroll-memory')
    }
    // eslint-disable-next-line
  }, [document.body.scrollHeight])
  // Päättyy

  const changePage = (n) => {
    const q = parseQuery(window.location.search)
    q.page = n
    navigate(`/${source}/${cid}?page=${q.page}&sort=${q.sort}&price=${q.price}&filter=${q.filter}`)
  }

  const [htmlTitle, setHtmlTitle] = useState('')

  useEffect(() => {
    if (window.location.search && title) {
      const q = parseQuery(window.location.search)
      const filterValues = Object.values(JSON.parse(q.filter)).filter((el) => el.length > 0)

      if (filterValues && filterValues.length) {
        setHtmlTitle(
          `${title} | ${filterValues.map((el) => el[0]).join(', ')} | Sivu ${q.page} | ${
            q.sort
          } | dc-collection`
        )
      } else {
        setHtmlTitle(`${title} | Sivu ${q.page} | ${q.sort} | dc-collection`)
      }
    }
  }, [wls, title])

  return (
    <div className='ItemBrowse'>
      <Helmet>
        <title>{htmlTitle ? htmlTitle : 'dc-collection'}</title>
      </Helmet>
      {breadcrumb}
      <Container className='topBox topCampaign'>{banner}</Container>

      <Container className='categoryContainer'>
        <div
          className={isMobile ? 'mobile-filter' : 'categoryCol1'}
          hidden={isMobile && !showFilter}>
          <Filter
            itemList={itemList}
            setItemList={setItemList}
            defaultList={defaultList}
            category={cid}
            source={source}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sort={sort}
            setSort={setSort}
            setShowFilter={setShowFilter}
            title={title}
            getSize={getSize}
            utils={utils}
          />

          <div className='recentlyViewedBox' hidden={isMobile || !recentsButtons}>
            <h6>VIIMEKSI KATSOTUT</h6>
            <div className='recentlyViewedButtons'>{recentsButtons}</div>
          </div>
        </div>

        <div className='categoryCol2'>
          <div className='topBox2'>
            <p>
              Tuotteita esillä: {itemButtons ? `${itemList.length}` : '-'}
              {/* Tuotteita esillä: {itemButtons ? `${itemList.length} / ${products.length}` : '- / -'} */}
            </p>
            <Button
              variant='light'
              className='mobile-only mobile-filter-toggle'
              onClick={() => setShowFilter(!showFilter)}>
              TUOTERAJAUS
              <img src={bars} alt='bars' width='13' height='13' style={{ marginLeft: '5px' }} />
            </Button>
            <Form.Group className='sortForm'>
              <Form.Label>Järjestä:</Form.Label>
              <Form.Control
                name='sort'
                type='text'
                as='select'
                value={sort}
                onChange={sortProducts}>
                <option>Nimi (A-Ö)</option>
                <option>Nimi (Ö-A)</option>
                <option>Hinta (halvin ensin)</option>
                <option>Hinta (kallein ensin)</option>
              </Form.Control>
            </Form.Group>
            <PaginationComponent source={source} id={cid} pages={pages} currentPage={currentPage} />
          </div>

          <div className='productsBox'>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}>
                <Spinner animation='border' id='customSpinner' />
              </div>
            ) : null}

            {!loading && itemButtons && itemButtons.length > 0 && !isMobile
              ? itemButtons.slice(currentPage * 20 - 20, currentPage * 20)
              : null}

            {!loading && itemButtons && itemButtons.length > 0 && isMobile
              ? itemButtons.slice(0, currentPage * 20)
              : null}

            {!loading && itemButtons && itemButtons.length === 0 ? (
              <p>Tekemälläsi rajauksella ei löytynyt tuotteita.</p>
            ) : null}

            {!loading && !itemButtons ? (
              <p>Tekemälläsi rajauksella ei löytynyt tuotteita.</p>
            ) : null}

            {itemButtons && pages > 1 ? (
              <PaginationComponent
                source={source}
                id={cid}
                pages={pages}
                currentPage={currentPage}
              />
            ) : null}
          </div>
          {isMobile && itemButtons && pages > 1 && currentPage !== pages ? (
            <p
              style={{
                padding: '0 10px',
                margin: 'auto auto 15px auto',
                fontSize: '13px',
              }}
              className='mobile-only'>{`${currentPage * 20} / ${itemList.length}`}</p>
          ) : null}
          {isMobile && itemButtons && pages > 1 && currentPage !== pages ? (
            <Button
              variant='black'
              style={{ padding: '10px 25px', margin: 'auto' }}
              className='mobile-only'
              onClick={() => changePage(currentPage + 1)}>
              NÄYTÄ LISÄÄ
            </Button>
          ) : null}
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  recents: state.auth.recents,
  prevRecentsQuery: state.auth.prevRecentsQuery,
  favorites: state.auth.user.favorites,
  isAuthenticated: state.auth.isAuthenticated,
  utils: state.utils
})

const reduxActions = {
  actionCampaignGetProducts,
  actionResetCurrentCampaign,
  actionRecentsGet,
  actionFavoritesAdd,
}

export default connect(mapStateToProps, reduxActions)(Campaign)
