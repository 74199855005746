import axios from 'axios'
import proxy from '../../utils/proxy'
import finalizeProducts from '../../utils/finalizeProducts'
import {
  CAMPAIGN_GET_ALL,
  CAMPAIGN_GET_PRODUCTS,
  CAMPAIGN_CURRENT_RESET,
  CAMPAIGN_ERROR,
} from './types'

// Hae kaikki aktiiviset kampanjat
export const actionCampaignGetAll = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const res = await axios.get(`${proxy}/api/public/get-campaigns`, config)

    if (res.status === 200) {
      dispatch({
        type: CAMPAIGN_GET_ALL,
        payload: res.data,
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: CAMPAIGN_ERROR,
    })
  }
}

// Hae kaikki aktiivisen kampanjan tuotteet
export const actionCampaignGetProducts = (codes) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ codes })

  try {
    const res = await axios.post(`${proxy}/api/public/get-campaign-products`, body, config)

    if (res.status === 200) {
      dispatch({
        type: CAMPAIGN_GET_PRODUCTS,
        payload: finalizeProducts(res.data),
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: CAMPAIGN_ERROR,
    })
  }
}

export const actionResetCurrentCampaign = () => (dispatch) => {
  dispatch({
    type: CAMPAIGN_CURRENT_RESET,
  })
}
