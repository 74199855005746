/* eslint-disable no-duplicate-case */
import proxy from './proxy'

export const allBrands = [
  'B&C',
  'Bella+Canvas',
  'bugOff',
  'Cobalt Fusion',
  'Cobalt Gear',
  'EXCD',
  'Gildan',
  'Jack&Maker',
  'K-Up',
  'Kariban',
  'Kariban Premium',
  'Kimood',
  'Mukua',
  'Native Spirit',
  'Penduick',
  'Petrol',
  'Proact',
  'Promodoro',
  'Stormtech',
  'V-Pro',
  'Velilla',
  'WK',
  'X.O',
]

export const allAmmattiRyhmat = [
  { label: 'Rakennus ja teollisuus', slug: 'rakennus-ja-teollisuus' },
  { label: 'Majoitus ja ravintola', slug: 'majoitus-ja-ravintola' },
  { label: 'Terveys ja hoiva', slug: 'terveys-ja-hoiva' },
]

export const allStandards = [
  { label: 'EN 1149-5 (antistaattinen)', slug: 'en-1149-5' },
  { label: 'EN 13034 (kemikaalisuojattu)', slug: 'en-13034' },
  { label: 'EN 14058 (kylmältä suojaava)', slug: 'en-14058' },
  { label: 'EN 17353 (näkyvä)', slug: 'en-17353' },
  { label: 'EN 342 (kylmältä suojaava)', slug: 'en-342' },
  { label: 'EN 343 (sateelta suojaava)', slug: 'en-343' },
  { label: 'EN ISO 11611 (hitsaussuojattu)', slug: 'en-iso-11611' },
  { label: 'EN ISO 11612 (paloturvallinen)', slug: 'en-iso-11612' },
  { label: 'EN ISO 20345 (turvajalkine)', slug: 'en-iso-20345' },
  { label: 'EN ISO 20471 (näkyvä)', slug: 'en-iso-20471' },
  { label: 'IEC 61482-2 (valokaarisuojattu)', slug: 'iec-61482-2' },
]

export const getProductAmmattiRyhmaName = (slug) => {
  switch (slug) {
    case 'rakennus-ja-teollisuus':
      return 'Rakennus ja teollisuus'
    case 'majoitus-ja-ravintola':
      return 'Majoitus ja ravintola'
    case 'terveys-ja-hoiva':
      return 'Terveys ja hoiva'
    default:
      return ''
  }
}

export const getProductStandardName = (slug) => {
  switch (slug) {
    case 'en-1149-5':
      return 'EN 1149-5 (antistaattinen)'
    case 'en-13034':
      return 'EN 13034 (kemikaalisuojattu)'
    case 'en-14058':
      return 'EN 14058 (kylmältä suojaava)'
    case 'en-17353':
      return 'EN 17353 (näkyvä)'
    case 'en-342':
      return 'EN 342 (kylmältä suojaava)'
    case 'en-343':
      return 'EN 343 (sateelta suojaava)'
    case 'en-iso-11611':
      return 'EN ISO 11611 (hitsaussuojattu)'
    case 'en-iso-11612':
      return 'EN ISO 11612 (paloturvallinen)'
    case 'en-iso-20345':
      return 'EN ISO 20345 (turvajalkine)'
    case 'en-iso-20471':
      return 'EN ISO 20471 (näkyvä)'
    case 'iec-61482-2':
      return 'IEC 61482-2 (valokaarisuojattu)'
    default:
      return ''
  }
}

export const getMateriaaliOminaisuusName = (slug) => {
  switch (slug) {
    case 'orgaaninen':
      return 'Orgaaninen'
    case 'kierratetty':
      return 'Kierrätetty'
    case 'elastaani':
      return 'Elastaani'
    default:
      return ''
  }
}

export const compareCarts = (cart1, cart2) => {
  const c1 = cart1
    .map((el) => ({
      id: el.id,
      amount: el.amount,
      line: el.line,
    }))
    .sort((a, b) => a.line - b.line)
  const c2 = cart2
    .map((el) => ({
      id: el.id,
      amount: el.amount,
      line: el.line,
    }))
    .sort((a, b) => a.line - b.line)
  const sameLength = c1.length === c2.length
  const sameProducts = c1.map((el) => el.id) === c2.map((el) => el.id)
  const sameAmounts = c1.map((el) => el.amount) === c2.map((el) => el.amount)
  const cartsHaveSameContent = sameLength && sameProducts && sameAmounts
  return cartsHaveSameContent
}

export const parseQuery = (queryString) => {
  let query = {}
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export const formatStormtechCode = (code) => {
  const num = /\d/
  const nums = code.match(num)
  const i = code.indexOf(nums)
  const formattedCode = [code.slice(0, i), '-', code.slice(i)].join('')
  return formattedCode
}

export const getGenre = (id) => {
  if (id === 'eco') return null
  if (id === 'new') return null
  const intId = parseInt(id)
  switch (true) {
    case intId < 1000:
      return null
    case intId < 2000:
      return 'Naiset'
    case intId < 3000:
      return 'Miehet'
    case intId < 4000:
      return 'Lapset'
    default:
      return null
  }
}

export const getCategory = (id) => {
  if (id === 'eco') return null
  if (id === 'new') return null
  switch (id) {
    case '0000':
    case '0100':
    case '0200':
    case '0300':
    case '0400':
    case '0500':
    case '0600':
    case '1000':
    case '1100':
    case '1200':
    case '1300':
    case '1400':
    case '1500':
    case '1600':
    case '2000':
    case '2100':
    case '2200':
    case '2300':
    case '2400':
    case '2500':
    case '2600':
    case '3000':
    case '3100':
    case '3200':
    case '3300':
    case '3400':
    case '3500':
    case '3600':
      return null
    case '0101':
    case '1101':
    case '2101':
    case '3101':
      return 'T-paidat'
    case '0102':
    case '1102':
    case '2102':
    case '3102':
      return 'Pikeet'
    case '0103':
    case '1103':
    case '2103':
    case '3103':
      return 'Kauluspaidat'
    case '0104':
    case '1104':
    case '2104':
    case '3104':
      return 'Neuleet'
    case '0201':
    case '1201':
    case '2201':
    case '3201':
      return 'Collegepaidat'
    case '0202':
    case '1202':
    case '2202':
    case '3202':
      return 'Collegehupparit'
    case '0203':
    case '1203':
    case '2203':
    case '3203':
      return 'Vetoketjuhupparit'
    case '0204':
    case '1204':
    case '2204':
    case '3204':
      return 'Collegetakit'
    case '0301':
    case '1301':
    case '2301':
    case '3301':
      return 'Fleecetakit'
    case '0302':
    case '1302':
    case '2302':
    case '3302':
      return 'Softshell'
    case '0303':
    case '1303':
    case '2303':
    case '3303':
      return 'Tuuli- ja kuoritakit'
    case '0304':
    case '1304':
    case '2304':
    case '3304':
      return 'Topatut takit'
    case '0305':
    case '1305':
    case '2305':
    case '3305':
      return 'Yhdistelmätakit'
    case '0306':
    case '1306':
    case '2306':
    case '3306':
      return 'Business- ja citytakit'
    case '0307':
    case '1307':
    case '2307':
    case '3307':
      return 'Liivit'
    case '0401':
    case '1401':
    case '2401':
    case '3401':
      return 'Housut'
    case '0402':
    case '1402':
    case '2402':
    case '3402':
      return 'Shortsit'
    case '0403':
    case '1403':
    case '2403':
    case '3403':
      return 'Collegehousut ja trikoot'
    case '0404':
    case '1404':
    case '2404':
    case '3404':
      return 'Hameet ja mekot'
    case '0405':
    case '1405':
    case '2405':
    case '3405':
      return 'Alusasut'
    case '0501':
    case '1501':
    case '2501':
    case '3501':
      return 'T-paidat (Sport)'
    case '0502':
    case '1502':
    case '2502':
    case '3502':
      return 'Pikeet (Sport)'
    case '0503':
    case '1503':
    case '2503':
    case '3503':
      return 'Shortsit (Sport)'
    case '0504':
    case '1504':
    case '2504':
    case '3504':
      return 'Housut (Sport)'
    case '0505':
    case '1505':
    case '2505':
    case '3505':
      return 'Takit ja liivit (Sport)'
    case '0506':
    case '1506':
    case '2506':
    case '3506':
      return 'Oheistuotteet (Sport)'
    case '0507':
    case '1507':
    case '2507':
    case '3507':
      return 'Paidat (Sport)'

    case '0601':
    case '1601':
    case '2601':
    case '3601':
      return 'Hatut ja päähineet'
    case '0602':
    case '1602':
    case '2602':
    case '3602':
      return 'Käsineet'
    case '0603':
    case '1603':
    case '2603':
    case '3603':
      return 'Huivit ja kaulaliinat'
    case '0604':
    case '1604':
    case '2604':
    case '3604':
      return 'Solmiot ja vyöt'
    case '0605':
    case '1605':
    case '2605':
    case '3605':
      return 'Sukat'
    case '0606':
    case '1606':
    case '2606':
    case '3606':
      return 'Essut'
    case '0607':
    case '1607':
    case '2607':
    case '3607':
      return 'Sadevarusteet'
    case '0608':
    case '1608':
    case '2608':
    case '3608':
      return 'Kylpytuotteet'
    case '0609':
    case '1609':
    case '2609':
    case '3609':
      return 'Peitot ja muut oheistuotteet'
    case '0610':
    case '1610':
    case '2610':
    case '3610':
      return 'Kengät'
    case '4001':
      return 'Ostoskassit'
    case '4002':
      return 'Pussukat ja lahjakassit'
    case '4003':
      return 'Asiakirja- ja esitelaukut'
    case '4004':
      return 'Tietokonelaukut'
    case '4005':
      return 'Olka- ja vyölaukut'
    case '4006':
      return 'Reput'
    case '4007':
      return 'Urheilu- ja matkakassit'
    case '4008':
      return 'Vetolaukut'
    case '4009':
      return 'Meikki- ja toilettipussit'
    case '4010':
      return 'Kylmälaukut'
    case '4011':
      return 'Jättikassit'
    case '5001':
      return 'T-paidat - Työvaatteet'
    case '5002':
      return 'Pikeet - Työvaatteet'
    case '5003':
      return 'Collegepaidat - Työvaatteet'
    case '5004':
      return 'Collegehupparit - Työvaatteet'
    case '5005':
      return 'Vetoketjuhupparit - Työvaatteet'
    case '5006':
      return 'Työhousut - Työvaatteet'
    case '5007':
      return 'Kauluspaidat - Työvaatteet'
    case '5008':
      return 'Collegetakit - Työvaatteet'
    case '5009':
      return 'Takit ja liivit - Työvaatteet'
    case '5010':
      return 'Oheistuotteet - Työvaatteet'
    case '5100':
      return 'Hi-Vis - Työvaatteet'
    case '5101':
      return 'Paidat - Hi-Vis'
    case '5102':
      return 'Takit - Hi-Vis'
    case '5103':
      return 'Alaosat - Hi-Vis'
    case '5104':
      return 'Päähineet ja asusteet - Hi-Vis'
    case '5105':
      return 'Colleget - Hi-Vis'
    case '5200':
      return 'Rakennus ja teollisuus - Työvaatteet'
    case '5201':
      return 'Paidat - Rakennus ja teollisuus'
    case '5202':
      return 'Colleget - Rakennus ja teollisuus'
    case '5203':
      return 'Takit - Rakennus ja teollisuus'
    case '5204':
      return 'Alaosat - Rakennus ja teollisuus'
    case '5205':
      return 'Kengät - Rakennus ja teollisuus'
    case '5206':
      return 'Päähineet ja asusteet - Rakennus ja teollisuus'
    case '5300':
      return 'Majoitus ja ravintola - Työvaatteet'
    case '5301':
      return 'Yläosat - Majoitus ja ravintola'
    case '5302':
      return 'Alaosat - Majoitus ja ravintola'
    case '5303':
      return 'Essut - Majoitus ja ravintola'
    case '5304':
      return 'Kengät - Majoitus ja ravintola'
    case '5305':
      return 'Päähineet ja asusteet - Majoitus ja ravintola'
    case '5400':
      return 'Terveys ja hoiva - Työvaatteet'
    case '5401':
      return 'Yläosat - Terveys ja hoiva'
    case '5402':
      return 'Alaosat - Terveys ja hoiva'
    case '5403':
      return 'Essut - Terveys ja hoiva'
    case '5404':
      return 'Kengät - Terveys ja hoiva'
    case '5405':
      return 'Päähineet ja asusteet - Terveys ja hoiva'
    case '5500':
      return 'Luokitellut - Työvaatteet'
    case '5501':
      return 'Näkyvät - Luokitellut'
    case '5502':
      return 'Antistaattiset - Luokitellut'
    case '5503':
      return 'Palo- ja hitsaussuojatut - Luokitellut'
    case '5504':
      return 'Kylmältä ja sateelta suojaavat - Luokitellut'
    case '5505':
      return 'Turvajalkineet - Luokitellut'
    case '6001':
      return 'B&C'
    case '6002':
      return 'Bella+Canvas'
    case '6003':
      return 'bugOff'
    case '6004':
      return 'Cobalt Fusion'
    case '6005':
      return 'Cobalt Gear'
    case '6006':
      return 'Gildan'
    case '6007':
      return 'K-Up'
    case '6008':
      return 'Kariban'
    case '6009':
      return 'Kimood'
    case '6010':
      return 'Penduick'
    case '6011':
      return 'Petrol'
    case '6012':
      return 'Proact'
    case '6013':
      return 'Promodoro'
    case '6014':
      return 'Stormtech'
    case '6015':
      return 'WK'
    case '6016':
      return 'Mukua'
    case '6017':
      return 'V-Pro'
    case '6018':
      return 'Velilla'
    case '6019':
      return 'Jack&Maker'
    case '6020':
      return 'Kariban Premium'
    case '6021':
      return 'Native Spirit'
    case '6022':
        return 'EXCD'
    case '6023':
      return 'X.O'
    default:
      return null
  }
}

export const getMainCategory = (id) => {
  if (id === 'eco') return 'ECO'
  if (id === 'new') return 'Uutuus'
  const intId = parseInt(id)
  switch (true) {
    case intId < 200:
      return 'Paidat'
    case intId < 300:
      return 'Colleget'
    case intId < 400:
      return 'Takit'
    case intId < 500:
      return 'Alaosat'
    case intId < 600:
      return 'Sport'
    case intId < 700:
      return 'Oheistuotteet'
    case intId === 1000:
      return null
    case intId < 1200:
      return 'Paidat'
    case intId < 1300:
      return 'Colleget'
    case intId < 1400:
      return 'Takit'
    case intId < 1500:
      return 'Alaosat'
    case intId < 1600:
      return 'Sport'
    case intId < 1700:
      return 'Oheistuotteet'
    case intId === 2000:
      return null
    case intId < 2200:
      return 'Paidat'
    case intId < 2300:
      return 'Colleget'
    case intId < 2400:
      return 'Takit'
    case intId < 2500:
      return 'Alaosat'
    case intId < 2600:
      return 'Sport'
    case intId < 2700:
      return 'Oheistuotteet'
    case intId === 3000:
      return null
    case intId < 3200:
      return 'Paidat'
    case intId < 3300:
      return 'Colleget'
    case intId < 3400:
      return 'Takit'
    case intId < 3500:
      return 'Alaosat'
    case intId < 3600:
      return 'Sport'
    case intId < 3700:
      return 'Oheistuotteet'
    case intId < 5000:
      return 'Laukut'
    case intId < 6000:
      return 'Työvaatteet'
    case intId < 7000:
      return 'Tuotemerkit'
    default:
      return null
  }
}

export const getCategoryCode = (alaRyhma, tuoteRyhma) => {
  if (tuoteRyhma === 'Työvaatteet') {
    switch (alaRyhma) {
      case 'T-paidat':
        return '5001'
      case 'Pikeet':
        return '5002'
      case 'Collegepaidat':
        return '5003'
      case 'Collegehupparit':
        return '5004'
      case 'Vetoketjuhupparit':
        return '5005'
      case 'Työhousut':
        return '5006'
      case 'Kauluspaidat':
        return '5007'
      case 'Collegetakit':
        return '5008'
      case 'Takit ja liivit':
        return '5009'
      case 'Oheistuotteet':
        return '5010'
      default:
        return null
    }
  } else {
    switch (alaRyhma) {
      case 'T-paidat':
        return '0101'
      case 'Pikeet':
        return '0102'
      case 'Kauluspaidat':
        return '0103'
      case 'Neuleet':
        return '0104'
      case 'Collegepaidat':
        return '0201'
      case 'Collegehupparit':
        return '0202'
      case 'Vetoketjuhupparit':
        return '0203'
      case 'Collegetakit':
        return '0204'
      case 'Fleecetakit':
        return '0301'
      case 'Softshell':
        return '0302'
      case 'Tuuli- ja kuoritakit':
        return '0303'
      case 'Topatut takit':
        return '0304'
      case 'Yhdistelmätakit':
        return '0305'
      case 'Business- ja citytakit':
        return '0306'
      case 'Liivit':
        return '0307'
      case 'Housut':
        return '0401'
      case 'Shortsit':
        return '0402'
      case 'Collegehousut ja trikoot':
        return '0403'
      case 'Hameet ja mekot':
        return '0404'
      case 'Alusasut':
        return '0405'
      case 'T-paidat (Sport)':
        return '0501'
      case 'Pikeet (Sport)':
        return '0502'
      case 'Shortsit (Sport)':
        return '0503'
      case 'Housut (Sport)':
        return '0504'
      case 'Takit ja liivit (Sport)':
        return '0505'
      case 'Oheistuotteet (Sport)':
        return '0506'
      case 'Paidat (Sport)':
        return '0507'
      case 'Hatut ja päähineet':
      case 'Lippalakit':
      case 'Hatut':
      case 'Pipot':
        return '0601'
      case 'Käsineet':
        return '0602'
      case 'Huivit ja kaulaliinat':
        return '0603'
      case 'Solmiot ja vyöt':
        return '0604'
      case 'Sukat':
        return '0605'
      case 'Essut':
        return '0606'
      case 'Sadevarusteet':
        return '0607'
      case 'Kylpytuotteet':
        return '0608'
      case 'Peitot ja muut oheistuotteet':
        return '0609'
      case 'Kengät':
        return '0610'
      case 'Ostoskassit':
        return '4001'
      case 'Pussukat ja lahjakassit':
        return '4002'
      case 'Asiakirja- ja esitelaukut':
        return '4003'
      case 'Tietokonelaukut':
        return '4004'
      case 'Olka- ja vyölaukut':
        return '4005'
      case 'Reput':
        return '4006'
      case 'Urheilu- ja matkakassit':
        return '4007'
      case 'Vetolaukut':
        return '4008'
      case 'Meikki- ja toilettipussit':
        return '4009'
      case 'Kylmälaukut':
        return '4010'
      case 'Jättikassit':
        return '4011'
      default:
        return null
    }
  }
}

export const getMainCategoryCode = (tuoteRyhma) => {
  switch (tuoteRyhma) {
    case 'Paidat':
      return '0100'
    case 'Colleget':
      return '0200'
    case 'Takit ja liivit':
      return '0300'
    case 'Alaosat':
      return '0400'
    case 'Sport':
      return '0500'
    case 'Päähineet ja asusteet':
    case 'Oheistuotteet':
      return '0600'
    case 'Laukut ja matkatarvikkeet':
      return '4000'
    case 'Työvaatteet':
      return '5000'
    default:
      return null
  }
}

export const specifyProduct = (code) => {
  let a = code.indexOf('-')
  let b = code.indexOf('_')
  let n
  if (a > b) n = a
  else n = b
  const productCode = code.substring(0, n)
  return productCode
}

export const specifyColor = (code) => {
  code = code.replace('_', '/')
  let firstLine = code.indexOf('-')
  // let b = code.indexOf('_')
  // let firstLine
  // if (a > b) firstLine = a
  // else firstLine = b
  const removeProductCode = code.substring(firstLine + 1)
  const nextLine = removeProductCode.indexOf('-')
  // const nextUnderscore = removeProductCode.indexOf('_')
  const nextDot = removeProductCode.indexOf('.')
  let colorCode
  if (nextLine !== -1) {
    colorCode = removeProductCode.substring(0, nextLine)
    return colorCode
  }
  // else if (nextUnderscore !== -1) {
  //   colorCode = removeProductCode.substring(0, nextUnderscore)
  //   return colorCode
  // }
  else if (nextDot !== -1) {
    colorCode = removeProductCode.substring(0, nextDot)
    return colorCode
  } else {
    return removeProductCode
  }
}

export const specifySize = (code) => {
  const countOfLines = code.split('-').length - 1
  const countOfUnderscores = code.split('_').length - 1
  if (countOfLines < 2 && countOfUnderscores < 2) {
    return ''
  }
  let lastDivider
  if (countOfLines > countOfUnderscores) lastDivider = code.lastIndexOf('-')
  else lastDivider = code.lastIndexOf('_')
  const removeProductAndColor = code.substring(lastDivider + 1)
  const nextDot = removeProductAndColor.indexOf('.')
  let sizeCode
  if (nextDot !== -1) {
    sizeCode = removeProductAndColor.substring(0, nextDot)
    return sizeCode
  } else {
    return removeProductAndColor
  }
}

export const getBrandLogo = (brand, small) => {
  switch (brand) {
    case 'B&C':
      return `${proxy}/images/logos/b&c_logo${small ? '_small' : ''}.png`
    case 'Bella+Canvas':
      return `${proxy}/images/logos/bella_canvas_logo${small ? '_small' : ''}.png`
    case 'Black&Match':
      return `${proxy}/images/logos/black&match_logo${small ? '_small' : ''}.png`
    case 'bugOff':
      return `${proxy}/images/logos/bugoff_logo${small ? '_small' : ''}.png`
    case 'Cobalt Fusion':
      return `${proxy}/images/logos/cobalt_fusion_logo${small ? '_small' : ''}.png`
    case 'Cobalt Gear':
      return `${proxy}/images/logos/cobalt_gear_logo${small ? '_small' : ''}.png`
    case 'Cobalt Uusio':
      return `${proxy}/images/logos/cobalt_uusio_logo${small ? '_small' : ''}.png`
    case 'EXCD':
      return `${proxy}/images/logos/excd_logo${small ? '_small' : ''}.png`
    case 'Gildan':
      return `${proxy}/images/logos/gildan_logo${small ? '_small' : ''}.png`
    case 'Jack&Maker':
      return `${proxy}/images/logos/jack&maker_logo${small ? '_small' : ''}.png`
    case 'K-Up':
      return `${proxy}/images/logos/k-up_logo${small ? '_small' : ''}.png`
    case 'Kariban':
      return `${proxy}/images/logos/kariban_logo${small ? '_small' : ''}.png`
    case 'Kariban Premium':
      return `${proxy}/images/logos/kariban_premium_logo${small ? '_small' : ''}.png`
    case 'Kimood':
      return `${proxy}/images/logos/kimood_logo${small ? '_small' : ''}.png`
    case 'Mukua':
      return `${proxy}/images/logos/mukua_logo${small ? '_small' : ''}.png`
    case 'Native Spirit':
      return `${proxy}/images/logos/native_spirit_logo${small ? '_small' : ''}.png`
    case 'Penduick':
      return `${proxy}/images/logos/penduick_logo${small ? '_small' : ''}.png`
    case 'Petrol':
      return `${proxy}/images/logos/petrol_logo${small ? '_small' : ''}.png`
    case 'Proact':
      return `${proxy}/images/logos/proact_logo${small ? '_small' : ''}.png`
    case 'Promodoro':
      return `${proxy}/images/logos/promodoro_logo${small ? '_small' : ''}.png`
    case 'Stormtech':
      return `${proxy}/images/logos/stormtech_logo${small ? '_small' : ''}.png`
    case 'V-Pro':
      return `${proxy}/images/logos/v-pro_logo${small ? '_small' : ''}.png`
    case 'Velilla':
      return `${proxy}/images/logos/velilla_logo${small ? '_small' : ''}.png`
    case 'WK':
      return `${proxy}/images/logos/wk_logo${small ? '_small' : ''}.png`
    case 'X.O':
      return `${proxy}/images/logos/xo_logo${small ? '_small' : ''}.png`
    default:
      return ''
  }
}

// Rajataan tuotteiden rajaustietoihin mukaan vain niiden variaatioiden
// data, joilla on saldoa (koot, väriryhmät, lähtöhinta)
export const filterDataWithInStockVariantsOnly = (data) => {
  for (let prod of data) {
    let sizesInStock = []
    let colorGroupsInStock = []
    for (let variant of prod.variaatiot) {
      sizesInStock.push(variant.koko)
      colorGroupsInStock.push(variant.variRyhma)
    }
    prod.koko = [...new Set(sizesInStock.sort((a, b) => (a > b ? 1 : -1)))]
    prod.variRyhmat = [...new Set(colorGroupsInStock.sort((a, b) => (a > b ? 1 : -1)))]
    prod.startingPrice = Math.min(...prod.variaatiot.map((el) => el.ovh))
  }
  return data
}

// Ei käytössä, alempana olevien kahden funktion toteutus korvasi tämän
// export const getGeneralExtraThumbnails = (thumbs, productId) => {
//   const extrasOnly = thumbs.filter((el) => el.includes('lisakuva'))
//   let generalThumbs = extrasOnly.map((url) => {
//     let endPart = url.split('/').pop()
//     if (productId && endPart.includes(productId)) {
//       endPart = endPart.replace(productId, '')
//       const dashCount = (endPart.match(/-/g) || []).length
//       if (dashCount <= 2) return url
//       else return undefined
//     }
//   })
//   generalThumbs = generalThumbs.filter((el) => el !== undefined)
//   return generalThumbs
// }

export const getExtraThumbsForProduct = (allImages, productId) => {
  const extrasOnly = allImages.filter((img) => img.includes('lisakuva') && img.includes('thumbnail'))
  let extraThumbs = extrasOnly.map((url) => {
    let endPart = url.split('/').pop()
    let thumb
    if (productId && endPart.includes(productId)) {
      endPart = endPart.replace(productId, '')
      const dashCount = (endPart.match(/-/g) || []).length
      if (dashCount <= 2) {
        return (thumb = url)
      } else {
        return (thumb = undefined)
      }
    }
    return thumb
  })
  extraThumbs = extraThumbs.filter((el) => el !== undefined)
  return extraThumbs
}

export const getExtraThumbsForColor = (color, allImages, tuoteKoodi) => {
  const validColor = color.replace('/', '_')
  const extraThumbs = allImages.filter((img) => {
    const imageColor = getSecondPartOfImageFile(img, tuoteKoodi)
    if (validColor === imageColor && img.includes('lisakuva') && img.includes('thumbnail')) {
      return true
    } else {
      return false
    }
  })
  return extraThumbs
}

export const getSecondPartOfImageFile = (image, tuoteKoodi) => {
  const imageWithoutProductId = image.replace(`${tuoteKoodi}-`, '')
  const splittedWords = imageWithoutProductId.split('-')
  const secondPart = splittedWords[0]
  return secondPart
}
