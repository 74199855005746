import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOAD_USER,
  RELOAD_USER,
  LOGIN_SUBMIT,
  CHANGE_PASSWORD,
  RESET_PW_STATE,
  RESET_USER_RELOAD,
  AUTH_ERROR,
  FAVORITES_GET,
  FAVORITES_ADD,
  FAVORITES_REMOVE,
  FAVORITES_ERROR,
  ADDRESSLIST_RESET,
  ADDRESSLIST_ADD,
  ADDRESSLIST_REMOVE,
  ADDRESSLIST_ERROR,
  RECENTS_GET,
  RECENTS_ERROR,
  LOGOUT,
  PW_RESETCODE_VALIDITY,
  CONSUME_PW_RESET,
} from '../actions/types'

const initialState = {
  token: localStorage.getItem('shop-token'),
  isAuthenticated: false,
  loading: true,
  user: {},
  favorites: [],
  recents: [],
  prevRecentsQuery: [],
  pwChangeDone: false,
  addressAddDone: false,
  addressRemoveDone: false,
  pwResetCodeValid: undefined,
  reloadDone: false
}

export default function reducerAuth(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case PW_RESETCODE_VALIDITY:
      return {
        ...state,
        pwResetCodeValid: payload,
      }

    case CONSUME_PW_RESET:
      return {
        ...state,
        pwResetCodeValid: undefined,
      }

    case LOGIN_SUBMIT:
      return {
        ...state,
        loading: true,
      }

    case LOAD_USER:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      }

    case RELOAD_USER:
    return {
      ...state,
      isAuthenticated: true,
      loading: false,
      user: payload,
      reloadDone: true
    }

    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        pwResetCodeValid: undefined,
      }

    case CHANGE_PASSWORD:
      return {
        ...state,
        pwChangeDone: true,
      }

    case RESET_PW_STATE:
      return {
        ...state,
        pwChangeDone: false,
      }

    case RESET_USER_RELOAD:
      return {
        ...state,
        reloadDone: false,
      }

    case FAVORITES_GET:
      return {
        ...state,
        favorites: payload,
      }

    case RECENTS_GET:
      return {
        ...state,
        recents: payload.recents,
        prevRecentsQuery: payload.prevRecentsQuery,
      }

    case FAVORITES_ADD:
    case FAVORITES_REMOVE:
      return {
        ...state,
        user: { ...state.user, favorites: payload },
      }

    case ADDRESSLIST_ADD:
      return {
        ...state,
        user: { ...state.user, addressList: payload },
        addressAddDone: true,
      }

    case ADDRESSLIST_REMOVE:
      return {
        ...state,
        user: { ...state.user, addressList: payload },
        addressRemoveDone: true,
      }

    case ADDRESSLIST_RESET:
      return {
        ...state,
        addressAddDone: false,
        addressRemoveDone: false,
      }

    case FAVORITES_ERROR:
      return {
        ...state,
        favorites: [],
      }

    case RECENTS_ERROR:
      return {
        ...state,
        recents: [],
        prevRecentsQuery: [],
      }

    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('shop-token')
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: {},
        favorites: [],
        recents: [],
        prevRecentsQuery: [],
        pwChangeDone: false,
      }

    case ADDRESSLIST_ERROR:
    default:
      return state
  }
}
