// React & Routing
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'
import { actionSendContactForm, actionContactReset } from '../../redux/actions/action.contact'

// Components
import Form1 from './Form1'
import Form2 from './Form2'
import Form3 from './Form3'

// Bootstrap
import { Form } from 'react-bootstrap'

// Styling
import './Contact.scss'

const Contact = ({ actionSendContactForm, actionContactReset, success }) => {
  const [selected, setSelected] = useState('1')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (success) {
      actionContactReset()
    } // eslint-disable-next-line
  }, [success])

  const submitForm = (values) => {
    actionSendContactForm({
      id: selected,
      name: values.name,
      company: values.company,
      email: values.email,
      msg: values.message,
      companyId: values.companyId,
      phone: values.phone,
      website: values.website,
      address: values.address,
      zip: values.zip,
      city: values.city,
      retailer: values.retailer,
      code: '',
    })
  }

  return (
    <div className='Contact'>
      <Helmet>
        <title>Ota yhteyttä | dc-collection</title>
      </Helmet>
      <h4>
        <strong>Ota yhteyttä</strong>
      </h4>
      <div className='contactAddress'>
        <p>DESPERADO CLOTHING OY</p>
        <p>Kisällintie 9</p>
        <p>01700 Vantaa</p>
        <br />
        <p>
          Puh.{' '}
          <a href='tel:09-27668612' alt=''>
            09-2766 8612
          </a>
        </p>
      </div>
      <hr />
      <h5>
        <strong>Lähetä viesti</strong>
      </h5>
      <Form.Group id='contactFormSelector'>
        <Form.Control
          name='form'
          type='text'
          as='select'
          value={selected}
          onChange={(e) => setSelected(e.target.value)}>
          <option value={1}>Olen jälleenmyyjä</option>
          <option value={2}>Haen jälleenmyyjäksi</option>
          <option value={3}>Etsin jälleenmyyjää</option>
        </Form.Control>
      </Form.Group>

      {selected === '1' ? <Form1 submitForm={submitForm} success={success} /> : null}
      {selected === '2' ? <Form2 submitForm={submitForm} success={success} /> : null}
      {selected === '3' ? <Form3 submitForm={submitForm} success={success} /> : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  success: state.contact.success,
})

const reduxActions = {
  actionSendContactForm,
  actionContactReset,
}

export default connect(mapStateToProps, reduxActions)(Contact)
